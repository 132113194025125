import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Menu,
    MenuItem,
    Paper,
    Radio,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import {
    CTA,
    Flex,
    FlexColumnGap,
    FlexEnd,
    FlexRow,
    FlexStartGap,
    TableCellStyled,
} from '../../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import {
    GetAllSurveyListAction,
    GetAllSurveys,
    updateBulkSurvey,
    SaveSurveyStatus,
    DownloadMonitorFiles,
    SurveyTemplatesLang,
} from '../../../../store/actions/Surveys/action';
import approved from '../../../../assets/img/approved.svg';
import pending from '../../../../assets/img/T-warning.svg';
import draft from '../../../../assets/img/edit_dark.svg';
import GreenDot from '../../../../assets/img/green_dot.svg';
import ClosedSvg from '../../../../assets/img/C-check.svg';
import actions from '../../../../assets/img/Menu-5.svg';
import arrowRight from '../../../../assets/img/Arrow-right.svg';
import CheckboxSvg from '../../../../assets/img/Checkbox.svg';
import DisabledCheckboxSvg from '../../../../assets/img/DisabledCheckbox.svg';
import moment from 'moment';
import PaginationComponent from '../../../User/PaginationComponent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { cloneDeep, debounce, filter } from 'lodash';
import {
    ACTUAL_STATUS,
    SURVEY_STATUS,
    SURVEY_STATUS_UI,
    SURVEY_TYPE,
    THROTTLE_TIME,
} from '../../../../global-constants';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import styled from 'styled-components';
import DashboardFilters from './DashboardFilters';
import { useSelector } from 'react-redux';
import { Badge } from '../../../Surveys/partials/setup-survey/Badge';
import { CommonLabel, Img, Pagetab } from '../../../../common/FormInputs/Common';
import { getResponseRatePercent, sanitize } from '../../../../utils/functions';
import { FlexInline } from '../../../User/partials/Client/SurveyTemplates';
import { EditModal } from '../../../../../src/common/DialogModal';
import { ShowMessage } from '../../../../common/FormInputs/Snackbar';
import { closePopUp } from '../../../../store/reducers/slice/SnackbarSlice';
import { resetData, resetDataExported } from '../../../../store/reducers/slice/SurveySlice';
import Outline24MoveLayerDown from '@mds/mds-icons/icons/svg/outline-24-move-layer-down.svg';
import { Icon } from '@mds/mds-reactjs-library';
import { createPortal } from 'react-dom';
import DropdownMenu from '../../../../common/FormInputs/DropdownMenu';
import radiobutton from '../../../../assets/img/radiobutton.svg';
import CloseIcon from '../../../../assets/img/close.svg';
import { closeModal } from '../../../../store/reducers/slice/Modal';
import { DataExportModal } from './DataExportModal';
import AuthStore from '../../../../common/AuthStore';
import { api } from '../../../../http/api';
import { Endpoints } from '../../../../http/Endpoints';
import ConfirmationPopUp from '../../../ConfirmationPopUp/ConfirmationPopUp';

const ManageSurveys = ({
    dispatchAction,
    data = [],
    mySurveys = [],
    allSurveys = [],
    allTotal,
    myTotal,
    handleResume,
    handleMonitorSurvey = () => {},
    handleTextEdit = () => {},
    templatesLang,
}) => {
    const surveyStatuses = Object.values(SURVEY_STATUS_UI).map(item => ({ is_selected: false, name: item }));
    const SurveyTypes = Object.values(SURVEY_TYPE).map(item => ({ is_selected: false, name: item }));
    const initialSelectedFilters = {
        status: [],
        link_type: [],
        template_type: [],
        survey_language: [],
        is_test: false,
    };
    const initialParams = {
        limit: 10,
        page: 1,
        searchParam: '',
        dateRange: { startDate: null, endDate: null },
    };
    const { data: surveyTemplates = [] } = useSelector(state => state.userTemplates);
    const initialFilters = {
        surveyTemplatesList: surveyTemplates,
        statusList: surveyStatuses,
        typesList: SurveyTypes,
        languages: [],
        localShowTest: false,
    };
    const [anchorEl, setAnchorEl] = useState(false);
    const [all, setAllState] = useState(initialParams);
    const [allSurveyData, setAllSurveyData] = useState([]);
    const [mySurveyData, setMySurveyData] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedSurveyId, setSelectedSurveyId] = useState('');
    const [mySurveyIds, setMySurveyIds] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [params, setId] = useState({
        uuid: '',
        survey_template: '',
        status: '',
        langid: '',
    });
    const [filters, setFilters] = useState(initialSelectedFilters); // The one sent with API
    const [surveyExport, setSurveyExport] = useState({
        open: false,
        selectLangid: '',
        type: 'base',
    });
    const [dataExportModal, setDataExportModal] = useState({
        open: false,
        surveyId: '',
    });
    const [filtersObj, setFiltersObj] = useState(initialFilters); // The one used to iterate over filters
    const [startDate, setStartDate] = useState(new Date('April 1, 2023'));
    const [endDate, setEndDate] = useState(new Date());
    const isAdmin = AuthStore.isAdmin;

    const {
        data: { can_view_all_survey = '', can_view_my_survey, can_close_bulk_survey },
    } = useSelector(state => state.permissionReducer);
    const { error, errorMessage, dataExported } = useSelector(state => state.surveyReducer);
    const [tab, setTab] = useState(can_view_all_survey.length ? 1 : 2);
    const { open: openSnack = false, snackMessage } = useSelector(({ snackbarReducer }) => snackbarReducer);
    const menuOpen = Boolean(anchorEl);
    const { LIVE, DRAFT, UNDER, COMPLETED, REVIEWED } = SURVEY_STATUS;
    const ifAllOrMySurvey = tab === 1 ? allSurveyData : mySurveyData;
    const total = tab === 1 ? allTotal : myTotal;
    const checkLiveSurveysLength = ifAllOrMySurvey?.filter(({ status }) => status === LIVE);
    const checkLiveSurveysSelected = ifAllOrMySurvey?.filter(
        ({ status, is_selected }) => status === LIVE && is_selected === true
    );
    const [languageObj, setLanguageObj] = useState({});
    const [surveyLanguages, setSurveyLanguages] = useState(templatesLang);
    const checkEditSurvey = ifAllOrMySurvey?.filter(({ uuid }) => uuid === selectedSurveyId)[0] || {};
    const { status: editSurveyStatus = '' } = checkEditSurvey;
    const flagOpen = openSnack || error;

    const styleObj = {
        width: `${pxToVw(23)}vw`,
        height: `${pxToVh(23)}vh`,
        color: colorsCode.TextHeading,
    };
    const checkBoxStyle = {
        width: `${pxToVw(18)}vw`,
        height: `${pxToVh(18)}vh`,
    };

    useEffect(() => {
        if (params?.langid?.split(',').length > 1 && templatesLang?.length) {
            const filteredLanguages = filter(templatesLang, lang => {
                return lang?.short_code !== 'en' && params.langid.includes(lang.uuid);
            });
            setSurveyLanguages(filteredLanguages);
        }
    }, [params, templatesLang]);

    useEffect(() => {
        getLanguages();
        dispatchAction(resetDataExported());
    }, []);

    useEffect(() => {
        if (!can_view_all_survey) {
            setTab(2);
            dispatchAction(GetAllSurveys({ limit: all.limit, page: all.page, reqBody: { ...filters } }));
        }
    }, [can_view_my_survey]);

    const getLanguages = async () => {
        const response = await api.get(`${Endpoints.getLanguages}?limit=50&page=1`);
        const _languages = response?.data?.data?.records || [];
        _languages.map((item, i) => {
            return Object.assign(_languages[i], { is_selected: false });
        });
        setFiltersObj({
            ...filtersObj,
            languages: _languages,
        });
        setLanguageObj(_languages);
    };

    useEffect(() => {
        setAllSurveyData(allSurveys);
        setMySurveyData(mySurveys);
    }, [allSurveys, mySurveys]);

    useEffect(() => {
        setFiltersObj({ ...filtersObj, surveyTemplatesList: surveyTemplates });
    }, [surveyTemplates]);

    const handleEditSurvey = val => {
        setAnchorEl(false);
        setSelectedSurveyId(val);
        setOpenEditModal(true);
    };

    const openModal = () => {
        setOpenEditModal(true);
    };
    const closeEditModal = () => {
        setOpenEditModal(false);
        setSelectedSurveyId('');
        setAnchorEl(false);
    };

    const changeSurveyStatus = async changeStatus => {
        closeEditModal();
        await dispatchAction(
            SaveSurveyStatus({
                surveyId: selectedSurveyId,
                status: changeStatus,
                isSurveyInfoReq: false,
                showSuccessMessage: true,
            })
        );
        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...all, reqBody: { ...filters } }));
        } else {
            dispatchAction(
                GetAllSurveys({
                    ...all.dateRange,
                    limit: all.limit,
                    page: all.page,
                    q: all.searchParam,
                    reqBody: { ...filters },
                })
            );
        }
        dispatchAction(closeModal());
    };

    const handleClose = React.useCallback(() => {
        dispatchAction(closePopUp());
        dispatchAction(resetData());
    }, [flagOpen]);

    const handleClearFilters = () => {
        setFilters(initialSelectedFilters);
        setFiltersObj({ ...initialFilters, languages: languageObj });
    };

    const handleClick = val => {
        setTab(val);
        handleClearFilters();
        setAllState(initialParams);
        setStartDate(new Date('April 1, 2023'));
        setEndDate(new Date());
        if (val === 1) {
            dispatchAction(
                GetAllSurveyListAction({
                    limit: initialParams.limit,
                    page: initialParams.page,
                    reqBody: { ...initialSelectedFilters },
                })
            );
        }
        if (val === 2) {
            dispatchAction(
                GetAllSurveys({
                    limit: initialParams.limit,
                    page: initialParams.page,
                    reqBody: { ...initialSelectedFilters },
                })
            );
        }
    };

    const handleChangeFn = (e, value) => {
        setAllState({ ...all, page: value });
        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...all, page: value, reqBody: { ...filters } }));
        }
        if (tab === 2) {
            dispatchAction(
                GetAllSurveys({
                    ...all.dateRange,
                    limit: all.limit,
                    page: value,
                    q: all.searchParam,
                    reqBody: { ...filters },
                })
            );
        }
    };

    const handleItemPerPageFn = val => {
        setAllState({ ...all, page: 1, limit: val });
        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...all, page: 1, limit: val, reqBody: { ...filters } }));
        }
        if (tab === 2) {
            dispatchAction(
                GetAllSurveys({ ...all.dateRange, limit: val, page: 1, q: all.searchParam, reqBody: { ...filters } })
            );
        }
    };

    const onSearchFn = debounce(e => {
        const {
            target: { value },
        } = e;
        const sanitizedValue = sanitize(value);
        setAllState({ ...all, searchParam: sanitizedValue, page: 1 });

        if (tab === 1) {
            dispatchAction(
                GetAllSurveyListAction({ ...all, searchParam: sanitizedValue, page: 1, reqBody: { ...filters } })
            );
        }
        if (tab === 2) {
            dispatchAction(
                GetAllSurveys({
                    ...all.dateRange,
                    limit: all.limit,
                    page: 1,
                    q: sanitizedValue,
                    reqBody: { ...filters },
                })
            );
        }
    }, THROTTLE_TIME);

    const OnDateChangeFn = (startDate, endDate) => {
        const copy = { ...all, page: 1 };
        copy.dateRange.startDate = startDate;
        copy.dateRange.endDate = endDate;
        setAllState(copy);

        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...copy, reqBody: { ...filters } }));
        } else {
            dispatchAction(
                GetAllSurveys({
                    startDate,
                    endDate,
                    limit: all.limit,
                    page: 1,
                    q: all.searchParam,
                    reqBody: { ...filters },
                })
            );
        }
    };

    const renderColoredChips = status => {
        switch (status) {
            case REVIEWED:
                return (
                    <FlexRowStyled className="reviewed">
                        <img src={approved} alt="" className="imgApprove" />
                        <Typography className="text">
                            {status[0].toUpperCase().concat(status.slice(1).toLowerCase()) || '-'}
                        </Typography>
                    </FlexRowStyled>
                );
            case DRAFT:
                return (
                    <FlexRowStyled className="draft">
                        <img src={draft} alt="" className="imgApprove" />
                        <Typography className="text">{ACTUAL_STATUS[status] || '-'}</Typography>
                    </FlexRowStyled>
                );
            case UNDER:
                return (
                    <FlexRowStyled className="underreview">
                        <img src={pending} alt="" className="imgApprove" />
                        <Typography className="text">{ACTUAL_STATUS[status] || '-'}</Typography>
                    </FlexRowStyled>
                );
            case LIVE:
                return (
                    <FlexRowStyled className="reviewed">
                        <img src={GreenDot} alt="" className="imgApprove" />
                        <Typography className="text">{ACTUAL_STATUS[status] || '-'}</Typography>
                    </FlexRowStyled>
                );
            case COMPLETED:
                return (
                    <FlexRowStyled className="closed">
                        <img src={ClosedSvg} alt="" className="imgApprove" />
                        <Typography className="text">{ACTUAL_STATUS[status] || '-'}</Typography>
                    </FlexRowStyled>
                );
        }
    };

    const handleParent = e => {
        const getData = tab === 1 ? allSurveyData : mySurveyData;
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(getData);
        let data = [];
        if (checked) {
            data = copy.map(item => {
                return { ...item, is_selected: item.status === LIVE ? checked : false };
            });
        } else {
            data = copy.map(item => {
                return { ...item, is_selected: checked };
            });
        }
        let _selectedIds = [];
        if (checked) {
            _selectedIds = copy.filter(({ status }) => status === LIVE).map(item => item.uuid);
        }
        if (tab === 1) {
            setSelectedIds(_selectedIds);
            setAllSurveyData(data);
        } else {
            setMySurveyIds(_selectedIds);
            setMySurveyData(data);
        }
    };

    const handleCheckChild = (e, i, id) => {
        const getData = tab === 1 ? allSurveyData : mySurveyData;
        const _ids = tab === 1 ? selectedIds : mySurveyIds;
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(getData);
        copy[i].is_selected = checked;
        if (tab === 1) {
            setAllSurveyData(copy);
        } else {
            setMySurveyData(copy);
        }
        //
        const containsId = _ids?.some(item => item === id);
        if (!containsId) {
            if (tab == 1) {
                setSelectedIds(ids => [...ids, id]);
            } else {
                setMySurveyIds(ids => [...ids, id]);
            }
        } else {
            let myIds = _ids.filter(item => item !== id);
            setIds([...myIds]);
        }
    };

    const indeterminateFlag = () => {
        if (!checkLiveSurveysSelected.length) {
            return false;
        } else if (checkLiveSurveysSelected.length !== checkLiveSurveysLength?.length) {
            return true;
        }
        return false;
    };

    const handleCloseSurvey = () => {
        const _ids = tab == 1 ? selectedIds : mySurveyIds;
        const stateVal = tab === 1 ? all : { ...all.dateRange, limit: all.limit, page: all.page, q: all.searchParam };
        if (_ids.length > 0) {
            const state = { ...stateVal, reqBody: { ...filters } };
            dispatchAction(updateBulkSurvey({ surveyIds: _ids, status: 'COMPLETED', tab, state }));
            setIds([]);
        }
    };

    const setIds = arrayData => {
        if (tab === 1) {
            setSelectedIds(arrayData);
        } else {
            setMySurveyIds(arrayData);
        }
    };

    const handleMenu = (e, uuid, survey_template, status, langid) => {
        setAnchorEl(e.currentTarget);
        setId({
            uuid,
            survey_template,
            status,
            langid,
        });
    };

    const handleSurveyExport = () => {
        setAnchorEl(false);
        if (params.langid.split(',').length > 1) {
            dispatchAction(SurveyTemplatesLang({ tid: params.survey_template }));
            setSurveyExport({
                ...surveyExport,
                open: true,
            });
        } else {
            dispatchAction(DownloadMonitorFiles({ surveyId: params.uuid, langid: params.langid }));
        }
    };

    const handleDataExport = data => {
        setDataExportModal({
            open: true,
            surveyId: ifAllOrMySurvey.find(({ uuid }) => uuid === data.uuid)?.survey_id,
            uuid: data.uuid,
        });
    };

    const closeDataExport = () => {
        setDataExportModal({ open: false, surveyId: '', uuid: '' });
    };

    const renderSurveyExportPopup = () => {
        const getRadio = expType => {
            return (
                <Radio
                    sx={{ height: 0, width: 0 }}
                    onClick={() =>
                        setSurveyExport({
                            ...surveyExport,
                            type: expType,
                        })
                    }
                    checked={surveyExport.type === expType}
                    icon={<img src={radiobutton} style={{ height: `${pxToVh(20)}vh`, width: `${pxToVw(20)}vw` }} />}
                />
            );
        };
        const closeModal = () => {
            setSurveyExport({ open: false, selectLangid: '', type: 'base' });
            setId({
                uuid: '',
                survey_template: '',
                status: '',
                langid: '',
            });
        };
        return (
            <Dialog
                open={surveyExport.open}
                PaperProps={{
                    sx: {
                        maxWidth: `${pxToVw(580)}vw`,
                        width: `${pxToVw(580)}vw`,
                        borderRadius: '8px',
                    },
                }}
            >
                <DialogContent
                    sx={{
                        p: `${pxToVh(30)}vh ${pxToVh(30)}vw 0vh ${pxToVh(30)}vw`,
                    }}
                >
                    <IconButton
                        aria-label="close"
                        onClick={closeModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <Img image={CloseIcon} h={pxToVh(24)} w={pxToVw(24)} alt="close" cursor="pointer" />
                    </IconButton>
                    <FlexColumnGap gap={pxToVh(40)}>
                        <CommonLabel
                            textWeight={500}
                            textSize={24}
                            textColor={colorsCode.TextHeading}
                            lineHeight={36}
                            label="Survey Export"
                        />
                        <FlexColumnGap gap={pxToVh(40)}>
                            <CommonLabel
                                textWeight={400}
                                textSize={16}
                                textColor={colorsCode.TextHeading}
                                lineHeight={24}
                                label="The export will be in the Excel (xls) format"
                            />
                            <FlexColumnGap gap={pxToVh(8)}>
                                <CommonLabel
                                    textWeight={400}
                                    textSize={16}
                                    textColor={colorsCode.Neutral30}
                                    lineHeight={24}
                                    label="Survey ID"
                                />
                                <FlexColumnGap gap={pxToVh(24)}>
                                    <CommonLabel
                                        textWeight={400}
                                        textSize={16}
                                        textColor={colorsCode.Neutral80}
                                        lineHeight={24}
                                        customStyles={{
                                            textTransform: 'uppercase',
                                        }}
                                        label={ifAllOrMySurvey.find(({ uuid }) => uuid === params.uuid)?.survey_id}
                                    />
                                    <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                        {getRadio('base')}
                                        <CommonLabel
                                            textWeight={surveyExport.type === 'base' ? 400 : 300}
                                            textSize={18}
                                            textColor={colorsCode.Neutral80}
                                            lineHeight={28}
                                            label="Base language"
                                        />
                                        <Langdiv>
                                            <CommonLabel
                                                textWeight={surveyExport.type === 'base' ? 400 : 300}
                                                textSize={14}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={16}
                                                label="English (US)"
                                            />
                                        </Langdiv>
                                    </FlexStartGap>
                                    <FlexColumnGap gap={pxToVh(16)} style={{ alignSelf: 'stretch' }}>
                                        <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                            {getRadio('multi')}
                                            <CommonLabel
                                                textWeight={surveyExport.type === 'multi' ? 400 : 300}
                                                textSize={18}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={28}
                                                label="Select another language"
                                            />
                                        </FlexStartGap>
                                        {surveyExport.type === 'multi' ? (
                                            <DropdownMenu
                                                id="lang"
                                                name="lang"
                                                menuItems={surveyLanguages}
                                                placeholder="Select an option"
                                                value={surveyExport.selectLangid}
                                                width={pxToVw(480)}
                                                onChangeFn={e =>
                                                    setSurveyExport({ ...surveyExport, selectLangid: e.target.value })
                                                }
                                            />
                                        ) : null}
                                    </FlexColumnGap>
                                </FlexColumnGap>
                            </FlexColumnGap>
                        </FlexColumnGap>
                    </FlexColumnGap>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', mb: `${pxToVh(35)}vh`, mt: `${pxToVh(40)}vh` }}>
                    <FlexStartGap gap={pxToVw(24)}>
                        <CTA
                            size={16}
                            fw={400}
                            lh={24}
                            color={colorsCode.PrimaryDeepBlue}
                            bg={colorsCode.white}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={16}
                            fw={400}
                            lh={24}
                            color={colorsCode.white}
                            bg={colorsCode.PrimaryDeepBlue}
                            onClick={() => {
                                const engUsOrOther =
                                    surveyExport.type === 'base'
                                        ? ifAllOrMySurvey.find(({ uuid }) => uuid === params.uuid)?.language_id
                                        : surveyExport.selectLangid;
                                dispatchAction(DownloadMonitorFiles({ surveyId: params.uuid, langid: engUsOrOther }));
                                setSurveyExport({
                                    open: false,
                                    selectLangid: '',
                                    type: 'base',
                                });
                            }}
                            disabled={!surveyExport.type && !surveyExport.selectLangid && !params.uuid}
                        >
                            Export
                        </CTA>
                    </FlexStartGap>
                </DialogActions>
            </Dialog>
        );
    };

    return (
        <FlexColumnGap gap={pxToVh(24)} data-testid="manage">
            <FlexInline>
                {can_view_all_survey === 'can_view_all_survey' ? (
                    <Pagetab
                        active={tab === 1}
                        clickHandler={() => (tab !== 1 ? handleClick(1) : null)}
                        label="All surveys"
                    />
                ) : null}
                {can_view_my_survey === 'can_view_my_survey' && can_view_all_survey === 'can_view_all_survey' ? (
                    <Pagetab
                        active={tab === 2}
                        clickHandler={() => (tab !== 2 ? handleClick(2) : null)}
                        label="My surveys"
                    />
                ) : null}
            </FlexInline>
            <DashboardFilters
                OnDateChangeFn={OnDateChangeFn}
                onSearchFn={onSearchFn}
                handleCloseSurvey={() =>
                    can_close_bulk_survey === 'can_close_bulk_survey' ? handleCloseSurvey() : null
                }
                tab={tab}
                dispatchAction={dispatchAction}
                allState={all}
                setAllState={setAllState}
                filters={filters}
                setFilters={setFilters}
                selectedIds={tab === 1 ? selectedIds : mySurveyIds}
                filtersObj={filtersObj}
                setFiltersObj={setFiltersObj}
                handleClearFilters={handleClearFilters}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
            />
            <Table component={Paper} sx={{ boxShadow: '0px 0px 1px 0px #051c2c26, 0px 2px 4px -1px #051c2c26' }}>
                <TableHead>
                    <TableRow>
                        <TableCellStyled>
                            <Checkbox
                                sx={{
                                    height: 0,
                                    width: 0,
                                    opacity: checkLiveSurveysLength.length ? '1' : '0.4',
                                }}
                                icon={<img src={CheckboxSvg} alt="" className="img" style={checkBoxStyle} />}
                                onClick={handleParent}
                                checked={Boolean(
                                    checkLiveSurveysLength.length &&
                                        checkLiveSurveysSelected?.length === checkLiveSurveysLength.length
                                )}
                                disabled={!checkLiveSurveysLength.length}
                                checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                indeterminate={indeterminateFlag()}
                                indeterminateIcon={<IndeterminateCheckBoxIcon sx={styleObj} />}
                            />
                        </TableCellStyled>
                        <TableCellStyled sx={{ width: '270px' }}>Survey name</TableCellStyled>
                        <TableCellStyled>Survey type</TableCellStyled>
                        <TableCellStyled>Status</TableCellStyled>
                        <TableCellStyled>Response Rate</TableCellStyled>
                        <TableCellStyled>Created on</TableCellStyled>
                        <TableCellStyled>Live on</TableCellStyled>
                        <TableCellStyled>Closed on</TableCellStyled>
                        <TableCellStyled>Actions</TableCellStyled>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {ifAllOrMySurvey && ifAllOrMySurvey.length ? (
                        ifAllOrMySurvey.map(
                            (
                                {
                                    survey_name = '',
                                    survey_id = '',
                                    uuid = '',
                                    survey_template,
                                    created_on = '',
                                    status,
                                    is_selected,
                                    response_rate,
                                    last_updated_on: updated_at = '',
                                    launch_date = '',
                                    supported_languages = '',
                                    survey_sub_type = '',
                                },
                                i
                            ) => {
                                const ifNotLive = status !== LIVE;
                                const surveyName = `${survey_id.toUpperCase()} - ${survey_name.replaceAll(
                                    '&amp;',
                                    '&'
                                )}`;
                                return (
                                    <TableRow key={i}>
                                        <TableCellStyled>
                                            <Checkbox
                                                sx={{
                                                    height: 0,
                                                    width: 0,
                                                    opacity: ifNotLive ? '0.4' : '1',
                                                    cursor: ifNotLive ? 'not-allowed' : 'pointer',
                                                }}
                                                icon={
                                                    <img
                                                        src={ifNotLive ? DisabledCheckboxSvg : CheckboxSvg}
                                                        alt=""
                                                        className="img"
                                                        style={checkBoxStyle}
                                                    />
                                                }
                                                disabled={ifNotLive}
                                                checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                                checked={Boolean(is_selected)}
                                                onClick={e => handleCheckChild(e, i, uuid)}
                                            />
                                        </TableCellStyled>
                                        <TableCellStyled>
                                            <CommonLabel
                                                customStyles={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: '1',
                                                    WebKitBoxOrient: 'vertical',
                                                }}
                                                textWeight={400}
                                                textSize={14}
                                                lineHeight={20}
                                                textColor={colorsCode.Neutral80}
                                                title={surveyName}
                                                label={surveyName}
                                            />
                                        </TableCellStyled>
                                        <TableCellStyled>
                                            <Badge
                                                data={data}
                                                survey_template={survey_template}
                                                survey_sub_type={survey_sub_type}
                                            />
                                        </TableCellStyled>
                                        <TableCellStyled className="tblecellStyled">
                                            <div className="surveyStatusClass">{renderColoredChips(status)}</div>
                                        </TableCellStyled>
                                        <TableCellStyled className="tblecellStyled">
                                            {`${response_rate.total_completed}/${
                                                response_rate.total_respondents
                                            } (${getResponseRatePercent(
                                                response_rate.total_completed,
                                                response_rate.total_respondents
                                            )}%)`}
                                        </TableCellStyled>
                                        <TableCellStyled className="tblecellStyled">
                                            {moment.unix(created_on).format('DD-MMM-YYYY')}
                                        </TableCellStyled>
                                        <TableCellStyled className="tblecellStyled">
                                            {launch_date && !ifNotLive
                                                ? moment.unix(launch_date).format('DD-MMM-YYYY')
                                                : '-'}
                                        </TableCellStyled>
                                        <TableCellStyled className="tblecellStyled">
                                            {status === COMPLETED && updated_at
                                                ? moment.unix(updated_at).format('DD-MMM-YYYY')
                                                : '-'}
                                        </TableCellStyled>
                                        <TableCellStyled>
                                            <Fragment>
                                                <img
                                                    src={actions}
                                                    alt=""
                                                    onClick={e =>
                                                        handleMenu(
                                                            e,
                                                            uuid,
                                                            survey_template,
                                                            status,
                                                            supported_languages
                                                        )
                                                    }
                                                    style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                                                />
                                            </Fragment>
                                        </TableCellStyled>
                                    </TableRow>
                                );
                            }
                        )
                    ) : (
                        <TableRow>
                            <TableCellStyled colSpan={9} sx={{ textAlign: 'center' }}>
                                No survey found
                            </TableCellStyled>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {ifAllOrMySurvey.length && total > all.limit ? (
                <FlexEnd style={{ alignSelf: 'stretch', marginTop: `${pxToVh(-18)}vh` }}>
                    <PaginationComponent
                        page={all.page}
                        perPage={all?.limit}
                        handleItemPerPageFn={handleItemPerPageFn}
                        handleChangeFn={handleChangeFn}
                        count={Math.ceil(total / all.limit)}
                    />
                </FlexEnd>
            ) : null}

            {menuOpen ? (
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    sx={{
                        '& .MuiMenu-paper': {
                            boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
                            width: `auto`,
                            marginLeft: `${pxToVw(30)}vw`,
                            marginTop: `${pxToVh(10)}vh`,
                        },
                    }}
                >
                    {[DRAFT, UNDER, REVIEWED].includes(params.status) ? (
                        <MenuItem>
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleResume(params.uuid, params.survey_template);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    RESUME
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleResume(params.uuid, params.survey_template);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    {[LIVE, COMPLETED].includes(params.status) ? (
                        <>
                            <MenuItem value="monitor">
                                <FlexRow>
                                    <Typography
                                        className="actionClass"
                                        color="#2B5580"
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleMonitorSurvey(params.uuid);
                                        }}
                                    >
                                        MONITOR
                                    </Typography>
                                    <img
                                        src={arrowRight}
                                        className="image"
                                        alt=""
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleMonitorSurvey(params.uuid);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </FlexRow>
                            </MenuItem>
                            <MenuItem value="schedule">
                                <FlexRow>
                                    <Typography
                                        className="actionClass"
                                        color="#2B5580"
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleResume(params.uuid, params.survey_template);
                                        }}
                                    >
                                        DISTRIBUTE SURVEY
                                    </Typography>
                                    <img
                                        src={arrowRight}
                                        className="image"
                                        alt=""
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleResume(params.uuid, params.survey_template);
                                        }}
                                    />
                                </FlexRow>
                            </MenuItem>
                        </>
                    ) : null}
                    {isAdmin && [DRAFT, LIVE, UNDER, REVIEWED].includes(params.status) ? (
                        <MenuItem value="text_edit">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color={colorsCode.SecondaryDeepBlue500}
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleTextEdit(params.uuid, params.survey_template);
                                    }}
                                >
                                    TEXT EDIT
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleTextEdit(params.uuid, params.survey_template);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    {[LIVE].includes(params.status) ? (
                        <MenuItem value="Edit">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleEditSurvey(params.uuid);
                                    }}
                                >
                                    REVERT TO DRAFT
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleEditSurvey(params.uuid);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    {[COMPLETED].includes(params.status) ? (
                        <MenuItem value="Change">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleEditSurvey(params.uuid);
                                    }}
                                >
                                    REVERT TO LIVE
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleEditSurvey(params.uuid);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    <MenuItem value="monitor">
                        <FlexRow>
                            <Typography
                                className="actionClass"
                                color="#2B5580"
                                onClick={() => {
                                    setAnchorEl(false);
                                    handleSurveyExport();
                                }}
                            >
                                SURVEY EXPORT
                            </Typography>

                            <Icon
                                src={Outline24MoveLayerDown}
                                name="export"
                                style={{
                                    height: `${pxToVh(12)}vh`,
                                    width: `${pxToVw(12)}vw`,
                                    color: colorsCode.SecondaryDeepBlue500,
                                }}
                                onClick={() => {
                                    setAnchorEl(false);
                                    handleSurveyExport();
                                }}
                            />
                        </FlexRow>
                    </MenuItem>
                    {isAdmin ? (
                        <MenuItem value="data-export">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleDataExport(params);
                                    }}
                                >
                                    DATA EXPORT
                                </Typography>

                                <Icon
                                    src={Outline24MoveLayerDown}
                                    name="export"
                                    style={{
                                        height: `${pxToVh(12)}vh`,
                                        width: `${pxToVw(12)}vw`,
                                        color: colorsCode.SecondaryDeepBlue500,
                                    }}
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleDataExport(params);
                                    }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                </Menu>
            ) : null}
            <DataExportModal dataExportModal={dataExportModal} closeDataExport={closeDataExport} />
            {openEditModal && (
                <EditModal
                    open={openModal}
                    close={closeEditModal}
                    surveydata={checkEditSurvey}
                    handleOnSave={() => {
                        changeSurveyStatus(editSurveyStatus === LIVE ? DRAFT : LIVE);
                    }}
                    changingStatusTo={editSurveyStatus === LIVE ? 'Draft' : 'Live'}
                />
            )}
            {flagOpen && (
                <ShowMessage
                    open={flagOpen}
                    type={!error ? 'success' : 'error'}
                    message={snackMessage || errorMessage}
                    close={handleClose}
                />
            )}
            {createPortal(renderSurveyExportPopup(), document.body)}
            {dataExported ? <ConfirmationPopUp isOpen={dataExported} message={errorMessage} /> : null}
        </FlexColumnGap>
    );
};

export const FlexRowStyled = styled(FlexRow)`
    gap: 0.3vw;
`;

export const Langdiv = styled(Flex)`
    padding: ${pxToVh(4)}vh ${pxToVw(8)}vw;
    align-items: flex-start;
    gap: ${pxToVw(10)}vw;
    border-radius: 4px;
    border: 1px solid ${colorsCode.neutral20};
`;

export default ManageSurveys;
