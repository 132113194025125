import styled, { css } from 'styled-components';
import Chip from '../../../components/KnowledgeCenter/Chip';
import { IconButton as IconBtn } from '@mui/material';
import { FlexColumnWithAlign, FlexRow } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const VideoContainer = styled.div`
    width: ${({ width }) => width || `${pxToVw(557)}vw`};
    height: ${({ height }) => height || `${pxToVh(324)}vh`};
    background-color: ${colorsCode.PrimaryDeepBlue};
    margin-left: ${pxToVw(16)}vw;
    position: relative;
`;

export const BackgroundImage = styled.img`
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
`;

export const TourTextContainer = styled(FlexColumnWithAlign)`
    max-width: 45%;
`;

export const ChipStyled = styled(Chip)`
    position: absolute !important;
    top: 16px;
    left: 24px;
    z-index: 10;
`;

export const ContentCardListWrapper = styled(FlexColumnWithAlign)`
    width: 100%;
    position: relative;
`;

export const ScrollContainer = styled(FlexRow)`
    width: 100%;
    padding: 2px;
    overflow-x: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

export const IconButton = styled(IconBtn)`
    position: absolute !important;
    top: 55%;
    transform: translateY(-50%);
    z-index: 10;
    background-color: ${colorsCode.deepBlue100} !important;
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    border-radius: 5px !important;

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            cursor: default !important;
            opacity: 0.7;

            svg path {
                fill: ${colorsCode.neutral60};
            }
        `}

    ${({ left }) =>
        left &&
        css`
            left: -${pxToVw(4)}vw;
        `}

    ${({ right }) =>
        right &&
        css`
            right: -${pxToVw(4)}vw;
        `}
`;
