import styled from 'styled-components';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const InsightsContentContainer = styled(FlexColumnWithAlign)`
    .content-div {
        padding: 0 ${pxToVw(50)}vw ${pxToVh(22)}vh ${pxToVw(32)}vw;

        &:not(:last-child) {
            border-bottom: 1px solid ${colorsCode.Neutral10};
        }
    }
`;

export const OHIInsightContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #f2f2f2;
    padding: 10px 10px 10px 0px;
`;
export const CardContainer = styled.div`
    border-radius: 8px;
    box-shadow: 0px 0px 1px rgba(5, 28, 44, 0.15);
    padding: 24px;
    background-color: white;
    margin: 0px 0px 0px 0px;
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`;

export const OHIStepImageContainer = styled.div`
    background-color: transparent;
    background-size: cover;
    display: flex;
    width: 600px;
    height: 331px;
`;

export const TitleDiv = styled.div`
    color: black;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    word-wrap: break-word;
`;

export const Underline = styled.div`
    width: 100%;
    height: 0.5px;
    background-color: #cccccc;
    margin-bottom: 5px;
`;

export const CurvedDiv = styled.div`
    width: 30%;
    height: 35px;
    background: #f8f8f8;
    border-top-left-radius: 20.5px;
    border-top-right-radius: 20.5px;
    text-align: center;
    padding: 5px 0px 0px 0px;
`;
