import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { CTA, FlexBetween, FlexColumnGap, Wrapper, FlexStartGap } from '../../../common/styled/styled';
import { Box } from '@mui/material';
import OverViewComponent from '../partials/Client/OverViewComponent';
import themeStyles from '../../Dashboard/themes';
import { useNavigate } from 'react-router';
import AuthStore from '../../../common/AuthStore';
import ClientList from '../partials/Tables/ClientList';
import UserList from '../partials/Tables/UserList';
import ClientFilter from '../partials/Client/ClientFilter';
import { useDispatch, useSelector } from 'react-redux';
import { BulkDeleteClient, ClientListing, DeleteClient, GetClientById } from '../../../store/actions/Client/actions';
import { CLIENT_USER_TABS, FILTER_STATUS, LABELS, PATHS, THROTTLE_TIME } from '../../../global-constants';
import { DeleteUser, UsersListing } from '../../../store/actions/Users/actions';
import AddUpdate from '../../../common/AddUpdate';
import Loader from '../../Loader';
import styled from 'styled-components';
import { exportSheet, getLinkTypesLabel } from '../../../utils/functions';
import '../../Dashboard/scss/index.scss';
import { resetData } from '../../../store/reducers/slice/Users';
import UserFilter from '../partials/UserFilter';
import { ShowMessage } from '../../../common/FormInputs/Snackbar';
import { SuccessModal } from '../../../common/DialogModal';
import { cloneDeep, debounce } from 'lodash';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { useLocation } from 'react-router-dom';
import { CommonLabel, Pagetab } from '../../../common/FormInputs/Common';
import { closePopUp } from '../../../store/reducers/slice/SnackbarSlice';
import { UserTemplates } from '../../../store/actions/Surveys/action';
import moment from 'moment';
import { resetSaved } from '../../../store/reducers/slice/ClientSlice';
import { FlexInline } from '../partials/Client/SurveyTemplates';

const initialUserFilters = {
    page: 1,
    limit: 10,
    search: '',
    dateRange: { startDate: null, endDate: null },
    filters: {
        filters: {},
        sort: 0,
    },
};

const initialClientFilters = {
    page: 1,
    limit: 10,
    search: '',
    dateRange: { startDate: null, endDate: null },
    filter: { templateType: '', surveyCount: '' },
    sort: 0,
};

const ClientsUsers = () => {
    const styles = themeStyles();
    const history = useNavigate();
    const dispatch = useDispatch();

    const [tab, setTab] = useState(1);
    const [usersData, setUsersData] = useState([]);
    const [clientsData, setClientsData] = useState([]);
    const [filterModalOpen, setFilterModalOpenOpen] = useState(false);
    const [userFilterOpen, setUserFilterOpen] = useState(null);

    const [userFilters, setUserFilters] = useState(initialUserFilters);
    const [clientFilter, setClientFilters] = useState(initialClientFilters);
    const [userParams, setUserParams] = useState({
        name: '',
        email: '',
        role_id: '',
        client_id: '',
        template_id: '',
        valid_till: '',
    });
    const [addUserOpen, setAddUser] = useState(false);
    const [userId, setUserId] = useState(null);
    const [open, setOpen] = useState(false); //confimration modal for delete
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedClientId, setSelectedClientId] = useState(-1);
    const [openConfirmModal, setOpenConfirmModal] = useState({});

    const {
        data: userlist = [],
        loading,
        isDataFetched: userDataFetched,
        userDetails = {},
    } = useSelector(state => state.userReducer);
    const {
        clientList = [],
        singleDeleted,
        bulkDeleted,
        errorMessage,
        error = false,
        clientData = {},
    } = useSelector(state => state.clientReducer);
    const { roles: rolesList = [], data } = useSelector(state => state.permissionReducer);
    const { open: openBar, type: statustype = '' } = useSelector(state => state.snackbarReducer);
    const { data: templatesData = [] } = useSelector(state => state.userTemplates);
    const [state, setFilters] = useState({
        roles: [],
        status: FILTER_STATUS,
    });
    const fullName = AuthStore.FullName;
    const totalUsersCount = userlist?.total_user_count || 0;
    const totalClientsCount = clientList?.total_client_count || 0;
    const location = useLocation();

    useEffect(() => {
        if (!templatesData.length) {
            dispatch(UserTemplates());
        }
        setFilters({
            ...state,
            roles: rolesList?.data?.records || [],
        });
        dispatch(UsersListing(userFilters));
        return () => {
            dispatch(resetData());
            dispatch(closePopUp());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        location?.state?.tab && setTab(location.state?.tab);
    }, [location]);

    const dispatchClientList = async filters => {
        const filterInUse = filters || clientFilter;
        dispatch(
            ClientListing({
                pageNo: filterInUse?.page,
                itemsPerPage: filterInUse?.limit,
                searchParam: filterInUse?.search,
                dateRange: filterInUse?.dateRange,
                filterQuery: filterInUse?.filter,
                sort: filterInUse?.sort,
            })
        );
    };

    useEffect(() => {
        if (singleDeleted || bulkDeleted) {
            setSelectedIds([]);
            setSelectedClientId(-1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [singleDeleted, bulkDeleted]);

    useEffect(() => {
        setUsersData(userlist.records);
    }, [userlist]);

    useEffect(() => {
        setClientsData(clientList.records);
    }, [clientList]);

    useEffect(() => {
        if (userDataFetched && Object.keys(userDetails).length && userDetails) {
            const { data = {} } = userDetails;
            const { email = '', name = '', client_id = '', role_id = '', template_id = '', valid_till } = data;

            if (userDetails && Object.keys(userDetails).length && client_id.length) {
                dispatch(GetClientById({ clientId: client_id[0] }));
            } else {
                setAddUser(true);
            }
            setUserParams({
                name,
                email,
                role_id,
                client_id: client_id.length ? clientData?.uuid || '' : '',
                valid_till: new Date(valid_till),
                template_id: template_id.length === 1 ? template_id[0] : template_id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userDetails]);

    useEffect(() => {
        if (clientData && Object.keys(clientData).length && userDetails && Object.keys(userDetails).length) {
            setUserParams({
                ...userParams,
                client_id: clientData?.uuid || '',
            });
            setAddUser(true);
        }
    }, [clientData]);

    const handleClick = () => {
        history(PATHS.ADD_CLIENT);
    };

    const onSearchFn = debounce(e => {
        const {
            target: { value },
        } = e;
        if (tab === 1) {
            const copy = { ...userFilters, page: 1 };
            copy.search = value;
            setUserFilters(copy);
            dispatch(UsersListing(copy));
        } else {
            const updatedClientFilters = { ...clientFilter, search: value, page: 1 };
            setClientFilters(updatedClientFilters);
            dispatchClientList(updatedClientFilters);
        }
    }, THROTTLE_TIME);

    const onFilterFn = filterData => {
        const { templateType, surveyCount } = filterData;
        const updatedClientFilters = {
            ...clientFilter,
            filter: { templateType: templateType, surveyCount: surveyCount },
            page: 1,
        };
        setClientFilters(updatedClientFilters);
        dispatchClientList(updatedClientFilters);
    };

    const OnDateChangeFn = (startDate, endDate) => {
        if (tab === 1) {
            const copy = cloneDeep(userFilters);
            copy.dateRange.startDate = startDate;
            copy.dateRange.endDate = endDate;
            copy.page = 1;
            setUserFilters(copy);
            dispatch(UsersListing(copy));
        } else {
            const updatedClientFilters = {
                ...clientFilter,
                dateRange: { startDate: startDate, endDate: endDate },
                page: 1,
            };
            setClientFilters(updatedClientFilters);
            dispatchClientList(updatedClientFilters);
        }
    };

    const handleAddUser = () => {
        setAddUser(true);
    };

    const bulkDeleteClient = () => {
        if (selectedIds?.length > 0) {
            dispatch(BulkDeleteClient({ client_ids: [...selectedIds], clientFilter }));
        }
        closeModal();
    };

    const deleteUser = () => {
        setOpen(false);
        const type = usersData.some(({ is_selected }) => is_selected === true) ? 'bulk' : 'single';
        const user_id =
            type === 'single'
                ? userId
                : usersData.filter(({ is_selected }) => is_selected === true).map(({ uuid }) => uuid);
        dispatch(DeleteUser({ user_id, type, userFilters }));
    };

    const closeModal = () => {
        setOpen(false);
    };

    const deleteSingleOrBulkClient = () => {
        if (openConfirmModal.btn === 'singleDelete') {
            deleteSingleClient();
        } else {
            bulkDeleteClient();
        }
    };

    const deleteSingleClient = () => {
        dispatch(DeleteClient({ ids: selectedClientId, clientFilter }));
        closeModal();
    };

    const close = useCallback(() => {
        dispatch(resetSaved());
        dispatch(closePopUp());
    }, [dispatch]);

    const renderTabPanel = () => {
        switch (tab) {
            case 1:
                return (
                    <ParentDiv>
                        <ClientFilter
                            onSearchFn={onSearchFn}
                            exportData={exportUsers}
                            selectedIds={
                                usersData?.length
                                    ? usersData
                                          .filter(({ is_selected }) => is_selected === true)
                                          .map(({ uuid }) => uuid)
                                    : []
                            }
                            openFilterModal={event => {
                                setUserFilterOpen(event.currentTarget);
                            }}
                            OnDateChangeFn={OnDateChangeFn}
                            setOpen={setOpen}
                            key={`user`}
                            tab={tab}
                            filterParam={userFilters}
                            usersData={usersData}
                        />
                        <UserList
                            usersData={usersData}
                            userFilters={userFilters}
                            setUserFilters={setUserFilters}
                            totalUsersCount={userlist?.total}
                            rolesList={rolesList}
                            data={data}
                            getRoleName={getRoleName}
                            dispatch={dispatch}
                            UsersListing={UsersListing}
                            setUsersData={setUsersData}
                            deleteUser={deleteUser}
                            setUserId={setUserId}
                            open={open}
                            setOpen={setOpen}
                            key={`users`}
                        />
                    </ParentDiv>
                );
            case 2:
                return (
                    <ParentDiv>
                        <ClientFilter
                            onSearchFn={onSearchFn}
                            onFilterFn={onFilterFn}
                            OnDateChangeFn={OnDateChangeFn}
                            exportData={exportClients}
                            selectedIds={
                                clientsData?.length
                                    ? clientsData
                                          .filter(({ is_selected }) => is_selected === true)
                                          .map(({ uuid }) => uuid)
                                    : []
                            }
                            openFilterModal={event => {
                                setFilterModalOpenOpen(event.currentTarget);
                            }}
                            filterModalFn={() => setFilterModalOpenOpen(null)}
                            filterOpen={Boolean(filterModalOpen)}
                            anchorEl={filterModalOpen}
                            setOpen={setOpen}
                            setOpenConfirmModal={setOpenConfirmModal}
                            key={`client`}
                            tab={tab}
                            filterParam={clientFilter}
                            clientsData={clientsData}
                        />
                        <ClientList
                            totalClientsCount={clientList?.total}
                            clientsData={clientsData}
                            clientFilter={clientFilter}
                            setClientFilters={setClientFilters}
                            getSurveyName={getSurveyName}
                            getLinkType={getLinkType}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds}
                            setOpen={setOpen}
                            setSelectedClientId={setSelectedClientId}
                            setOpenConfirmModal={setOpenConfirmModal}
                            setClientsData={setClientsData}
                            key={`clients`}
                            templatesData={templatesData}
                            dispatchClientList={dispatchClientList}
                        />
                        {open ? (
                            <SuccessModal
                                open={open}
                                confirmFlag={true}
                                message={`${
                                    openConfirmModal.btn === 'singleDelete'
                                        ? LABELS.DELETE_CLIENT_DESC
                                        : LABELS.DELETE_BULK_CLIENT_DESC
                                }`}
                                title="Delete Client"
                                btnYes={LABELS.DELETE_BTN}
                                btnCancel={LABELS.CANCEL_BTN}
                                onClose={closeModal}
                                onYes={deleteSingleOrBulkClient}
                            />
                        ) : null}
                        {openBar || error ? (
                            <ShowMessage
                                open={openBar || error}
                                type={statustype || !errorMessage ? 'success' : 'error'}
                                message={statustype || !errorMessage ? LABELS.DELETE_CLIENT_SUCCESS : errorMessage}
                                close={close}
                            />
                        ) : null}
                    </ParentDiv>
                );
            default:
                return <Fragment />;
        }
    };

    const exportUsers = () => {
        const fileName = 'Users';
        const array = [];
        usersData.forEach(({ email = '', name = '', role_id = '', client_name = '', survey_ids, last_login_at }) => {
            array.push({
                name,
                role: role_id ? getRoleName(role_id) : '-',
                clientName: client_name,
                email,
                surveyAssigned: survey_ids?.toUpperCase() || '-',
                LastLogin: last_login_at ? `${moment.unix(last_login_at).format('DD-MMM-YYYY | hh:mm A')} (UTC)` : 'NA',
            });
        });
        exportSheet(array, fileName, [LABELS.USERS_HEAD]);
    };

    const getRoleName = roleId => {
        if (rolesList?.data?.records?.length) {
            const {
                data: { records = [] },
            } = rolesList;
            const roleName = records?.find(({ uuid = '' }) => uuid === roleId)?.name;
            return roleName || '-';
        }
        return '-';
    };

    const exportClients = () => {
        const fileName = 'Clients';
        const array = [];
        const { records = [] } = clientList || {};
        records.map(({ client_name, meta_info = [], client_template = [], user_count = 0, survey_count = 0 }) => {
            array.push({
                client_name,
                meta_info: getLinkType(meta_info),
                client_template: client_template?.map(client => getSurveyName([client])).join(', '),
                users: `${user_count} users`,
                survey_count,
            });
        });
        exportSheet(array, fileName, [LABELS.CLIENTS_HEAD]);
    };

    const getSurveyName = clientTemplate => {
        let surveyName = '';
        if (clientTemplate && clientTemplate.length) {
            surveyName = clientTemplate[0].template_name;
        }
        return surveyName;
    };

    const getLinkType = metaArr => {
        const value = metaArr.find(({ meta_key = '' }) => meta_key === 'survey_link_type')?.meta_value;
        return value ? getLinkTypesLabel(value) : '-';
    };

    const handleClinetUsersTab = tabid => {
        setTab(tabid);
        if (tabid === 1) {
            setFilters({
                ...state,
                roles: rolesList?.data?.records || [],
            });
            setClientFilters(initialClientFilters);
            dispatch(UsersListing(userFilters));
        }
        if (tabid === 2) {
            setUserFilters(initialUserFilters);
            setFilters({
                roles: rolesList?.data?.records || [],
                status: FILTER_STATUS,
            });
            dispatchClientList();
        }
    };

    return (
        <Wrapper>
            {loading && <Loader position="fixed" background="transparent" showBackground />}
            <Box className={`${styles.parent}`} data-testid="box">
                <br />
                <FlexBetween style={{ marginBottom: '7vh' }}>
                    <CommonLabel
                        textSize={24}
                        textWeight={400}
                        lineHeight={36}
                        textColor={colorsCode.Neutral80}
                        label={`Hi ${fullName}`}
                    />
                    <FlexStartGap gap={pxToVw(24)}>
                        {/* if admin */}
                        {AuthStore.isAdmin && data?.can_create_users === 'can_create_users' ? (
                            <CTA
                                color={colorsCode.white}
                                size={16}
                                fw={400}
                                lh={24}
                                bg={colorsCode.PrimaryDeepBlue}
                                onClick={handleAddUser}
                            >
                                {LABELS.USER.ADD_USER}
                            </CTA>
                        ) : null}

                        {/*  */}
                        {data?.can_create_clients === 'can_create_clients' ? (
                            <CTA
                                color={colorsCode.white}
                                size={16}
                                fw={400}
                                lh={24}
                                bg={colorsCode.PrimaryDeepBlue}
                                onClick={handleClick}
                            >
                                {LABELS.USER.ADD_CLIENT}
                            </CTA>
                        ) : null}
                    </FlexStartGap>
                </FlexBetween>
                <CommonLabel
                    textSize={32}
                    textWeight={500}
                    lineHeight={36}
                    textColor={colorsCode.Neutral80}
                    label={LABELS.DASHBOARD.Overview}
                />
                <OverViewComponent totalUsersCount={totalUsersCount} totalClientsCount={totalClientsCount} />
                <FlexColumnGap gap={pxToVh(28)} style={{ alignItems: 'flex-start' }}>
                    <CommonLabel
                        textSize={32}
                        textWeight={500}
                        lineHeight={36}
                        textColor={colorsCode.TextHeading}
                        label={LABELS.USER.MANAGE}
                    />
                    <FlexInline>
                        {CLIENT_USER_TABS.map(({ id = '', name = '' }, i) => (
                            <React.Fragment key={i}>
                                {data?.can_view_users === 'can_view_users' && id === 1 ? (
                                    <Pagetab
                                        active={id === tab}
                                        label={name}
                                        clickHandler={() => handleClinetUsersTab(id)}
                                        key={i}
                                    />
                                ) : null}
                                {data?.can_view_clients === 'can_view_clients' && id === 2 ? (
                                    <Pagetab
                                        active={id === tab}
                                        label={name}
                                        clickHandler={() => handleClinetUsersTab(id)}
                                        key={i}
                                    />
                                ) : null}
                            </React.Fragment>
                        ))}
                    </FlexInline>
                </FlexColumnGap>
                {renderTabPanel()}
                <br />
            </Box>
            {/* add edit modal */}
            {addUserOpen && data?.can_update_users === 'can_update_users' ? (
                <AddUpdate
                    button_name={userDetails && Object.keys(userDetails).length ? LABELS.UPDATE : LABELS.USER.ADD_USER}
                    title={
                        userDetails && Object.keys(userDetails).length ? LABELS.USER.UPDATE_USER : LABELS.USER.ADD_USER
                    }
                    open={addUserOpen}
                    close={() => {
                        setAddUser(false);
                        setTab(1);
                    }}
                    styles={styles}
                    setUserParams={setUserParams}
                    userParams={userParams}
                    searchTxt={userFilters.search}
                    filterTxt={userFilters.filters.filters}
                />
            ) : null}

            {userFilterOpen ? (
                <UserFilter
                    open={Boolean(userFilterOpen)}
                    anchorEl={userFilterOpen}
                    openCloseFn={() => setUserFilterOpen(null)}
                    setUserFilters={setUserFilters}
                    userFilters={userFilters}
                    state={state}
                    setFilters={setFilters}
                />
            ) : null}
        </Wrapper>
    );
};

const ParentDiv = styled.div`
    margin-top: 4.05vh;
    // margin-right: 2vw;
`;

export default ClientsUsers;
