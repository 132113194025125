import styled from 'styled-components';
import Chip from '../../../components/KnowledgeCenter/Chip';
import { FlexBetween, FlexCenterWithAlign, FlexColumnWithAlign, FlexRow } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const DeploymentContainer = styled(FlexColumnWithAlign)`
    padding: ${pxToVh(42)}vh 0 ${pxToVh(42)}vh ${pxToVw(0)}vw;
    width: 100%;
`;

export const DeploymentHeadingContainer = styled(FlexRow)`
    width: 100%;
    flex: 1;
`;

export const Divider = styled.div`
    height: 1px;
    background-color: ${colorsCode.Neutral10};
    width: 100%;
    flex: 1;
    margin-right: -${pxToVw(42)}vw;
`;

export const KeyActivitiesContainer = styled(FlexColumnWithAlign)`
    margin: ${pxToVh(29)}vh 0 0;
    padding-left: ${pxToVw(32)}vw;
`;

export const KeyBox = styled(FlexCenterWithAlign)`
    min-width: ${pxToVw(81)}vw;
    max-width: ${pxToVw(81)}vw;
    height: ${pxToVw(90)}vw;
    background-color: ${colorsCode.Neutral10};
    border-radius: 10px;
`;

export const ITTestingContainer = styled(FlexColumnWithAlign)`
    width: 100%;
`;

export const DeploymentContentContainer = styled(FlexColumnWithAlign)`
    width: 100%;
`;

export const AccordianContainer = styled(FlexColumnWithAlign)`
    width: 100%;
`;

export const OutputContainer = styled(FlexColumnWithAlign)`
    ol {
        margin: 0;
        padding-left: ${pxToVh(24)}vh;
    }
`;

export const AccordionParentDiv = styled(FlexColumnWithAlign)`
    width: 100%;
    &:nth-child(even) {
        .content-div {
            flex-direction: row-reverse;

            > :first-child {
            }
        }
    }

    &:nth-child(odd) {
        .content-div {
            > :first-child {
            }
        }
    }
`;

export const NoteChip = styled(Chip)`
    max-width: unset !important;
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 250ms !important;

    @media (max-width: 1330px) {
        span {
            display: block;
            white-space: normal;
        }
    }
`;

export const ParentContentDiv = styled(FlexBetween)`
    width: 100%;
    > * {
        flex: 0.5;
        max-width: calc(50% - ${pxToVw(21.5)}vw);
    }

    .help-icon {
        cursor: pointer;
        path {
            fill: ${colorsCode.Neutral80};
        }
    }
`;
