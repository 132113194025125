import styled from 'styled-components';
import { FlexRow } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled(FlexRow)`
    padding: ${({ padding }) => padding || `${pxToVh(26)}vh ${pxToVw(44)}vw`};
    background-color: ${({ backgroundColor }) => backgroundColor || colorsCode.white};
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    height: ${({ height }) => height || `${pxToVh(134)}vh`};
    width: ${({ width }) => width || `${pxToVw(216)}vw`};
    border: 1px solid ${colorsCode.Neutral10};
    border-radius: 8px;
    justify-content: center;
    align-items: flex-start;

    .double-right-icon {
        margin: 0 -2px;
        height: 20px;
        width: 20px;

        path {
            fill: ${colorsCode.SecondaryDeepBlue2};
        }
    }
`;

export const Icon = styled.img`
    max-height: ${pxToVh(55)}vh;
    max-width: ${pxToVw(55)}vw;
    margin: ${pxToVh(10)}vh ${pxToVw(3)}vw;
`;
