import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { CTA, FlexColumnCenter } from '../../common/styled/styled';
import { CommonLabel, Img } from '../../common/FormInputs/Common';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import { useDispatch } from 'react-redux';
import { resetDataExported } from '../../store/reducers/slice/SurveySlice';
import approved from '../../assets/img/approved.svg';
import CloseIcon from '../../assets/img/close.svg';

const ConfirmationPopUp = ({ isOpen, message }) => {
    const dispatchAction = useDispatch();
    return (
        <Dialog
            open={isOpen}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(400)}vw`,
                    width: `${pxToVw(400)}vw`,
                    borderRadius: '5px',
                },
            }}
        >
            <img
                src={CloseIcon}
                alt=""
                style={{
                    height: `${pxToVh(12)}vh`,
                    width: ` ${pxToVw(12)}vw`,
                    cursor: 'pointer',
                    right: `${pxToVw(10)}vw`,
                    top: `${pxToVh(8)}vh`,
                    position: 'absolute',
                }}
                onClick={() => dispatchAction(resetDataExported())}
            />
            <DialogTitle sx={{ marginTop: '15px' }}>
                <FlexColumnCenter gap="1">
                    <Img image={approved} h={pxToVh(45)} w={pxToVw(45)} alt="" />

                    <CommonLabel
                        textWeight={500}
                        textSize={24}
                        textColor={colorsCode.TextHeading}
                        label="Mail Sent"
                        textAlign="center"
                    />
                </FlexColumnCenter>
            </DialogTitle>
            <DialogContent sx={{ mt: `${pxToVh(10)}vh` }}>
                <CommonLabel
                    textSize={16}
                    lineHeight={24}
                    textColor={colorsCode.TextHeading}
                    label={message}
                    textAlign="center"
                />
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', padding: `${pxToVh(25)}vh 0` }}>
                <CTA
                    size={16}
                    lh={24}
                    fw={400}
                    bg={colorsCode.PrimaryDeepBlue}
                    pt={8}
                    pl={16}
                    color={colorsCode.white}
                    onClick={() => dispatchAction(resetDataExported())}
                >
                    Okay
                </CTA>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationPopUp;
