import { createSlice } from '@reduxjs/toolkit';
import {
    GetFilterOptions,
    GetDemoResponses,
    GetResponseRate,
    UpdateResponseRate,
    GetResponseThreshold,
    UpdateResponseThreshold,
    GetFilteredDemoResponses,
} from '../../actions/MonitorResponse/actions';

export const INTITIAL_STATE = {
    loading: false,
    data: {},
    originalData: {},
    error: false,
    filterOptions: [],
    responseRate: {},
    responseThreshold: {},
    thresholdLoading: false,
    errorMessage: '',
    successMessage: '',
};

const MonitorResponseSlice = createSlice({
    name: 'MonitorResponse',
    initialState: INTITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(GetFilterOptions.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(GetFilterOptions.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.filterOptions = payload.data;
            })
            .addCase(GetFilterOptions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error fetching filter options';
            })
            .addCase(GetDemoResponses.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(GetDemoResponses.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload.data;
                state.originalData = payload.data;
                state.isDataFetched = true;
            })
            .addCase(GetDemoResponses.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error fetching demographic data';
            })
            .addCase(GetFilteredDemoResponses.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(GetFilteredDemoResponses.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload.data;
                state.isDataFetched = true;
            })
            .addCase(GetFilteredDemoResponses.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error fetching demographic data';
            })
            .addCase(GetResponseRate.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(GetResponseRate.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.responseRate = payload.data;
            })
            .addCase(GetResponseRate.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error fetching response rate';
            })
            .addCase(UpdateResponseRate.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(UpdateResponseRate.fulfilled, state => {
                state.loading = false;
            })
            .addCase(UpdateResponseRate.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error updating response rate';
            })
            .addCase(UpdateResponseThreshold.pending, state => {
                state.loading = true;
                state.error = false;
            })
            .addCase(UpdateResponseThreshold.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.successMessage = payload?.message;
            })
            .addCase(UpdateResponseThreshold.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload || 'Error updating response threshold';
            })
            .addCase(GetResponseThreshold.pending, state => {
                state.thresholdLoading = true;
                state.error = false;
            })
            .addCase(GetResponseThreshold.fulfilled, (state, { payload }) => {
                state.thresholdLoading = false;
                const _responseThreshold = payload.data?.reduce((result, current) => {
                    result[current.option_id] = current.threshold;
                    return result;
                }, {});
                state.responseThreshold = _responseThreshold;
            })
            .addCase(GetResponseThreshold.rejected, (state, { payload }) => {
                state.thresholdLoading = false;
                state.error = true;
                state.errorMessage = payload || 'Error fetching response threshold';
            });
    },
    reducers: {
        resetData: state => {
            state.loading = false;
            state.thresholdLoading = false;
            state.errorMessage = '';
            state.successMessage = '';
            state.error = false;
            state.filterOptions = [];
            state.data = {};
            state.originalData = {};
            state.responseRate = {};
            state.responseThreshold = {};
        },
        resetErrorState: state => {
            state.loading = false;
            state.errorMessage = '';
            state.successMessage = '';
            state.error = false;
        },
        setData: (state, { payload }) => {
            state.data = payload;
        },
    },
});

export const { resetData, resetErrorState, setData } = MonitorResponseSlice.actions;
export default MonitorResponseSlice.reducer;
