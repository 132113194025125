import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as MuiButton } from '@mui/material';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';

const Button = ({
    fontColor,
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    label,
    onClick,
    variant,
    endIcon,
    startIcon,
    className,
    isUppercase,
    padding,
    isUnderlined,
    textDecoration,
}) => {
    return (
        <ButtonStyled
            fontWeight={fontWeight}
            $lineHeight={lineHeight}
            letterSpacing={letterSpacing}
            fontSize={fontSize}
            $fontColor={fontColor}
            onClick={onClick}
            variant={variant}
            endIcon={endIcon}
            startIcon={startIcon}
            className={className}
            $isUppercase={isUppercase}
            padding={padding}
            $isUnderlined={isUnderlined}
            $textDecoration={textDecoration}
        >
            {label}
        </ButtonStyled>
    );
};

const ButtonStyled = styled(MuiButton)`
    color: ${({ $fontColor }) => $fontColor} !important;
    font-size: ${({ fontSize }) => pxToVh(fontSize || 12)}vh !important;
    line-height: ${({ $lineHeight }) => pxToVh($lineHeight || 16)}vh !important;
    cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};
    font-weight: ${({ fontWeight }) => fontWeight || 400} !important;
    letter-spacing: ${({ letterSpacing }) => letterSpacing};
    text-transform: ${({ $isUppercase }) => (!$isUppercase ? 'none' : null)} !important;
    text-decoration: ${({ $textDecoration }) => $textDecoration} !important;
    padding: ${({ padding }) => padding} !important;

    ${({ $isUnderlined }) =>
        $isUnderlined &&
        css`
            box-shadow: 0 1px 0 0 ${colorsCode.SecondaryDeepBlue};
            border-radius: 0 !important;
            border: none !important;
        `}

    .MuiButton-endIcon {
        margin-right: 0;
        margin-left: ${pxToVw(8)}vw;
    }

    .MuiButton-startIcon {
        margin-right: ${pxToVw(8)}vw;
        margin-left: 0;
    }
`;

Button.defaultProps = {
    fontColor: '',
    fontSize: null,
    lineHeight: null,
    fontWeight: null,
    letterSpacing: null,
    label: null,
    onClick: null,
    variant: 'contained',
    endIcon: null,
    startIcon: null,
    className: null,
    isUppercase: true,
    padding: null,
    isUnderlined: false,
    textDecoration: null,
};

Button.propTypes = {
    label: PropTypes.string,
    fontColor: PropTypes.string,
    letterSpacing: PropTypes.string,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    fontWeight: PropTypes.number,
    onClick: PropTypes.func,
    variant: PropTypes.string,
    endIcon: PropTypes.node,
    startIcon: PropTypes.node,
    className: PropTypes.string,
    isUppercase: PropTypes.bool,
    padding: PropTypes.string,
    isUnderlined: PropTypes.bool,
    textDecoration: PropTypes.string,
};

export default Button;
