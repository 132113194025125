import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Mid } from '@mid/sdk';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './themes';
import './sass/base.scss';
import { CONFIG } from './global-constants';

const root = ReactDOM.createRoot(document.getElementById('root'));

// MID configuration
const mid = new Mid({
    redirectUrl: `${CONFIG.BASE_URL}/auth/callback`,
    logoutRedirectUrl: `${CONFIG.BASE_URL}/`,
    loginInfo: {
        appId: CONFIG.CLIENT_ID,
        oidcConfigUrl: CONFIG.OIDC_URL,
        authDriver: 'mid',
        flow: 'auth_code',
    },
    async landingFn() {
        window.location.hash = '';
    },
});

root.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme.mui}>
        <Provider store={store}>
            <CssBaseline>
                <App mid={mid} />
            </CssBaseline>
        </Provider>
    </ThemeProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
