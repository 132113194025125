import styled from 'styled-components';
import { FlexBetween, FlexCenterWithAlign, FlexColumnWithAlign } from '../../../common/styled/styled';
import { pxToVw } from '../../../common/colors';
import { SECTION_LEFT_PADDING } from '../../../components/KnowledgeCenter/GanttChart/styles';

export const OrgHealthContentContainer = styled(FlexColumnWithAlign)`
    padding-left: ${SECTION_LEFT_PADDING};
`;

export const OHIContentContainer = styled(FlexColumnWithAlign)`
    padding-left: ${SECTION_LEFT_PADDING};
`;

export const ParentDiv = styled(FlexBetween)`
    width: 100%;
    padding: ${({ padding }) => padding};

    > * {
        flex: ${({ flex }) => 1 - flex || 0.5};
    }

    &:nth-child(even) {
        flex-direction: row-reverse;

        > :first-child {
            margin-left: ${({ marginLeft }) => marginLeft || `${pxToVw(90)}vw`};
            flex: ${({ flex }) => flex};
        }
    }

    &:nth-child(odd) {
        > :first-child {
            margin-right: ${({ marginLeft }) => marginLeft || `${pxToVw(90)}vw`};
            flex: ${({ flex }) => flex};
        }
    }
`;

export const ImageContainer = styled(FlexColumnWithAlign)`
    &.center {
        align-self: center;
        align-items: center;
    }

    &.top-center {
        align-self: flex-start;
        align-items: center;
    }

    &.center-right {
        align-self: center;
        align-items: flex-end;
    }

    &.center-end {
        align-self: center;
        align-items: flex-end;
    }

    &.top-left {
        align-self: flex-start;
        align-items: flex-start;
    }

    &.top-right {
        align-self: flex-start;
        align-items: flex-end;
    }

    &.bottom-left {
        align-self: flex-end;
        align-items: flex-start;
    }

    &.bottom-right {
        align-self: flex-end;
        align-items: flex-end;
    }

    align-self: ${({ alignSelf }) => alignSelf};
`;

export const Image = styled.img`
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
`;

export const ScoreCardsContainer = styled(FlexCenterWithAlign)`
    max-width: ${pxToVw(873)}vw;
    align-self: center;
    gap: ${({ gap }) => gap};
`;

export const CircleImage = styled.div`
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffefd1;
    padding: 5px 5px 5px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
