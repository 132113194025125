import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    open: false,
    type: '',
    snackMessage: '',
};

const SnackbarSlice = createSlice({
    name: 'SurveyReducer',
    initialState: INITIAL_STATE,
    reducers: {
        openPopUp: (state, { payload }) => {
            state.open = true;
            state.type = payload;
            state.snackMessage = '';
            if (typeof payload === 'object') {
                state.type = payload.type;
                state.snackMessage = payload.message;
            }
        },
        closePopUp: state => {
            state.open = false;
            state.type = '';
            state.snackMessage = '';
        },
    },
});

export const { openPopUp, closePopUp } = SnackbarSlice.actions;
export default SnackbarSlice.reducer;
