/* istanbul ignore file */
import axios from 'axios';
import { api } from '../../http/api';
import { Endpoints } from '../../http/Endpoints';
import { parseJwt } from '../../utils/functions';
import { CONFIG } from '../../global-constants';
import AuthStore from '../AuthStore';
import { clearChatHistory } from '@ohi/genai-fe';
import moment from 'moment/moment';

export const Login = async midToken => {
    try {
        const response = await api.get(Endpoints.loginUrl, {
            headers: {
                Authorization: `Bearer ${midToken}`,
            },
        });
        const infoFromToken = parseJwt(midToken);
        const { auth_time = 0 } = infoFromToken;
        const {
            data: { access_token = '', refresh_token = '', token_type = '' },
        } = response;
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('refresh_token', refresh_token);
        localStorage.setItem('midToken', midToken);
        localStorage.setItem('auth_time', auth_time);
        axios.defaults.headers[`Authorization`] = `${token_type} ${access_token}`;
    } catch (error) {
        Logout();
    }
};

export const Logout = redirectPath => {
    clearChatHistory();
    console.warn('Clearing storages...!');
    localStorage.clear();
    sessionStorage.clear();
    console.warn('Logging out...!');
    window.location.replace(`${CONFIG.BASE_URL}${redirectPath ? redirectPath : ''}`);
};

/**
 * refresh token api calling
 */
export const checkForRefresh = async resetAnyhow => {
    const access_token = AuthStore.accessToken;
    if (access_token && AuthStore.refreshToken) {
        const { exp } = AuthStore.parsedData;
        if ((exp && moment().unix() - exp < 10800) || resetAnyhow) {
            const response = await axios.request({
                method: 'GET',
                url: `${CONFIG.BASE_PATH_APP}${Endpoints.refreshToken}`,
                headers: {
                    Authorization: `Bearer ${AuthStore.refreshToken}`,
                },
            });
            const {
                data: { access_token: newAccessToken = '' },
            } = response;
            axios.defaults.headers[`Authorization`] = newAccessToken;
            AuthStore.accessToken = newAccessToken;
        }
    }
};
