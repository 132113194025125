/* istanbul ignore file */
import axios from 'axios';
import { CONFIG } from '../global-constants';
import AuthStore from '../common/AuthStore';
import { store } from '../store';
import { setStatusCode } from '../store/reducers/slice/Modal';

export const api = axios.create({
    baseURL: CONFIG.BASE_PATH_APP,
    timeout: 180000,
    headers: {
        Authorization: `Bearer ${AuthStore.accessToken}`,
    },
});

api.interceptors.request.use(
    async config => {
        config.headers = {
            Authorization: `Bearer ${AuthStore.accessToken}`,
        };
        return config;
    },
    () => {
        console.log('NOT AUTHENTICATED');
    }
);

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status === 401) {
            store.dispatch(setStatusCode(401));
        } else if (error?.response?.status === 404) {
            store.dispatch(setStatusCode(404));
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);
