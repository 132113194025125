import styled from 'styled-components';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled(FlexColumnWithAlign)`
    padding: ${({ padding }) => padding || `${pxToVh(20)}vh ${pxToVw(14)}vw`};
    background-color: ${({ backgroundColor }) => backgroundColor || colorsCode.white};
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    height: ${({ height }) => height || `${pxToVh(257)}vh`};
    width: ${({ width }) => width || `${pxToVw(216)}vw`};
    cursor: pointer;
    border-radius: 8px;
    flex-grow: 1;
`;

export const MediaContainer = styled.div`
    position: relative;
    min-height: ${({ mediaHeight }) => mediaHeight || `${pxToVh(176)}vh`};
    max-height: ${({ mediaHeight }) => mediaHeight || `${pxToVh(210)}vh`};
    width: 100%;
`;

export const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;
