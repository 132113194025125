import { Autocomplete, FormHelperText, TextField } from '@mui/material';
import React, { memo, useState } from 'react';
import ArrowDownIcon from '../../../assets/img/arrow_down.svg';
import PropTypes from 'prop-types';
import { colorsCode, pxToVw } from '../../../common/colors';
import { FormControl } from '@mui/base';

const AutocompleteInput = ({
    getOptionLabelFn,
    data,
    value,
    handleAutocomplete,
    handleSearch,
    width,
    name,
    placeholder = 'Search client',
    disabled,
    error = false,
    message = '',
    noOptionsText = 'No options',
    errorMessage = '',
    hideIcon = false,
    isLoading = false,
    isControlledInput = false,
    renderOption,
    fullWidth = false,
}) => {
    const [val, setVal] = useState(null);

    const onChange = e => {
        const {
            target: { value },
        } = e;
        setVal(value);
        handleSearch(e);
    };

    const onAutoCompleteChange = (...args) => {
        setVal(null);
        handleAutocomplete(...args);
    };

    const shouldOpenDropdown = val?.length >= 3 && !isLoading ? true : false;

    return (
        <FormControl style={{ width: fullWidth ? '100%' : '' }}>
            <Autocomplete
                id="client_id"
                name={name}
                // autoComplete="off"
                options={data && data.length ? data : []}
                value={value}
                disabled={disabled}
                data-testid="auto"
                open={isControlledInput ? shouldOpenDropdown : undefined}
                onClose={() => setVal(null)}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        padding: `0 0.28vw 0 ${pxToVw(16)}vw`,
                    },
                    '&&& input': {
                        paddingLeft: '0',
                    },
                    '&& .MuiAutocomplete-endAdornment': {
                        right: `${pxToVw(16)}vw`,
                    },
                    borderColor: !error ? '#B3B3B3' : colorsCode.error,
                }}
                onChange={onAutoCompleteChange}
                getOptionLabel={option => (getOptionLabelFn(option, name) ? getOptionLabelFn(option, name) : '')}
                popupIcon={!hideIcon ? <img src={ArrowDownIcon} alt="dropdown" /> : null}
                noOptionsText={noOptionsText}
                renderOption={renderOption}
                renderInput={params => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        onChange={onChange}
                        error={error}
                        helperText={message}
                        autoComplete="off"
                        name={name}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: '5.67vh',
                                borderColor: !error ? '#B3B3B3' : colorsCode.error,
                                width: fullWidth ? '100%' : `${width}vw`,
                                borderRadius: '2px',
                                fontSize: `1.98vh`,
                            },
                        }}
                    />
                )}
            />
            <FormHelperText sx={{ color: colorsCode.error }}>{errorMessage}</FormHelperText>
        </FormControl>
    );
};

AutocompleteInput.propTypes = {
    getOptionLabelFn: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    value: PropTypes.string,
    handleAutocomplete: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    search: PropTypes.string,
    width: PropTypes.string.isRequired,
    hideIcon: PropTypes.bool,
};

export default memo(AutocompleteInput);
