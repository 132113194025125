import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Navbar from '../../../components/KnowledgeCenter/Navbar';
import Footer from '../../../components/KnowledgeCenter/Footer';
import { PATHS } from '../../../global-constants';
import { useSelector, useDispatch } from 'react-redux';
import E404 from '../../../components/E404';
import { UserTemplates } from '../../../store/actions/Surveys/action';
import { Loader } from 'OHI-distribute-survey';

export const AppContext = createContext({});

const Home = ({ isDeepLink, setIsDeepLink }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const [activeSection, setActiveSection] = useState(null);
    const { search } = location;
    const params = new URLSearchParams(search);
    const { data = [], loading } = useSelector(state => state.userTemplates);
    const [isValidUrl, setValidStatusUrl] = useState(true);

    useEffect(() => {
        const apiCall = async () => {
            if (!data.length) {
                await dispatch(UserTemplates());
            }
        };
        apiCall();
        const version = params.get('version');
        if (version) {
            const status = data.map(e => e.knowledge_centre_version).includes(version);
            setValidStatusUrl(status);
        } else {
            if (location.pathname !== PATHS.KNOWLEDGE_CENTER) {
                setValidStatusUrl(false);
            }
        }
    }, [data]);

    useEffect(() => {
        const isDeepLink = params.get('deep_link') === '1';
        setIsDeepLink(isDeepLink);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const value = {
        activeSection,
        setActiveSection,
    };

    const getNavBar = () => {
        if (isDeepLink && location.pathname.toLowerCase() == `${PATHS.KNOWLEDGE_CENTER}/`) {
            return null;
        } else if (location.pathname.toLowerCase() == PATHS.KNOWLEDGE_CENTER) {
            return null;
        } else {
            return <Navbar />;
        }
    };

    const contentPage = () => {
        return isValidUrl ? (
            <Container isDeepLink={isDeepLink}>
                {getNavBar()}
                <Outlet />
                <Footer />
            </Container>
        ) : loading ? (
            <Loader showBackground background="transparent" position="fixed" />
        ) : (
            <E404></E404>
        );
    };

    if (isDeepLink === null) return null;

    return (
        <AppContext.Provider value={value}>
            {loading ? <Loader showBackground background="transparent" position="fixed" /> : contentPage()}
        </AppContext.Provider>
    );
};

export const Container = styled.div`
    ${({ isDeepLink }) =>
        !isDeepLink &&
        css`
            margin-left: 13vw;
        `}
`;

Home.defaultProps = {
    isDeepLink: false,
    setIsDeepLink: () => {},
};

Home.propTypes = {
    isDeepLink: PropTypes.bool,
    setIsDeepLink: PropTypes.func,
};

export default Home;
