import { createAsyncThunk } from '@reduxjs/toolkit';
import { Endpoints } from '../../../http/Endpoints';
import {
    MESSAGE,
    additional_module_ques,
    additional_ques,
    demographic_ques,
    ohi_template_ques,
    open_text_ques,
} from '../../../global-constants';
import { api } from '../../../http/api';

export const GetDisplayLogicQuestion = createAsyncThunk('displayLogicQuestion', async (params, { rejectWithValue }) => {
    try {
        const { surveyId, selectedTab, subGroupId = '' } = params;
        const url = `${Endpoints.createSurvey}/${surveyId}/question-list-with-conditions?question_type=${selectedTab}${
            subGroupId ? `&sub_group_id=${subGroupId}` : ''
        }`;
        const res = await api.get(url);
        return { data: res.data.data.records, selectedTab: selectedTab };
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetSubGroupList = createAsyncThunk('subGroupList', async (params, { rejectWithValue }) => {
    try {
        const { surveyId } = params;
        const url = `${Endpoints.createSurvey}/${surveyId}/subgroup-list`;
        const res = await api.get(url);
        return res.data.data.records;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetPreviousQuestionList = createAsyncThunk('previousQuestionList', async (params, { rejectWithValue }) => {
    try {
        const { surveyId, question_id, questionType = 'ALL' } = params;
        const url = `${Endpoints.createSurvey}/${surveyId}/question-list-with-conditions?question_type=${questionType}&excluded_question_id=${question_id}`;
        const res = await api.get(url);
        return res.data.data.records;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const SaveDisplayLogic = createAsyncThunk('saveDisplayLogic', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { surveyId, tempPayload, activeTab = '', subGroupId = '' } = params;
        const url = `${Endpoints.createSurvey}/${surveyId}/conditional-question`;
        const res = await api.post(url, { ...tempPayload });
        let selectedTab = '';
        switch (activeTab) {
            case demographic_ques:
                selectedTab = 'DEMOGRAPHIC';
                break;
            case open_text_ques:
                selectedTab = 'OPEN_TEXT';
                break;
            case ohi_template_ques:
                selectedTab = 'STANDARD';
                break;
            case additional_module_ques:
                selectedTab = 'ADDITIONAL_MODULE';
                break;
            case additional_ques:
                selectedTab = 'ADDITIONAL_QUESTION';
                break;
        }

        dispatch(
            GetDisplayLogicQuestion({
                surveyId: surveyId,
                selectedTab: selectedTab,
                subGroupId: subGroupId,
            })
        );
        return res.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const DeleteDisplatLogic = createAsyncThunk(
    'deleteDisplayLogic',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { surveyId, question_id, activeTab = '', subGroupId = '' } = params;

            const url = `${Endpoints.createSurvey}/${surveyId}/conditional-question/${question_id}`;
            const res = await api.delete(url);
            let selectedTab = '';
            switch (activeTab) {
                case demographic_ques:
                    selectedTab = 'DEMOGRAPHIC';
                    break;
                case open_text_ques:
                    selectedTab = 'OPEN_TEXT';
                    break;
                case ohi_template_ques:
                    selectedTab = 'STANDARD';
                    break;
                case additional_module_ques:
                    selectedTab = 'ADDITIONAL_MODULE';
                    break;
                case additional_ques:
                    selectedTab = 'ADDITIONAL_QUESTION';
                    break;
            }
            dispatch(
                GetDisplayLogicQuestion({
                    surveyId: surveyId,
                    selectedTab: selectedTab,
                    subGroupId: subGroupId,
                })
            );

            return res.data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);
