/* istanbul ignore file */
import React, { Suspense, useState } from 'react';
import { Typography } from '@mui/material';
import Loader from '../Loader';
import AuthStore from '../../common/AuthStore';
import Unauthenticated from '../Unauthenticated';
import styled, { css } from 'styled-components';
import ChatBotImg from '../../assets/img/chatbot.svg';
import Edit from '../../assets/img/pen.svg';
import { FlexRow } from '../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import { openSlackChannel } from '../../utils/functions';
import { ChatBot } from '@ohi/genai-fe';
import Text from '../KnowledgeCenter/Text';
import Feedback from '../Feedback/Feedback';
import { ShowMessage } from '../../common/FormInputs/Snackbar';

const SecureRoutes = ({ children, isAdmin = false, canAccess = true, isLoading = false }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackSubmitRes, setFeedbackSubmitRes] = useState('');
    const tempHide = false; //temporarily hiding Chat with us since we have genai chatbot
    const refreshToken = AuthStore.refreshToken;
    const [showUploadMessage, setUploadMessage] = useState(null);

    if (isLoading) {
        return <Loader showBackground position="absolute" background="transparent" />;
    }
    if (canAccess !== undefined && canAccess === false) {
        return <Unauthenticated />;
    } else if (isAdmin && !AuthStore.isAdmin) {
        return <Unauthenticated />;
    }
    return (
        <Suspense fallback={<Loader position="absolute" showBackground background="transparent" />}>
            <FeedbackDiv onClick={() => setShowFeedbackModal(true)}>
                <img src={Edit} />
                <Text
                    fontColor={colorsCode.NeutralWhite}
                    fontSize={16}
                    lineHeight={20}
                    label="Feedback"
                    fontWeight={400}
                    letterSpacing="1px"
                />
            </FeedbackDiv>
            {tempHide ? (
                <ChatBotDiv
                    onMouseEnter={() => setIsExpanded(true)}
                    onMouseLeave={() => setIsExpanded(false)}
                    isExpanded={isExpanded}
                    onClick={openSlackChannel}
                >
                    <img src={ChatBotImg} alt="chatbot" />
                    <ChatBotText component="span" className="chatbot-text">
                        Chat with us
                    </ChatBotText>
                </ChatBotDiv>
            ) : null}
            {!AuthStore?.isClient ? <ChatBot userAuthToken={refreshToken} userName={AuthStore.name} /> : null}
            {children}
            {showFeedbackModal ? (
                <Feedback
                    onClose={() => setShowFeedbackModal(false)}
                    showFeedbackModal={showFeedbackModal}
                    setFeedbackSubmitRes={val => setFeedbackSubmitRes(val)}
                    setUploadMessage={setUploadMessage}
                />
            ) : null}
            {feedbackSubmitRes?.length ? (
                <ShowMessage
                    open={feedbackSubmitRes?.length}
                    type={feedbackSubmitRes?.length ? 'success' : 'error'}
                    message={feedbackSubmitRes}
                    close={() => setFeedbackSubmitRes('')}
                />
            ) : null}
            {showUploadMessage && (
                <ShowMessage
                    open={showUploadMessage?.length}
                    type="success"
                    message={showUploadMessage}
                    close={() => setUploadMessage(null)}
                />
            )}
        </Suspense>
    );
};

const FeedbackDiv = styled.div`
    position: fixed;
    top: 35%;
    right: 16px;
    transform: translateY(0) rotate(270deg);
    transform-origin: right;
    white-space: nowrap;
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: ${colorsCode.neutral60};
    z-index: 16;
    display: flex;
    gap: 8px;
    img {
        width: ${pxToVw(12)};
        height: ${pxToVh(12)};
        transform: rotate(90deg);
    }
    &:hover {
        box-shadow: 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        cursor: pointer;
    }
`;

const ChatBotDiv = styled(FlexRow)`
    position: fixed;
    bottom: 7vh;
    right: 2vh;
    z-index: 15;
    background-color: ${colorsCode.secondaryAmber600};
    border-radius: 25px;
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    cursor: pointer;
    gap: 12px;
    padding: 12px;
    transition: width 500ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 53px;

    .chatbot-text {
        height: 0;
        opacity: 0;
        transition: height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        white-space: nowrap;
    }

    ${({ isExpanded }) =>
        isExpanded &&
        css`
            width: 153px;

            .chatbot-text {
                height: 100%;
                opacity: 1;
            }
        `}
`;

const ChatBotText = styled(Typography)`
    color: ${colorsCode.white};
    cursor: pointer;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;
`;

export default SecureRoutes;
