import { createSlice } from '@reduxjs/toolkit';
import {
    getOpenEndStandardQuestions,
    getOpenEndCustomQuestions,
    updateOpenEndQues,
    updateInstructionOpenText,
} from '../../actions/TextEdit/actions';

export const INITIAL_STATE = {
    loading: false,
    standard: [],
    custom: [],
    isUpdated: false,
    error: false,
    errorMessage: '',
    subGroup: {},
};

const OpenEnd = createSlice({
    initialState: INITIAL_STATE,
    name: 'OpenEnd',
    extraReducers: builder => {
        builder
            .addCase(getOpenEndStandardQuestions.pending, state => {
                state.loading = true;
            })
            .addCase(getOpenEndStandardQuestions.fulfilled, (state, { payload }) => {
                state.loading = false;
                const array = Object.values(payload.data.data.records);
                const modified = array.length ? array.map(item => ({ ...item, is_edited: false })) : [];
                state.subGroup = payload.data.data.sub_group;
                state.standard = modified;
            })
            .addCase(getOpenEndStandardQuestions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(getOpenEndCustomQuestions.pending, state => {
                state.loading = true;
            })
            .addCase(getOpenEndCustomQuestions.fulfilled, (state, { payload }) => {
                state.loading = false;
                const array = Object.values(payload.data.data.records);
                const modified = array.length ? array.map(item => ({ ...item, is_edited: false })) : [];
                state.custom = modified;
            })
            .addCase(getOpenEndCustomQuestions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateOpenEndQues.pending, state => {
                state.loading = true;
            })
            .addCase(updateOpenEndQues.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateOpenEndQues.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateInstructionOpenText.pending, state => {
                state.loading = true;
            })
            .addCase(updateInstructionOpenText.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateInstructionOpenText.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        resetOpenEnd: state => {
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = '';
        },
    },
});

export const { resetOpenEnd } = OpenEnd.actions;
export default OpenEnd.reducer;
