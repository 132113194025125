import { Box, Typography, Divider, FormControlLabel, Checkbox } from '@mui/material';
import React, { memo, useEffect } from 'react';
import InputField from '../../../../common/FormInputs/InputField';
import {
    Flex,
    FlexCenter,
    FlexColumn,
    FlexColumnGap,
    FlexRow,
    FlexStart,
    FlexStartGap,
} from '../../../../common/styled/styled';
import generic from '../../../../assets/img/genericlink.svg';
import individuallink from '../../../../assets/img/individuallink.svg';
import individualPlusGeneric from '../../../../assets/img/individualPlusGeneric.svg';
import { useDispatch, useSelector } from 'react-redux';
import { IndustryListing } from '../../../../store/actions/Client/actions';
import { ERROR_MSG, LABELS, LIMIT, REGEX, SURVEY_TYPE } from '../../../../global-constants';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import CheckboxSvg from '../../../../assets/img/Checkbox.svg';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import DropdownMenu from '../../../../common/FormInputs/DropdownMenu';
import { CommonLabel } from '../../../../common/FormInputs/Common';
import styled from 'styled-components';

const ClientOverview = ({ setClientState, clientState, client_id, clientData }) => {
    const {
        CREATE_SURVEY,
        CREATE_SURVEY: { Overview_Title },
    } = LABELS;
    const dispatch = useDispatch();
    const { industry: industryData = [] } = useSelector(state => state.clientReducer);
    const isNumber = REGEX.isnumber.test(clientState.maximum_survey_limit);
    const industriesData = industryData?.records || [];

    const styleObj = {
        width: `${pxToVw(26)}vw`,
        height: `${pxToVh(26)}vh`,
        color: colorsCode.TextHeading,
    };
    const checkBoxStyle = {
        width: `${pxToVw(18)}vw`,
        height: `${pxToVh(18)}vh`,
    };

    useEffect(() => {
        if (!industryData?.records?.length) {
            dispatch(IndustryListing({ page: 1, limit: LIMIT }));
        }
    }, []);

    const handleInput = e => {
        const {
            target: { name, value },
        } = e;
        setClientState({ ...clientState, [name]: value });
    };

    const handleChecked = e => {
        const {
            target: { checked, value },
        } = e;
        const copy = cloneDeep(clientState);
        if (checked) {
            const linktType = copy.survey_link_type.filter(item => item);
            linktType.push(value);
            copy.survey_link_type = linktType;
            setClientState(copy);
        } else {
            const filterData = copy.survey_link_type.filter(item => item !== value);
            setClientState({ ...clientState, survey_link_type: filterData });
        }
    };

    const checkForValidOrgName = name => {
        return name && (name.replace(/\s/g, '').length === 0 || !REGEX.clientName.test(name));
    };

    const getErrorMessage = () => {
        if (!isNumber) {
            return ERROR_MSG.MAX_SURVEY_LIMIT;
        }
        if (client_id && parseInt(clientState.maximum_survey_limit) < clientData.survey_count) {
            return 'Survey limit cannot be less than surveys created';
        }
        return '';
    };

    const getLinkProps = linkType => {
        if (linkType === 'GENERIC') {
            return {
                desc: CREATE_SURVEY.GENERIC_DESC,
                icon: individuallink,
            };
        } else if (linkType === 'INDIVIDUAL') {
            return {
                desc: CREATE_SURVEY.INDIVIDUAL_DESC,
                icon: generic,
            };
        } else {
            return {
                desc: CREATE_SURVEY.INDIVIDUAL_PLUS_GENERIC_DESC,
                icon: individualPlusGeneric,
            };
        }
    };
    const getLinkType = () => {
        return Object.keys(SURVEY_TYPE).map(key => {
            const isSelected = clientState.survey_link_type.includes(key);
            const { desc, icon } = getLinkProps(key);
            return (
                <SurveyTypeDiv
                    key={key}
                    bg={isSelected ? colorsCode.cyan100 : colorsCode.white}
                    bcolor={isSelected ? `1px solid ${colorsCode.primaryCyan}` : `none`}
                >
                    <CheckboxDiv>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    inputProps={{
                                        'data-testid': 'link',
                                    }}
                                    name="survey_link_type"
                                    sx={{ height: 0, width: 0 }}
                                    id={key}
                                    checked={isSelected}
                                    value={key}
                                    onClick={e => handleChecked(e, key)}
                                    checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                    icon={<img src={CheckboxSvg} style={checkBoxStyle} />}
                                />
                            }
                        />
                    </CheckboxDiv>
                    <FlexColumnGap gap={pxToVh(8)} style={{ width: `${pxToVw(200)}vw` }}>
                        <FlexCenter style={{ gap: `${pxToVw(20)}vw` }}>
                            <img src={icon} alt="" className="img" />
                            <ContentDiv>
                                <CommonLabel
                                    textColor={colorsCode.Neutral80}
                                    textSize={16}
                                    textWeight={400}
                                    lineHeight={24}
                                    label={CREATE_SURVEY[key]}
                                />
                            </ContentDiv>
                        </FlexCenter>
                        <FlexStartGap gap={pxToVw(35)}>
                            <Typography />
                            <CommonLabel
                                textColor={colorsCode.TextSubtle}
                                textSize={16}
                                textWeight={400}
                                lineHeight={24}
                                title={desc}
                                label={desc}
                            />
                        </FlexStartGap>
                    </FlexColumnGap>
                </SurveyTypeDiv>
            );
        });
    };

    return (
        <Box className="clientOverview" data-testid="clientoverview">
            <Divider />
            <Box className="partone">
                <FlexStart>
                    <Typography className="partonetitle">{Overview_Title.details}</Typography>
                </FlexStart>
                <FlexRow className="rowGap">
                    <FlexColumn className="columngap">
                        <Typography className="columngaptext">{CREATE_SURVEY.ORG}</Typography>
                        <InputField
                            placeholder="Enter organization name"
                            name="company_name"
                            id="clientname"
                            val={clientState.company_name}
                            onChangeFn={handleInput}
                            width={pxToVw(540)}
                            error={checkForValidOrgName(clientState.company_name) ? true : false}
                            errorMessage={
                                checkForValidOrgName(clientState.company_name)
                                    ? 'Please provide valid client name.'
                                    : ''
                            }
                        />
                    </FlexColumn>
                    <FlexColumn className="columngap">
                        <Typography className="columngaptext">{CREATE_SURVEY.Industry}</Typography>
                        <DropdownMenu
                            id="industry"
                            name="industry"
                            categories={industriesData}
                            placeholder="Select industry"
                            grouped
                            width={pxToVw(540)}
                            height={5.67}
                            value={clientState.industry}
                            onChangeFn={handleInput}
                            defaultValue={clientState.industry}
                        />
                    </FlexColumn>
                </FlexRow>
            </Box>
            <Divider />
            <Box className="partone">
                <FlexStart>
                    <Typography className="partonetitle">{Overview_Title.Link}</Typography>
                </FlexStart>
                <FlexColumnGap gap={pxToVw(40)}>
                    <FlexRow className="linktyperow">
                        <FlexColumn style={{ gap: '1vh' }}>
                            <Typography className="columngaptext">{CREATE_SURVEY.TYPE}</Typography>
                            <FlexRow style={{ gap: '2vw' }} alignItems={'unset'}>
                                {getLinkType()}
                            </FlexRow>
                        </FlexColumn>
                    </FlexRow>
                    <FlexRow>
                        <FlexColumn style={{ gap: '1vh' }}>
                            <Typography className="columngaptext">{CREATE_SURVEY.SURVEY_LIMIT}</Typography>
                            <InputField
                                placeholder="Enter survey limit"
                                id="clientname"
                                name="maximum_survey_limit"
                                val={clientState.maximum_survey_limit}
                                onChangeFn={handleInput}
                                width={pxToVw(540)}
                                error={!!getErrorMessage()}
                                errorMessage={getErrorMessage()}
                            />
                        </FlexColumn>
                    </FlexRow>
                </FlexColumnGap>
            </Box>
        </Box>
    );
};

const SurveyTypeDiv = styled(Flex)`
    padding: ${pxToVh(20)}vh ${pxToVw(44)}vw ${pxToVh(22)}vh ${pxToVw(20)}vw;
    box-shadow: ${colorsCode.boxShadow};
    border-radius: 4px;
    background: ${({ bg }) => bg};
    border: ${({ bcolor }) => bcolor};
    position: relative;
`;

export const CheckboxDiv = styled.div`
    position: absolute;
    right: 5px;
    top: 14px;
    display: flex;
    align-items: flex-start;
`;

const ContentDiv = styled.div`
    height: ${({ h }) => pxToVh(h)}vh;
    width: ${({ w }) => pxToVw(w)}vw;
`;

ClientOverview.propTypes = {
    setClientState: PropTypes.func.isRequired,
    clientState: PropTypes.shape({
        maximum_survey_limit: PropTypes.string,
        company_name: PropTypes.string,
        industry: PropTypes.string,
        survey_link_type: PropTypes.oneOf(['GENERIC', 'INDIVIDUAL']),
    }),
    client_id: PropTypes.string,
    clientData: PropTypes.shape({}),
};

export default memo(ClientOverview);
