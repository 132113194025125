/* istanbul ignore file */
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState, useEffect, useRef } from 'react';
import {
    // REPLACE_CHARS,
    BASE_VALUE,
    ACTUAL_STATUS,
    REGEX,
    SURVEY_TYPE_MAPPING,
} from '../global-constants';

export const parseJwt = (token = '') => {
    if (!token) return {};
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
};

export const exportSheet = (arrayData, fileName, heading) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const data = XLSX.utils.json_to_sheet(arrayData);
    // json data
    const sheetInformation = { Sheets: { data: data }, SheetNames: ['data'] };
    // column name
    XLSX.utils.sheet_add_aoa(data, heading, { origin: 'A1' });
    //write data in sheet
    const excelBuffer = XLSX.write(sheetInformation, {
        bookType: 'xlsx',
        type: 'array',
    });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
};

export const exportSheets = (arrayData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const data = {};
    Object.keys(arrayData).forEach(key => {
        const value = arrayData[key];
        const _data = XLSX.utils.json_to_sheet(value);
        data[key] = _data;
    });

    // json data
    const sheetInformation = {
        Sheets: { ...data },
        SheetNames: Object.keys(arrayData).length === 0 ? ['sheet'] : Object.keys(arrayData),
    };

    //write data in sheet
    const excelBuffer = XLSX.write(sheetInformation, {
        bookType: 'xlsx',
        type: 'array',
    });
    const fileData = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(fileData, fileName + fileExtension);
};

export const convertToTitleCase = text => {
    if (!text) return '';
    const arr = text.toLowerCase().split(',');
    const newArr = arr.map(item => item.charAt(0).toUpperCase() + item.slice(1));
    return newArr.join(',');
};

export const getLinkTypesLabel = value => {
    const linkTypes = value.split(',');
    let label = '';
    linkTypes.forEach((type, idx) => {
        const valUsed = type.replace(/\s/g, '');
        label = label + SURVEY_TYPE_MAPPING[valUsed]?.label;
        if (idx !== linkTypes.length - 1) {
            label = label + ', ';
        }
    });

    return label;
};

//ALLOWED ALL SPECIAL CHARACTERS
export const sanitize = str => {
    // const reg = /[=*<>]/gi;
    // return str.replace(reg, match => {
    //     return REPLACE_CHARS[match];
    // });
    return str;
};

export const getMonitorResponse = (
    data,
    positiveVal,
    negativeVal,
    totalResponses,
    isGenericType,
    thresholdValues = {}
) => {
    if (data.complete === 0) {
        return '';
    }
    // individual survey
    if (!isGenericType) {
        if (totalResponses < BASE_VALUE) {
            return negativeVal;
        }
        if (data.complete < BASE_VALUE) {
            return negativeVal;
        }
        return positiveVal;
    }
    // generic survey
    if (data.complete < (thresholdValues[data.uuid] || BASE_VALUE)) {
        return negativeVal;
    }
    return positiveVal;
};

export const getResponseRatePercent = (total_completed, total_respondent) => {
    if (!total_completed) {
        return '0';
    }
    let percentile = (total_completed / total_respondent) * 100;
    const getDecimal = Number(percentile.toString().split('.')[1]?.[0]);
    percentile = getDecimal > 0 && getDecimal >= 5 ? Math.ceil(percentile) : Math.floor(percentile);
    return percentile;
};

export const getSentenceCase = str => {
    const _str = str || '';
    if (_str && ACTUAL_STATUS[str]) {
        return ACTUAL_STATUS[str];
    } else if (_str) {
        const firstLetter = _str[0].toUpperCase();
        const remaining = _str.slice(1).toLowerCase();
        return `${firstLetter}${remaining}`;
    }
    return '-';
};

export const checkFilterApplied = (state, key) => {
    const stateValues = Object.values(state || {});
    for (let i = 0; i < stateValues.length; i++) {
        const filterArr = stateValues[i] || [];
        for (let j = 0; j < filterArr.length; j++) {
            const item = filterArr[j];
            if (item?.[key]) {
                return true;
            }
        }
    }
    return false;
};

export const convertDateToTimestamp = dateVal => {
    if (dateVal) {
        const value = new Date(dateVal);
        return value.getTime();
    }
};

export const useIntersection = (elements, rootMargin = '-50% 0% -50% 0%') => {
    const [isVisible, setState] = useState(false);
    const prevEl = useRef({});

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const entryId = entry.target.getAttribute('id');
                    prevEl.current[entryId] = entry;
                });
                let _isVisible = 0;
                let _el = null;
                Object.values(prevEl.current).forEach(elem => {
                    if (elem.intersectionRatio >= _isVisible && elem.isIntersecting) {
                        _isVisible = elem.intersectionRatio;
                        _el = elem;
                    }
                });
                setState(_el?.target?.getAttribute('id'));
            },
            { root: null, rootMargin, threshold: 0 }
        );

        if (elements?.current?.length > 0) {
            elements?.current?.forEach(el => el && observer?.observe(el));

            return () => {
                observer.disconnect();
            };
        }

        return () => {};
    }, [elements, rootMargin]);

    return isVisible;
};

export const isHTML = RegExp.prototype.test.bind(REGEX.HTML);

export const alphaVal = number => String.fromCharCode(97 + number).toUpperCase();

export const downloadURL = url => {
    if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.click();
    }
};

export const isString = value => typeof value === 'string' || value instanceof String;

export const openSlackChannel = () => {
    let downloadLink = document.createElement('a');
    downloadLink.target = '_blank';
    downloadLink.href = 'https://slack.com/app_redirect?channel=C05PYR99VBL';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
};

export const generatePasscode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let uuid = '';
    for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        uuid += characters[randomIndex];
    }
    return uuid;
};

export const downloadFile = url => {
    if (url) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
    }
};

export const generateUUID = () => {
    return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
        (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16)
    );
};
