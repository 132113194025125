import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import Text from '../../../components/KnowledgeCenter/Text';
import Button from '../../../components/KnowledgeCenter/Button';
import { FlexBetween } from '../../../common/styled/styled';
import { ScrollContainer, ContentCardListWrapper, IconButton } from './styles';
import arrowRight from '../../../assets/img/Arrow-right.svg';
import { LANDING_PAGE_TEXT } from '../../../utils/kc-constants';
import ContentCard from '../../../components/KnowledgeCenter/ContentCard';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const ContentCardList = ({ heading, onClickMore, onClickCard, data }) => {
    const ref = useRef(null);
    const [showNext, setShowNext] = useState(false);
    const [showPrev, setShowPrev] = useState(false);

    const checkScroll = debounce(() => {
        if (ref?.current) {
            const { scrollLeft, scrollWidth, clientWidth } = ref.current;
            const currentWidth = scrollLeft + clientWidth;
            setShowNext(currentWidth + 1 < scrollWidth ? true : false);
            setShowPrev(scrollLeft === 0 ? false : true);
        }
    }, 500);

    const onClickNext = () => {
        if (ref?.current && showNext) {
            ref?.current.scrollBy({ left: 287, behavior: 'smooth' });
        }
    };

    const onClickPrevious = () => {
        if (ref?.current && showPrev) {
            ref?.current.scrollBy({ left: -287, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        checkScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    const hasScrollableContent = ref?.current?.scrollWidth > ref?.current?.clientWidth;

    return (
        <ContentCardListWrapper gap={pxToVh(18)}>
            <FlexBetween className="full-width">
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={28}
                    lineHeight={32}
                    fontWeight={500}
                    label={heading}
                />
                <Button
                    label={LANDING_PAGE_TEXT.EXPLORE_MORE_CTA}
                    endIcon={<img src={arrowRight} alt="arrow-right" />}
                    variant={'text'}
                    letterSpacing={`${pxToVw(0.6)}vw`}
                    onClick={() => onClickMore()}
                    fontColor={colorsCode.blue700}
                />
            </FlexBetween>
            <ScrollContainer gap={pxToVw(20)} ref={ref} onScroll={checkScroll}>
                {data.map(content => {
                    return (
                        <ContentCard
                            key={content.id}
                            mediaType={content.media_type}
                            mediaLabel={content.media_label}
                            onClick={onClickCard}
                            data={content}
                            text={content.text}
                            width={`calc(25% - ${pxToVw(15)}vw)`}
                        />
                    );
                })}
                {hasScrollableContent ? (
                    <Fragment>
                        <IconButton left onClick={onClickPrevious} isDisabled={!showPrev} disableRipple={!showPrev}>
                            <ChevronLeft htmlColor={colorsCode.PrimaryDeepBlue} />
                        </IconButton>
                        <IconButton right onClick={onClickNext} isDisabled={!showNext} disableRipple={!showNext}>
                            <ChevronRight htmlColor={colorsCode.PrimaryDeepBlue} />
                        </IconButton>
                    </Fragment>
                ) : null}
            </ScrollContainer>
        </ContentCardListWrapper>
    );
};

ContentCardList.defaultProps = {
    onClickMore: () => {},
    onClickCard: () => {},
    data: [],
};

ContentCardList.propTypes = {
    heading: PropTypes.string.isRequired,
    onClickMore: PropTypes.func,
    onClickCard: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ContentCardList;
