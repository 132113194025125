/* istanbul ignore file */
import React from 'react';

export const RejectSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9808 5.38056 18.6194 0.0192227 12 0ZM18 13H6V11H18V13Z"
                fill="#D51F31"
            />
        </svg>
    );
};

export const WarningSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="9" y="7" width="6" height="13" fill="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.8659 20.9821L12.8659 1.98214C12.673 1.69311 12.3484 1.51953 12.0009 1.51953C11.6534 1.51953 11.3289 1.69311 11.1359 1.98214L0.135928 20.9821C-0.0426135 21.2914 -0.0427113 21.6724 0.135671 21.9817C0.314054 22.291 0.643846 22.4818 1.00093 22.4821H23.0009C23.358 22.4818 23.6878 22.291 23.8662 21.9817C24.0446 21.6724 24.0445 21.2914 23.8659 20.9821ZM13.2069 8.48214L12.7339 15.0821H11.2679L10.8219 8.48214H13.2069ZM12.0009 19.4821C11.1907 19.4821 10.5339 18.8253 10.5339 18.0151C10.5339 17.2049 11.1907 16.5481 12.0009 16.5481C12.8111 16.5481 13.4679 17.2049 13.4679 18.0151C13.4679 18.8253 12.8111 19.4821 12.0009 19.4821Z"
                fill="#F3C233"
            />
        </svg>
    );
};

export const SuccessSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C23.9808 5.38056 18.6194 0.0192227 12 0ZM10 17.414L4.586 12L6 10.586L10 14.586L18 6.586L19.414 8L10 17.414Z"
                fill="#007F26"
            />
        </svg>
    );
};

export const ModalSuccess = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24 1.5C11.3 1.5 1 11.8 1 24.5C1 37.2 11.3 47.5 24 47.5C36.7 47.5 47 37.2 47 24.5C47 11.8 36.7 1.5 24 1.5ZM36.7 17.2L20.7 33.2C20.5 33.4 20.3 33.5 20 33.5C19.7 33.5 19.5 33.4 19.3 33.2L11.3 25.2C10.9 24.8 10.9 24.2 11.3 23.8C11.7 23.4 12.3 23.4 12.7 23.8L20 31.1L35.3 15.8C35.7 15.4 36.3 15.4 36.7 15.8C37.1 16.2 37.1 16.8 36.7 17.2Z"
                fill="#007F26"
            />
        </svg>
    );
};

export const CorssSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M8 7.29289L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L8.70711 8L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.6583 14.0488 13.3417 14.0488 13.1464 13.8536L8 8.70711L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95118 13.6583 1.95118 13.3417 2.14645 13.1464L7.29289 8L2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L8 7.29289Z"
                fill="#333333"
            />
        </svg>
    );
};
