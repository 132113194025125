import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Box, LinearProgress, Typography } from '@mui/material';
import { Logout } from '../../common/api/login';
import { parseJwt } from '../../utils/functions';
import axios from 'axios';
import { Endpoints } from '../../http/Endpoints';
import { CONFIG, PATHS } from '../../global-constants';
import AuthStore from '../../common/AuthStore';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionAction, Roles } from '../../store/actions/Login/actions';
import Loader from '../Loader';
import Unauthenticated from '../Unauthenticated';

const CallbackComponent = ({ mid }) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [status, setStatus] = React.useState(false);
    const { loading, data } = useSelector(state => state.permissionReducer);

    const checkvalidateuser = async () => {
        try {
            const midToken = mid.accessToken();
            if (midToken) {
                const {
                    data: { access_token = '', refresh_token = '', token_type = '' },
                    status = '',
                } = await axios.request({
                    method: 'GET',
                    url: `${CONFIG.BASE_PATH_APP}${Endpoints.loginUrl}`,
                    headers: {
                        Authorization: `Bearer ${midToken}`,
                    },
                });
                if (status === 200) {
                    setStatus(false);
                    const infoFromToken = parseJwt(midToken);
                    const { auth_time = 0 } = infoFromToken;
                    const token = `${token_type} ${access_token}`;
                    axios.defaults.headers.common[`Authorization`] = token;
                    localStorage.setItem('token_type', token_type);
                    dispatch(PermissionAction(token));
                    dispatch(Roles({ page: 1, limit: 50, token }));
                    AuthStore.accessToken = access_token;
                    AuthStore.refreshToken = refresh_token;
                    AuthStore.midToken = midToken;
                    AuthStore.auth_time = auth_time;
                }
            } else {
                Logout();
            }
        } catch (error) {
            console.error('Exception occurred...!');
            console.error(error);
            setStatus(true);
        }
        // eslint-disable-next-line
    };

    useEffect(() => {
        const midToken = mid.accessToken();
        if (midToken) {
            checkvalidateuser();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.keys(data).length) {
            const redirectUrl = AuthStore.redirectionURL;
            history(redirectUrl || PATHS.DASHBOARD);
            AuthStore.redirectionURL = '';
        }
    }, [data, history]);

    if (status) {
        return <Unauthenticated />;
    } else if (loading) {
        return <Loader showBackground position="fixed" background="transparent" />;
    }

    return (
        <Box
            component="div"
            sx={{
                width: '100vw',
                height: '100vh',
            }}
            data-testid="call"
        >
            <Box
                sx={{
                    position: 'absolute',
                    left: '45vw',
                    top: '45vh',
                }}
            >
                <Typography
                    fontSize={40}
                    color="black"
                    variant="h3"
                    fontFamily="Mckinsey Sans"
                    fontWeight={500}
                    data-testid="callback"
                >
                    Loading...
                </Typography>
                <LinearProgress sx={{ mt: 2 }} />
            </Box>
        </Box>
    );
};

export default CallbackComponent;
