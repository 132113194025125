import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Icon,
    TextField,
    Typography,
    Button,
    Box,
    Popover,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import CloseIcon from '../../assets/img/close.svg';
import Edit from '../../assets/img/blue-pen.svg';
import UploadImg from '../../assets/img/upload-cloud.svg';
import DeleteImg from '../../assets/img/delete.svg';
import ReplaceImg from '../../assets/img/crop.svg';
import CheckboxSvg from '../../assets/img/Checkbox.svg';
import RedWarningIcon from '../../assets/img/T-warning-red.svg';
import Rejected from '../../assets/img/C-remove.svg';
import Text from '../KnowledgeCenter/Text';
import { CTA, FlexAlignCenter, FlexColumnGap, FlexRow } from '../../common/styled/styled';
import { commonThemes } from '../../common/themes';
import styled from 'styled-components';
import { Img } from '../../common/FormInputs/Common';
import { Endpoints } from '../../http/Endpoints';
import { api } from '../../http/api';
import { Loader } from 'OHI-distribute-survey';

export function UploadIcon() {
    return <img src={UploadImg} alt="Upload Icon" />;
}
export function DeleteIcon() {
    return <img src={DeleteImg} alt="Upload Icon" />;
}
export function ReplaceIcon() {
    return <img src={ReplaceImg} alt="Upload Icon" />;
}

const Feedback = ({ onClose, showFeedbackModal, setFeedbackSubmitRes, setUploadMessage }) => {
    const styles = commonThemes();
    const [apiState, setApiState] = useState({ loading: false, errorState: '' });
    const [feedbackSubject, setFeedbackSubject] = useState([]);
    const [customSubject, setCustomSubject] = useState('');
    const [uploadImg, setUploadImg] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [feedback, setFeedback] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [imageHover, setImgHover] = useState(1);
    const style = {
        width: '100%',
        padding: '10px 0 20px',
        '& .MuiInputBase-root': {
            height: '180px',
            alignItems: 'flex-start',
        },
        '& .MuiInputBase-input': {
            height: '160px !important',
            overflowY: 'auto !important',
        },
    };
    const getFeedbackSubject = async () => {
        try {
            setApiState({ ...apiState, loading: true });
            const res = await api.get(`${Endpoints.feedbackSubject}`);
            if (res.status === 200) {
                const subArr = res.data.data?.map(sub => {
                    return { subject_name: sub, is_selected_key: false };
                });
                setFeedbackSubject(subArr);
            } else {
                setApiState({ loading: false, errorState: 'Something went wrong please reload' });
            }
            setApiState({ ...apiState, loading: false });
        } catch (error) {
            setApiState({ loading: false, errorState: error });
        }
    };
    useEffect(() => {
        getFeedbackSubject();
    }, []);
    const handleImageUpload = event => {
        setApiState({ loading: false, errorState: '' });
        const file = event.target.files[0];
        if (
            file &&
            (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') &&
            file.size <= 2 * 1024 * 1024
        ) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);
            setUploadImg(file);
            setUploadMessage('Image has been updated successfully');
        } else {
            setApiState({ ...apiState, errorState: 'Error uploading file. Please try again' });
        }
    };

    const handleFeedbackSubmit = async () => {
        const formData = new FormData();
        const selectedSub = feedbackSubject?.filter(sub => sub?.is_selected_key).map(item => item?.subject_name);

        formData.append(`subject`, selectedSub.join(', '));
        formData.append('custom_subject', customSubject);
        formData.append('description', feedback);
        if (uploadImg) {
            formData.append('file_obj', uploadImg);
        }

        try {
            setApiState({ ...apiState, loading: true });
            const res = await api.post(`${Endpoints.feedbackSubmit}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (res.status === 200) {
                setFeedbackSubmitRes(res.data.message);
                setApiState({ loading: false, errorState: '' });
            } else {
                setApiState({ loading: false, errorState: 'Something went wrong please reload' });
            }
            setApiState({ ...apiState, loading: false });
        } catch (error) {
            setApiState({ loading: false, errorState: error });
        }
        onClose();
    };
    const uploadImagRule = () => {
        return (
            <FlexRow gap={pxToVw(20)}>
                <Text
                    fontColor={colorsCode.neutral60}
                    fontSize="10px"
                    lineHeight={24}
                    label="Max file size: 2 MB"
                    fontWeight={400}
                />
                <Text
                    fontColor={colorsCode.neutral60}
                    fontSize="10px"
                    lineHeight={24}
                    label="Acceptable file formats: PNG, JPEG & JPG"
                    fontWeight={400}
                />
            </FlexRow>
        );
    };
    return (
        <>
            <Dialog
                open={showFeedbackModal}
                PaperProps={{
                    sx: {
                        minWidth: '933px',
                        width: '933px',
                        padding: '2.4vh 2.4vh 0',
                    },
                }}
            >
                {apiState.loading ? <Loader showBackground background="transparent" position="fixed" /> : null}
                <Icon
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: `${pxToVw(16)}vw`,
                        top: `${pxToVh(8)}vh`,
                        cursor: 'pointer',
                    }}
                >
                    <Img image={CloseIcon} />
                </Icon>
                <DialogTitle>
                    <FlexAlignCenter gap="0.5">
                        <Img image={Edit} />
                        <Text
                            fontColor={colorsCode.primaryCyan}
                            fontSize="28px"
                            lineHeight={24}
                            label={'Feedback form'}
                            fontWeight={500}
                        />
                    </FlexAlignCenter>
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: '100%',
                        padding: `0 57px`,
                        marginBottom: '20px',
                    }}
                >
                    <div style={{ paddingTop: '10px' }}>
                        <Typography component="span" fontWeight="400">
                            Feedback subject
                            <span className={styles.required}>*</span>
                        </Typography>
                    </div>
                    <FeedbackSubjectDiv>
                        {feedbackSubject?.map(sub => {
                            return (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={
                                                <img
                                                    src={CheckboxSvg}
                                                    className="img"
                                                    style={{
                                                        width: `${pxToVw(18)}vw`,
                                                        height: `${pxToVh(18)}vh`,
                                                    }}
                                                />
                                            }
                                            sx={{
                                                height: 0,
                                                width: 0,
                                                margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                            }}
                                            checked={sub.is_selected_key}
                                            onChange={e => {
                                                const tempFeedback = feedbackSubject?.map(item => {
                                                    if (sub.subject_name === 'Other' && !e.target.checked) {
                                                        setCustomSubject('');
                                                    }
                                                    if (item.subject_name === sub.subject_name) {
                                                        return { ...item, is_selected_key: e.target.checked };
                                                    }
                                                    return item;
                                                });
                                                setFeedbackSubject(tempFeedback);
                                            }}
                                            id="eng_exclude"
                                        />
                                    }
                                    label={sub.subject_name}
                                    key={sub.subject_name}
                                />
                            );
                        })}
                    </FeedbackSubjectDiv>
                    <div style={{ padding: '6px 0 28px 0' }}>
                        <Text
                            fontColor={colorsCode.neutral60}
                            fontSize={12}
                            lineHeight={24}
                            label="Select a feedback category"
                            fontWeight={400}
                        />
                    </div>

                    {feedbackSubject?.find(item => item.subject_name === 'Other')?.is_selected_key && (
                        <TextField
                            sx={{ width: '100%', paddingBottom: '20px' }}
                            onChange={e => setCustomSubject(e.target.value)}
                            size="small"
                            placeholder="Enter feedback subject"
                            value={customSubject}
                        />
                    )}
                    <FlexRow justifyContent="space-between" alignItems="flex-start" gap="2">
                        <FlexColumnGap gap="8px" width="50%">
                            <Typography component="span" fontWeight="400">
                                Feedback
                                <span className={styles.required}>*</span>
                            </Typography>
                            <TextField
                                onChange={e => setFeedback(e.target.value)}
                                size="small"
                                value={feedback}
                                placeholder="Enter your feedback"
                                className="feedback"
                                multiline
                                sx={style}
                            />
                        </FlexColumnGap>
                        <FlexColumnGap gap="8px" width="50%">
                            <Typography component="span" fontWeight="400">
                                Add image
                            </Typography>
                            <FeedbackImageColumn>
                                <ImageContainer>
                                    {!uploadImg ? (
                                        <UploadImgContainer>
                                            <Button
                                                variant="outlined"
                                                component="label"
                                                startIcon={<UploadIcon />}
                                                sx={{
                                                    borderColor: `${colorsCode.SecondaryDeepBlue}`,
                                                    textTransform: 'none',
                                                    color: 'inherit',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    padding: '3px 15px',
                                                    width: 'fit-content',
                                                    marginTop: '20%',
                                                }}
                                            >
                                                Upload Image
                                                <input
                                                    type="file"
                                                    hidden
                                                    accept="image/jpeg, image/png, image/jpg"
                                                    onChange={handleImageUpload}
                                                />
                                            </Button>
                                            {uploadImagRule()}
                                        </UploadImgContainer>
                                    ) : (
                                        <div style={{ position: 'relative', textAlign: 'center' }}>
                                            <ImagePreview style={{ opacity: imageHover }}>
                                                <img src={previewUrl} className="ImgPreview" alt="Uploaded Image" />
                                            </ImagePreview>
                                            <HoverButtons
                                                onMouseEnter={() => setImgHover(0.4)}
                                                onMouseLeave={() => setImgHover(1)}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    component="label"
                                                    startIcon={<DeleteIcon />}
                                                    className="ImgPreview"
                                                    sx={{
                                                        borderColor: `${colorsCode.SecondaryDeepBlue}`,
                                                        textTransform: 'none',
                                                        color: 'inherit',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        padding: '3px 15px',
                                                        width: 'fit-content',
                                                        background: `${colorsCode?.neutral02}`,
                                                        '&:hover': {
                                                            background: `${colorsCode?.Neutral05}`,
                                                        },
                                                    }}
                                                    onClick={e => {
                                                        setAnchorEl(e.currentTarget);
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                                <Popover
                                                    id={open ? 'simple-popover' : undefined}
                                                    open={Boolean(anchorEl)}
                                                    anchorEl={anchorEl}
                                                    onClose={() => {
                                                        setAnchorEl(null);
                                                    }}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                >
                                                    <DeletePopoverWrapper>
                                                        <FlexRow gap={pxToVw(12)} alignItems="flex-start">
                                                            <img
                                                                src={RedWarningIcon}
                                                                style={{
                                                                    paddingTop: '2px',
                                                                    height: '24px',
                                                                    width: '24px',
                                                                }}
                                                            />
                                                            <Text
                                                                fontSize={14}
                                                                lineHeight={20}
                                                                label="Are you sure you want to delete this image? You can’t undo this action."
                                                                fontWeight={400}
                                                            />
                                                        </FlexRow>
                                                        <div style={{ paddingLeft: '36px' }}>
                                                            <FlexRow gap="1.2">
                                                                <CTA
                                                                    size={14}
                                                                    lh={14}
                                                                    fw={400}
                                                                    bg={colorsCode.white}
                                                                    pt={8}
                                                                    pl={16}
                                                                    color={colorsCode.PrimaryDeepBlue}
                                                                    bcolor={colorsCode.PrimaryDeepBlue}
                                                                    onClick={() => setAnchorEl(null)}
                                                                >
                                                                    Cancel
                                                                </CTA>
                                                                <CTA
                                                                    size={14}
                                                                    lh={14}
                                                                    fw={400}
                                                                    bg={colorsCode.PrimaryDeepBlue}
                                                                    pt={8}
                                                                    pl={16}
                                                                    color={colorsCode.white}
                                                                    onClick={() => {
                                                                        setUploadImg('');
                                                                        setUploadMessage(
                                                                            'Image has been deleted successfully'
                                                                        );
                                                                        setApiState({
                                                                            ...apiState,
                                                                            errorState: '',
                                                                        });
                                                                        setAnchorEl(null);
                                                                    }}
                                                                >
                                                                    Delete
                                                                </CTA>
                                                            </FlexRow>
                                                        </div>
                                                    </DeletePopoverWrapper>
                                                </Popover>
                                                <Button
                                                    variant="outlined"
                                                    component="label"
                                                    startIcon={<ReplaceIcon />}
                                                    sx={{
                                                        borderColor: `${colorsCode.SecondaryDeepBlue}`,
                                                        textTransform: 'none',
                                                        color: 'inherit',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                        padding: '3px 15px',
                                                        width: 'fit-content',
                                                        background: '#f8f8f8',
                                                        borderShadow: 'none',
                                                        '&:hover': {
                                                            background: `${colorsCode?.Neutral05}`,
                                                        },
                                                    }}
                                                >
                                                    Replace image
                                                    <input
                                                        type="file"
                                                        hidden
                                                        accept="image/jpeg, image/png, image/jpg"
                                                        onChange={handleImageUpload}
                                                    />
                                                </Button>
                                            </HoverButtons>
                                        </div>
                                    )}
                                </ImageContainer>
                            </FeedbackImageColumn>
                            {apiState?.errorState && (
                                <FlexRow gap="0.1">
                                    <Img image={Rejected} h={1.5} w={1.5} />
                                    <Text
                                        fontColor={colorsCode.rejected}
                                        fontSize={12}
                                        lineHeight={24}
                                        label={apiState?.errorState}
                                        fontWeight={400}
                                    />
                                </FlexRow>
                            )}
                        </FlexColumnGap>
                    </FlexRow>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', paddingBottom: `5vh` }}>
                    <FlexRow gap={pxToVw(16)}>
                        <CTA
                            size={14}
                            lh={14}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={onClose}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={14}
                            lh={14}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            disabled={
                                feedback?.trim()?.length
                                    ? !feedbackSubject.map(sub => sub?.is_selected_key).some(item => item)
                                    : true
                            }
                            onClick={handleFeedbackSubmit}
                        >
                            Send feedback
                        </CTA>
                    </FlexRow>
                </DialogActions>
            </Dialog>
        </>
    );
};
const DeletePopoverWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 343px;
    padding: 2.4vh;
    gap: 16px;
    justify-content: center;
    align-items: start;
`;
export const FeedbackSubjectDiv = styled.div`
    padding: 8px 0 0 0;
    display: flex;
    justify-content: space-between;
`;
const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
`;
const ImagePreview = styled.div`
    img {
        width: 95%;
        height: 170px;
        object-fit: contain;
    }
`;

const UploadImgContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 12px 20px;
`;
const HoverButtons = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    opacity: 0,
    '&:hover': {
        opacity: 1,
    },
});
export const FeedbackImageColumn = styled.div`
    width: 100%;
    height: 180px;
    margin-top: 10px;
    background: ${colorsCode.neutral02};
    border-radius: 4px;
    border: 1px dashed ${colorsCode.SecondaryDeepBlue};
`;

export default Feedback;
