import { TextField } from '@mui/material';
import React from 'react';
import { colorsCode, pxToVh, pxToVw } from '../colors';
import { FlexAlignCenter } from '../styled/styled';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { CommonLabel } from './Common';

const InputField = ({
    placeholder,
    id,
    name,
    onChangeFn,
    val,
    error = false,
    errorMessage = '',
    disabled = false,
    width,
    height,
    fullWidth,
    color,
    ai = 'center',
    onBlur = () => {},
    onFocus = () => {},
    className,
    type,
    inputProps,
    multiline = false,
    dir = 'ltr',
}) => {
    const style = {
        '& .MuiInputBase-root': {
            height: height ? `${height}vh` : `5.67vh`,
            borderColor: colorsCode.Neutral30,
            width: width ? `${width}vw` : fullWidth ? 'auto' : '27vw',
            borderRadius: '2px',
            fontSize: `${pxToVh(16)}vh`,
            lineHeight: `${pxToVh(24)}vh`,
            color: color ? colorsCode.Neutral80 : color,
            alignItems: ai,
        },
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: colorsCode.PrimaryDeepBlue,
            borderColor: colorsCode.TextSubtle,
            opacity: 0.5,
        },
        '& .MuiFormHelperText-root': {
            marginLeft: 0,
        },
        input: {
            padding: `16.5px ${pxToVw(16)}vw`,
        },
    };

    const getErrormessage = msg => {
        if (msg) {
            return (
                <FlexAlignCenter gap={pxToVw(6)}>
                    <WarningAmberOutlinedIcon
                        sx={{ color: colorsCode.error, height: `${pxToVh(16)}vh`, width: `${pxToVw(16)}vw` }}
                    />
                    <div>
                        {msg === 'invalid' || msg === 'invalid custom' ? (
                            <CommonLabel
                                textSize={12}
                                textColor={colorsCode.rejected}
                                textWeight={400}
                                lineHeight={16}
                                label={`The ${
                                    msg === 'invalid custom' ? 'replaced term' : 'organization name'
                                } can consist of the following characters:`}
                            />
                        ) : null}

                        {msg === 'invalid' || msg === 'invalid custom' ? (
                            <ul
                                style={{
                                    marginTop: 0,
                                    paddingLeft: `${pxToVw(12)}vw`,
                                    fontSize: `${pxToVh(12)}vh`,
                                    fontWeight: 400,
                                    lineHeight: `${pxToVh(16)}vh`,
                                }}
                            >
                                <li>letters a to z (both lowercase and uppercase),</li>
                                <li>numbers 0 to 9</li>
                                <li>{`the special characters ${
                                    msg === 'invalid custom'
                                        ? '\' " ? . _ () . , / # $ % & ! ` - '
                                        : "# $ % & . - space '."
                                }`}</li>
                            </ul>
                        ) : (
                            msg
                        )}
                    </div>
                </FlexAlignCenter>
            );
        }
    };

    return (
        <div dir={dir} style={{ width: '100%' }}>
            <TextField
                id={id}
                name={name}
                placeholder={placeholder}
                value={val || ''}
                disabled={disabled}
                onChange={onChangeFn}
                error={Boolean(error)}
                helperText={getErrormessage(errorMessage)}
                autoComplete="off"
                sx={style}
                fullWidth={fullWidth}
                onBlur={onBlur}
                onFocus={onFocus}
                className={className}
                multiline={multiline}
                type={type}
                {...inputProps}
            />
        </div>
    );
};

export default InputField;
