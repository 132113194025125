import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Text from '../Text';
import { Container, LinearProgress, Tab, TabContainer, SecondaryContainer, MainBar, LogoutContainer } from './styles';
import { AppContext } from '../../../containers/KnowledgeCenter/Home';
import {
    NAVBAR_ROUTE_TO_KEYS,
    NAVBAR_KEYS_TO_ROUTES,
    SECTION_KEYS,
    SECTION_KEYS_TO_ROUTES,
    KC_VERSION_KEYS,
} from '../../../utils/kc-constants';
import { PATHS } from '../../../global-constants';
import LogoutImg from '../../../assets/img/Logout.svg';
import themeStyles from '../../../themes/styles';
import { NAVBAR_DATA, NAVBAR_DATA_4 } from '../../../utils/kc-data';
import AuthStore from '../../../common/AuthStore';
import { Logout } from '../../../common/api/login';
import { colorsCode, pxToVw } from '../../../common/colors';
import { FlexCenter, FlexRow } from '../../../common/styled/styled';

const Navbar = ({ open, showLinearProgressBar }) => {
    const location = useLocation();
    const history = useNavigate();
    const styles = themeStyles();
    const { activeSection, setActiveSection } = useContext(AppContext);
    const [scrollPercentage, setScrollPercentage] = useState(0);
    const [activeTab, setActiveTab] = useState(null);
    const [profileTab, setProfileTab] = useState(false);
    const { pathname, search } = location;
    const params = new URLSearchParams(search);
    const isDeepLink = params.get('deep_link') === '1';

    const [navData, setNavdata] = useState(NAVBAR_DATA);
    const [isVersionFour, setVersion] = useState(false);

    useEffect(() => {
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
        setNavdata(isVersionFour ? NAVBAR_DATA_4 : NAVBAR_DATA);
    }, [isVersionFour, setVersion]);

    useEffect(() => {
        if (NAVBAR_ROUTE_TO_KEYS[pathname]) {
            setActiveTab(NAVBAR_ROUTE_TO_KEYS[pathname]);
        } else {
            params.delete('section');
            history(
                {
                    search: params.toString(),
                },
                { replace: true }
            );
            setActiveTab(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        const sectionParam = params.get('section');
        if (sectionParam) {
            handleSectionChange(SECTION_KEYS[sectionParam]);
        } else {
            setActiveSection(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (showLinearProgressBar) {
            const progressBarHandler = () => {
                const totalScroll = document.documentElement.scrollTop;
                const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
                const scroll = windowHeight !== 0 ? (totalScroll * 100) / windowHeight : 0;

                setScrollPercentage(scroll);
            };

            window.addEventListener('scroll', progressBarHandler);

            return () => window.removeEventListener('scroll', progressBarHandler);
        }
    }, [activeTab, showLinearProgressBar]);

    useEffect(() => {
        if (!params.get('section')) {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        if (activeSection && pathname !== PATHS.KNOWLEDGE_CENTER) {
            params.set('section', SECTION_KEYS_TO_ROUTES[activeSection]);
            history(
                {
                    search: params.toString(),
                },
                { replace: true }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection, search]);

    const handleTabChange = value => {
        setActiveSection(null);
        setActiveTab(value);
        params.delete('section');
        history({
            pathname: NAVBAR_KEYS_TO_ROUTES[value],
            search: params.toString(),
        });
    };

    const handleSectionChange = value => {
        const element = document.getElementById(value);
        if (element) {
            element.scrollIntoView();
        }
        params.set('section', SECTION_KEYS_TO_ROUTES[value]);
        history(
            {
                search: params.toString(),
            },
            { replace: true }
        );

        setActiveSection(value);
    };

    const handleTab = () => {
        setProfileTab(old => !old);
    };

    return open ? (
        <Container>
            {showLinearProgressBar ? (
                <LinearProgress variant="determinate" color="success" value={scrollPercentage} />
            ) : (
                <MainBar />
            )}
            <TabContainer>
                <FlexRow gap="0">
                    {navData.map(nav => {
                        return (
                            <Tab
                                key={nav.unique_key}
                                onClick={() => handleTabChange(nav.unique_key)}
                                isActive={activeTab === nav.unique_key}
                            >
                                <Text
                                    fontColor={activeTab === nav.unique_key ? colorsCode.white : colorsCode.TextSubtle}
                                    fontSize={14}
                                    lineHeight={20}
                                    fontWeight={activeTab === nav.unique_key ? 500 : 400}
                                    label={nav.name}
                                />
                            </Tab>
                        );
                    })}
                </FlexRow>
                {isDeepLink ? (
                    <LogoutContainer>
                        {profileTab && (
                            <Box className={`${styles.logoutParent} logout-parent-kc`}>
                                <FlexCenter
                                    style={{ gap: `${pxToVw(5)}vw`, alignItems: 'center', paddingRight: '2vw' }}
                                >
                                    <img src={LogoutImg} alt="" className={`${styles.logoutImg}`} />
                                    <Typography
                                        className={`${styles.logout}`}
                                        component="span"
                                        onClick={() => {
                                            Logout(`${PATHS.KNOWLEDGE_CENTER}?deep_link=1`);
                                        }}
                                        whiteSpace="nowrap"
                                    >
                                        Log out
                                    </Typography>
                                </FlexCenter>
                            </Box>
                        )}
                        <Box component="div" className={`${styles.profileClass} profile-class-kc`} onClick={handleTab}>
                            <Typography className={`${styles.initials}`} sx={{ color: colorsCode.white }}>
                                {AuthStore.initials}
                            </Typography>
                        </Box>
                    </LogoutContainer>
                ) : null}
            </TabContainer>
            {activeTab ? (
                <SecondaryContainer isHome={activeTab === NAVBAR_ROUTE_TO_KEYS[PATHS.KNOWLEDGE_CENTER_HOME]}>
                    {activeTab === NAVBAR_ROUTE_TO_KEYS[PATHS.KC_OVERVIEW] && isDeepLink ? (
                        <Text
                            fontColor={colorsCode.backgroundDisabled}
                            fontSize={14}
                            lineHeight={20}
                            fontWeight={300}
                            label="Home"
                            onClick={() => {
                                params.delete('section');
                                params.delete('version');
                                history(
                                    {
                                        search: params.toString(),
                                    },
                                    { replace: true }
                                );
                                history({
                                    pathname: PATHS.KNOWLEDGE_CENTER,
                                    search: params.toString(),
                                });
                            }}
                            textDecoration="none"
                        />
                    ) : null}
                    {navData
                        .find(({ unique_key }) => unique_key === activeTab)
                        ?.subpages?.map(option => {
                            return (
                                <Text
                                    key={option.unique_key}
                                    fontColor={
                                        activeSection === option.unique_key
                                            ? colorsCode.white
                                            : colorsCode.backgroundDisabled
                                    }
                                    fontSize={14}
                                    lineHeight={20}
                                    fontWeight={activeSection === option.unique_key ? 400 : 300}
                                    label={option.name}
                                    onClick={() => handleSectionChange(option.unique_key)}
                                    textDecoration="none"
                                />
                            );
                        })}
                </SecondaryContainer>
            ) : null}
        </Container>
    ) : null;
};

Navbar.defaultProps = {
    open: true,
    showLinearProgressBar: true,
    onClose: () => {},
};

Navbar.propTypes = {
    open: PropTypes.bool,
    showLinearProgressBar: PropTypes.bool,
    onClose: PropTypes.func,
};

export default Navbar;
