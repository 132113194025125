import React, { useState, useMemo } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { BorderLightButton, FlexBetween, FlexRow, StyledFilterMenu } from '../styled/styled';
import { ReactComponent as ArrowDown } from '../../assets/img/arrow_down.svg';
import { LABELS, SURVEY_CREATED_LIST } from '../../global-constants';
import { colorsCode, pxToVh, pxToVw } from '../colors';
import { useSelector } from 'react-redux';
import { commonThemes } from '../themes';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CountLabel from './CountLabel';
import PropTypes from 'prop-types';
import { CommonLabel } from './Common';

const FilterModal = ({
    open,
    cancelBtnName,
    saveBtnName,
    mainTitle,
    clearTitle,
    close,
    onApply,
    filter,
    setFilter,
    anchorEl = null,
    clientFilter,
}) => {
    const { surveyTemplates = [] } = useSelector(state => state.clientReducer);
    const [selectedSurveyCounts, setSelectedSurveyCounts] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const handleAccordion = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const onClickSurveyCreated = selectedId => {
        const idExists = selectedSurveyCounts.some(item => item === selectedId);
        if (idExists) {
            const filteredData = selectedSurveyCounts.filter(item => item !== selectedId);
            setSelectedSurveyCounts(filteredData);
        } else {
            setSelectedSurveyCounts([...selectedSurveyCounts, selectedId]);
        }
    };

    const onChangeSurveyType = selectedId => {
        setFilter({ ...filter, templateType: selectedId });
    };

    const clearFilters = () => {
        close();
        setFilter({ templateType: '', surveyCount: '' });
        setSelectedSurveyCounts([]);
        onApply({
            ...clientFilter,
            surveyCount: null,
        });
    };

    const styles = commonThemes();

    const labelCount = () => {
        if (selectedSurveyCounts.some(item => item === null)) {
            return selectedSurveyCounts.length - 1;
        }
        return selectedSurveyCounts.length;
    };

    const showChecked = id => {
        let markChecked = false;
        const checkIfOnlySelectAll = selectedSurveyCounts.length === 1 && selectedSurveyCounts[0] === null;
        if (!checkIfOnlySelectAll && selectedSurveyCounts.includes(id)) {
            markChecked = true;
        }
        return markChecked;
    };

    const checkFilterApplied = () => {
        return filter?.templateType !== '' || selectedSurveyCounts?.length > 0 ? true : false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isFilterApplied = useMemo(() => checkFilterApplied(), [filter, selectedSurveyCounts]);

    return (
        <StyledFilterMenu
            open={open}
            className={styles.ContentWrapper}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            height={`${pxToVh(350)}vh`}
            style={{
                marginLeft: `9vw`,
                marginTop: `-1vh`,
            }}
        >
            <FlexBetween>
                <DialogTitle className={styles.filterModalTitle}>{mainTitle}</DialogTitle>
                <DialogTitle
                    onClick={clearFilters}
                    className={`${styles.filterModalClearTitle} ${!isFilterApplied ? styles.clearTitleDisabled : ''}`}
                >
                    {clearTitle}
                </DialogTitle>
            </FlexBetween>
            <DialogContent className={styles.filterOptionsContainer}>
                <DialogContentText className={styles.accordianTitle}>
                    <Accordion
                        square={true}
                        expanded={expanded == 'surveyTemplates'}
                        elevation={0}
                        disableGutters={true}
                        onChange={handleAccordion('surveyTemplates')}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDown />}
                            aria-controls="panel1a-content"
                            id="surveyTemplates"
                        >
                            <CommonLabel
                                textWeight={500}
                                textSize={14}
                                textColor={colorsCode.Neutral80}
                                lineHeight={20}
                                label={LABELS.FILTER_MODAL.SURVEY_TYPE}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <FlexRow>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue="OHI-Pulse Survey"
                                    name="radio-buttons-group"
                                >
                                    {surveyTemplates.records &&
                                        surveyTemplates.records.map((item, i) => {
                                            return (
                                                <FormControlLabel
                                                    key={i}
                                                    value={item.id}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                color: '#b3b3b3',
                                                                '&.Mui-checked': { color: '#000' },
                                                            }}
                                                            checked={filter.templateType === item.uuid}
                                                        />
                                                    }
                                                    label={
                                                        <CommonLabel
                                                            textWeight={400}
                                                            textSize={14}
                                                            textColor={colorsCode.Neutral80}
                                                            lineHeight={20}
                                                            label={item.template_name}
                                                        />
                                                    }
                                                    onClick={() => onChangeSurveyType(item.uuid)}
                                                />
                                            );
                                        })}
                                </RadioGroup>
                            </FlexRow>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        expanded={expanded == 'created'}
                        elevation={0}
                        disableGutters={true}
                        onChange={handleAccordion('created')}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="created">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.SURVEY_CREATED}
                                />
                                <CountLabel count={labelCount()} />
                            </FlexBetweenWrap>
                        </AccordionSummary>

                        {SURVEY_CREATED_LIST.map((item, i) => {
                            return (
                                <AccordionDetails key={i}>
                                    <FlexRow onClick={() => onClickSurveyCreated(item.id)}>
                                        <Checkbox
                                            checked={showChecked(item.id)}
                                            sx={{
                                                height: '4.38vh',
                                                width: '4.38vh',
                                                color: colorsCode.Neutral30,
                                            }}
                                            checkedIcon={<CheckBoxIcon sx={{ color: colorsCode.TextHeading }} />}
                                        />
                                        <CommonLabel
                                            textWeight={400}
                                            textSize={14}
                                            textColor={colorsCode.Neutral80}
                                            lineHeight={20}
                                            label={item.name}
                                        />
                                    </FlexRow>
                                </AccordionDetails>
                            );
                        })}
                    </Accordion>
                </DialogContentText>
            </DialogContent>
            {/* <FlexEndWrap> */}
            <DialogActions
                sx={{
                    justifyContent: 'flex-end',
                    gap: `${pxToVw(20)}vw`,
                    p: `${pxToVh(20)}vh ${pxToVw(17)}vw ${pxToVh(20)}vh 0vw`,
                    borderTop: `1px solid #ccc`,
                }}
            >
                <BorderLightButton onClick={close} color={colorsCode.PrimaryDeepBlue} light>
                    {cancelBtnName}
                </BorderLightButton>
                <BorderLightButton
                    isDisabled={!isFilterApplied}
                    onClick={() => {
                        const allChecked =
                            selectedSurveyCounts.some(item => item === 1) &&
                            selectedSurveyCounts.some(item => item === 2);
                        onApply({
                            ...filter,
                            surveyCount: allChecked ? null : selectedSurveyCounts[0],
                        });
                    }}
                >
                    {saveBtnName}
                </BorderLightButton>
            </DialogActions>
            {/* </FlexEndWrap> */}
            <br />
        </StyledFilterMenu>
    );
};

const FlexBetweenWrap = styled(FlexBetween)`
    width: 100%;
    margin-right: 0.7vh;
`;

FilterModal.propTypes = {
    cancelBtnName: PropTypes.string.isRequired,
    saveBtnName: PropTypes.string.isRequired,
    mainTitle: PropTypes.string.isRequired,
    clearTitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
    setFilter: PropTypes.func.isRequired,
};
export default FilterModal;
