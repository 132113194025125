import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colorsCode } from '../../common/colors';

export default function Loader({ background, position, showBackground, height = '' }) {
    const style = {
        position,
        height: height || '100%',
        width: '100%',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: background,
        overflow: 'auto',
        zIndex: 99,
    };
    const whiteBg = {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: '#ffffff',
        opacity: '0.7',
    };

    return (
        <div className="loader" style={style}>
            {showBackground && <div style={whiteBg} />}
            <LoaderWrapper title="loading">
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
            </LoaderWrapper>
        </div>
    );
}

Loader.defaultProps = {
    background: 'white',
    position: 'fixed',
    showBackground: false,
};

Loader.propTypes = {
    background: PropTypes.string,
    position: PropTypes.string,
    showBackground: PropTypes.bool,
};

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    font-size: 1rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: Translate(-50%, -50%);
`;

const Dot = styled.div`
    position: relative;
    width: 0.9rem;
    height: 0.9rem;
    margin: 0.8em;
    border-radius: 50%;

    @keyframes anime {
        50%,
        75% {
            transform: scale(2.5);
        }
        80%,
        100% {
            opacity: 0;
        }
    }

    &:nth-child(1) {
        background-color: ${colorsCode.blueShade1};
    }
    &:nth-child(2) {
        background-color: ${colorsCode.blueShade2};
    }
    &:nth-child(3) {
        background-color: ${colorsCode.blueShade3};
    }
    &:nth-child(4) {
        background-color: ${colorsCode.blueShade4};
    }
    &:nth-child(5) {
        background-color: ${colorsCode.blueShade5};
    }

    &:before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: inherit;
        border-radius: inherit;
        animation: anime 2s ease-out infinite;
    }

    &:nth-child(1):before {
        animation-delay: 0.2s;
    }

    &:nth-child(2):before {
        animation-delay: 0.4s;
    }

    &:nth-child(3):before {
        animation-delay: 0.6s;
    }

    &:nth-child(4):before {
        animation-delay: 0.8s;
    }

    &:nth-child(5):before {
        animation-delay: 1s;
    }
`;
