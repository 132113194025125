import React from 'react';
import styled from 'styled-components';
import Text from '../Text';
import { FlexRow } from '../../../common/styled/styled';
import { Container, TextContainer } from './styles';
import { openSlackChannel } from '../../../utils/functions';
import { FOOTER_OPTIONS } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const Footer = () => {
    const handleFooterItemClick = index => {
        if (index === 0) {
            openSlackChannel();
        } else if (index === 1) {
            window.open('mailto:ohi_escalation@mckinsey.com?subject=Feedback', '_blank');
        }
    };

    return (
        <Container gap={pxToVw(97)}>
            {FOOTER_OPTIONS.map(({ label, sub_label, icon }, index) => {
                return (
                    <ItemContainer gap={pxToVw(17)} key={label} alignItems="flex-start">
                        <img src={icon} alt="chat-icon" onClick={() => handleFooterItemClick(index)} />
                        <TextContainer gap={pxToVh(16)}>
                            <Text
                                fontColor={colorsCode.PrimaryDeepBlue}
                                fontSize={20}
                                lineHeight={32}
                                label={label}
                                onClick={() => handleFooterItemClick(index)}
                            />
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={16}
                                lineHeight={24}
                                label={sub_label}
                                fontWeight={300}
                                onClick={() => handleFooterItemClick(index)}
                            />
                        </TextContainer>
                    </ItemContainer>
                );
            })}
        </Container>
    );
};

export const ItemContainer = styled(FlexRow)`
    img {
        cursor: pointer;
    }
`;

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
