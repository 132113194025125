import { createSlice } from '@reduxjs/toolkit';
import { AddUser, DeleteUser, UpdateUser, UserDetails, UsersListing } from '../../actions/Users/actions';

export const INTITIAL_STATE = {
    loading: false,
    isDataFetched: false,
    isDeleted: false,
    isUpdated: false,
    data: [],
    userDetails: {},
    errorMessage: '',
    isSaved: false,
    error: false,
    isUpdatedSelf: false,
};
const Users = createSlice({
    name: 'UsersReducer',
    initialState: INTITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(UsersListing.pending, state => {
                state.loading = true;
            })
            .addCase(UsersListing.fulfilled, (state, { payload }) => {
                const {
                    data: { records = [], total = 0, total_user_count = 0 },
                } = payload;
                state.loading = false;
                state.data = {
                    records: records?.length ? records.map(item => ({ ...item, is_selected: false })) : [],
                    total,
                    total_user_count,
                };
                state.isDataFetched = true;
            })
            .addCase(UsersListing.rejected, (state, { payload }) => {
                state.loading = false;
                state.data = [];
                state.isDataFetched = true;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(UserDetails.pending, state => {
                state.loading = true;
            })
            .addCase(UserDetails.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.isDataFetched = true;
                state.userDetails = payload;
            })
            .addCase(UserDetails.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.isDataFetched = true;
                state.errorMessage = payload;
            })
            .addCase(DeleteUser.pending, state => {
                state.loading = true;
            })
            .addCase(DeleteUser.fulfilled, state => {
                state.loading = false;
                state.isDeleted = true;
            })
            .addCase(DeleteUser.rejected, (state, { payload }) => {
                state.loading = false;
                state.isDeleted = true;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(AddUser.pending, state => {
                state.loading = true;
            })
            .addCase(AddUser.fulfilled, state => {
                state.loading = false;
                state.isSaved = true;
            })
            .addCase(AddUser.rejected, (state, { payload }) => {
                state.loading = false;
                state.isSaved = true;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(UpdateUser.pending, state => {
                state.loading = true;
            })
            .addCase(UpdateUser.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(UpdateUser.rejected, (state, { payload }) => {
                state.loading = false;
                state.isUpdated = true;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        resetData: state => {
            state.isDataFetched = false;
            state.isDeleted = false;
            state.isSaved = false;
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = '';
            state.userDetails = {};
            state.isUpdatedSelf = false;
        },
        checkUpdatedSelf: state => {
            state.isUpdatedSelf = true;
        },
    },
});
export const { resetData, checkUpdatedSelf } = Users.actions;
export default Users.reducer;
