import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../http/api';
import { Endpoints } from '../../../http/Endpoints';

export const GetFilterOptions = createAsyncThunk(
    Endpoints.monitorFilterOptions,
    async (params, { rejectWithValue }) => {
        try {
            const { surveyId } = params;
            const response = await api.get(Endpoints.monitorFilterOptions.replace(':survey_id', surveyId));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetDemoResponses = createAsyncThunk(
    Endpoints.monitorDemoResponses,
    async (params, { rejectWithValue }) => {
        try {
            const { surveyId } = params;
            const response = await api.post(Endpoints.monitorDemoResponses.replace(':survey_id', surveyId));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetFilteredDemoResponses = createAsyncThunk(
    `${Endpoints.monitorDemoResponses}/filters`,
    async (params, { rejectWithValue }) => {
        try {
            const { surveyId, filters } = params;
            const response = await api.post(Endpoints.monitorDemoResponses.replace(':survey_id', surveyId), filters);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetResponseRate = createAsyncThunk(Endpoints.monitorResponseRate, async (params, { rejectWithValue }) => {
    try {
        const { surveyId } = params;
        const response = await api.get(Endpoints.monitorResponseRate.replace(':survey_id', surveyId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message);
    }
});

export const UpdateResponseRate = createAsyncThunk(
    Endpoints.monitorUpdateRespondents,
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { surveyId, total_respondents } = params;
            const response = await api.patch(`${Endpoints.monitorUpdateRespondents.replace(':survey_id', surveyId)}`, {
                total_respondents,
            });
            dispatch(GetResponseRate({ surveyId }));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const UpdateResponseThreshold = createAsyncThunk(
    `${Endpoints.monitorResponseThreshold}/update`,
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { surveyId, addData, updateData } = params;
            let response = {};
            if (addData?.length) {
                response = await api.post(Endpoints.monitorResponseThreshold.replace(':survey_id', surveyId), addData);
            }
            if (updateData?.length) {
                response = await api.put(
                    Endpoints.monitorResponseThreshold.replace(':survey_id', surveyId),
                    updateData
                );
            }
            dispatch(GetResponseThreshold({ surveyId }));
            await dispatch(GetDemoResponses({ surveyId }));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetResponseThreshold = createAsyncThunk(
    `${Endpoints.monitorResponseThreshold}/get`,
    async (params, { rejectWithValue }) => {
        try {
            const { surveyId } = params;
            const response = await api.get(Endpoints.monitorResponseThreshold.replace(':survey_id', surveyId));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);
