import { MenuItem, Select } from '@mui/material';
import React from 'react';
import ArrowDownIcon from '../../assets/img/arrow_down.svg';
import { pxToVh, pxToVw } from '../colors';
import { commonThemes } from '../themes';

const SelectMenu = ({ menuItems, value, onChangeFn, disabled, controlWidth }) => {
    const style = commonThemes();
    return (
        <Select
            labelId="select-menu"
            id="select-menu"
            value={value}
            displayEmpty={true}
            renderValue={value => (value ? value : 'placeholder')}
            onChange={onChangeFn}
            disabled={disabled}
            sx={{
                width: `${pxToVw(88)}vw`,
                height: `${pxToVh(26)}vh`,
                fontSize: `${pxToVh(14)}vh`,
                fontWeight: 400,
                lineHeight: `${pxToVh(16)}vh`,
                paddingTop: `${pxToVh(7.2)}vh`,
            }}
            MenuProps={{
                PaperProps: {
                    sx: {
                        '& .MuiMenuItem-root': {
                            fontSize: '0.9rem',
                        },
                    },
                },
                style: { zIndex: 11111111 },
            }}
            inputProps={{
                'data-testid': 'selectval',
            }}
            IconComponent={props => <img src={ArrowDownIcon} {...props} alt="dropdown" />}
            classes={{
                select: `${controlWidth ? style.pageSelectClass : style.selectClass}`,
            }}
        >
            {menuItems &&
                menuItems.map(item => (
                    <MenuItem key={item} value={item}>
                        {item}
                    </MenuItem>
                ))}
        </Select>
    );
};

export default SelectMenu;
