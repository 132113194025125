import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../http/api';
import { Endpoints } from '../../../http/Endpoints';
import AuthStore from '../../../common/AuthStore';
import { GetClientById } from '../Client/actions';
import { MESSAGE } from '../../../global-constants';

export const UsersListing = createAsyncThunk('/api/user-listing', async (paramsObj, { rejectWithValue }) => {
    try {
        const {
            search = '',
            limit = 10,
            page = 1,
            dateRange: { startDate = '', endDate = '' },
            filters,
        } = paramsObj;
        let url = Endpoints.usersAll.replace('__limit__', limit).replace('__page__', page);
        if (search) {
            url = `${url}&q=${encodeURIComponent(search)}`;
        }
        if (startDate && endDate) {
            url = `${url}&start_date=${startDate}&end_date=${endDate}`;
        }

        const response = await api.post(url, filters);
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const UserDetails = createAsyncThunk('get/api/users/user_id', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { user_id } = params;
        const response = await api.get(Endpoints.usersDetails.replace(':user_id', user_id));
        const { data } = response;
        if (AuthStore.isClientOrCst && data.data.client_id.length) {
            dispatch(GetClientById({ clientId: data.data.client_id[0] }));
        }
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const DeleteUser = createAsyncThunk(
    'delete/api/users/user_id',
    async (params, { rejectWithValue, dispatch }) => {
        const { openPopUp } = await import('../../reducers/slice/SnackbarSlice');
        try {
            const { user_id, type, userFilters } = params;
            const requestParams = type === 'single' ? { user_id } : { user_ids: user_id };
            let response = {};
            if (type === 'single') {
                response = await api.delete(Endpoints.usersDetails.replace(':user_id', user_id), {
                    data: requestParams,
                });
            } else {
                response = await api.post(Endpoints.bulkDeleteUser, requestParams);
            }
            const { data } = response;
            const userParams = {
                ...userFilters,
            };
            await dispatch(UsersListing(userParams));
            await dispatch(openPopUp(true));
            return data;
        } catch (error) {
            await dispatch(openPopUp(false));
            return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const AddUser = createAsyncThunk('adduser', async (params, { rejectWithValue, dispatch }) => {
    const { openPopUp } = await import('../../reducers/slice/SnackbarSlice');
    try {
        const { name, email, role_id, client_id, template_id, valid_till } = params;
        let response = {};
        response = await api.post(Endpoints.addUser, {
            name,
            email,
            role_id: role_id,
            client_id: client_id.length ? [client_id] : [],
            template_id: template_id.length ? template_id : [],
            valid_till,
        });
        const { data } = response;
        const userParams = {
            limit: 10,
            page: 1,
            search: '',
            dateRange: { startDate: '', endDate: '' },
            filters: {
                filters: {},
                sort: 0,
            },
        };
        await dispatch(UsersListing(userParams));
        await dispatch(openPopUp(true));
        return data;
    } catch (error) {
        await dispatch(openPopUp(false));
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const UpdateUser = createAsyncThunk('updateUser', async (params, { rejectWithValue, dispatch }) => {
    const { openPopUp } = await import('../../reducers/slice/SnackbarSlice');
    try {
        const { user_id: loggedInUserUuid = '' } = AuthStore.parsedData || {};
        const { user_id, name, email, role_id, client_id, template_id, valid_till, searchTxt, filterTxt } = params;
        const response = await api.put(Endpoints.usersDetails.replace(':user_id', user_id), {
            name,
            email,
            role_id: role_id,
            client_id: [client_id],
            template_id: template_id,
            valid_till,
        });
        const { data } = response;
        if (loggedInUserUuid === user_id) {
            // circular dependency issue
            const { checkUpdatedSelf } = await import('../../reducers/slice/Users');
            dispatch(checkUpdatedSelf());
        }
        const userParams = {
            limit: 10,
            page: 1,
            search: searchTxt,
            dateRange: { startDate: '', endDate: '' },
            filters: {
                filters: { ...filterTxt },
                sort: 0,
            },
        };
        await dispatch(UsersListing(userParams));
        await dispatch(openPopUp(true));
        return data;
    } catch (error) {
        await dispatch(openPopUp(false));
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const searchClient = createAsyncThunk('searchClient', async (params, { rejectWithValue }) => {
    try {
        let url = Endpoints.allClientsListUrl;
        const { search, templateid } = params;
        url = `${url}?q=${encodeURIComponent(search)}&limit=50&page=1`;
        const response = await api.post(url, {
            filters: {
                template_type: templateid ? [templateid] : [],
            },
            sort: 0,
        });
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const searchUser = createAsyncThunk('searchUser', async (params, { rejectWithValue }) => {
    try {
        let url = Endpoints.usersAll.replace('__limit__', 50).replace('__page__', 1);
        const { search, searchUser } = params;
        url = `${url}&q=${encodeURIComponent(search)}`;
        if (searchUser) {
            if (searchUser.client_id) {
                url = `${url}&client_id=${searchUser.client_id}`;
            }
            if (searchUser.is_admin_only) {
                url = `${url}&is_only_admin=${searchUser.is_admin_only}`;
            }
        }
        const response = await api.post(url, { filters: {}, sort: 0 });
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const checkUserExists = createAsyncThunk('checkuser', async (params, { rejectWithValue }) => {
    try {
        const { search } = params;
        let url = `${Endpoints.userExists}/${encodeURI(search)}`;
        const response = await api.get(url);
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});
