import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Chip as MuiChip } from '@mui/material';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';

const Chip = ({
    label,
    backgroundColor,
    padding,
    fontColor,
    lineHeight,
    fontSize,
    className,
    icon,
    borderRadius,
    multiline,
    alignItems,
    fontWeight,
    onClick,
}) => {
    return (
        <MuiChipStyled
            $backgroundColor={backgroundColor}
            padding={padding}
            label={label}
            $fontColor={fontColor}
            $lineHeight={lineHeight}
            $fontWeight={fontWeight}
            $borderRadius={borderRadius}
            fontSize={fontSize}
            className={className}
            icon={icon}
            multiline={multiline}
            alignItems={alignItems}
            onClick={onClick}
        />
    );
};

const MuiChipStyled = styled(MuiChip)`
    && {
        color: ${({ $fontColor }) => $fontColor || colorsCode.PrimaryDeepBlue};
        font-size: ${({ fontSize }) => pxToVh(fontSize || 14)}vh !important;
        line-height: ${({ $lineHeight }) => pxToVh($lineHeight || 16)}vh !important;
        font-weight: ${({ $fontWeight }) => $fontWeight} !important;
        padding: ${({ padding }) => padding || `${pxToVh(4)}vh ${pxToVw(8)}vw`};
        background-color: ${({ $backgroundColor }) => $backgroundColor || colorsCode.cyan100};
        border-radius: ${({ $borderRadius }) => $borderRadius || '4px'};
        align-items: ${({ alignItems }) => alignItems};
        cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
        height: auto;

        span {
            padding: 0;
            cursor: inherit;

            ${({ multiline }) =>
                multiline &&
                css`
                    display: block;
                    white-space: normal;
                `}
        }

        .MuiChip-icon {
            margin-left: 0;
            margin-right: ${pxToVw(12)}vw;
        }
    }
`;

Chip.defaultProps = {
    backgroundColor: null,
    padding: null,
    lineHeight: null,
    fontWeight: null,
    fontSize: null,
    fontColor: null,
    className: null,
    icon: null,
    borderRadius: null,
    alignItems: null,
    multiline: false,
    onClick: null,
};

Chip.propTypes = {
    label: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    padding: PropTypes.string,
    lineHeight: PropTypes.string,
    fontWeight: PropTypes.number,
    fontSize: PropTypes.string,
    fontColor: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.node,
    borderRadius: PropTypes.string,
    alignItems: PropTypes.string,
    multiline: PropTypes.bool,
    onClick: PropTypes.func,
};

export default Chip;
