import { createSlice } from '@reduxjs/toolkit';
import {
    getAgreementFrequencyQues,
    getQuestionScales,
    updateAgreementFrequencyQues,
    updateAgreementFrequencyInstructions,
    getTextEditTabs,
    getTextEditTabsData,
    updateSystemMessages,
} from '../../actions/TextEdit/actions';

export const INITIAL_STATE = {
    loading: false,
    scale: [],
    agreementScaleQues: [],
    frequentScaleQues: [],
    isUpdated: false,
    error: false,
    errorMessage: '',
    subGroup: {
        agreement: {},
        frequency: {},
    },
    tabs: {},
    tabsData: {},
};

const TextEdit = createSlice({
    initialState: INITIAL_STATE,
    name: 'TextEdit',
    extraReducers: builder => {
        builder
            .addCase(getQuestionScales.pending, state => {
                state.loading = true;
            })
            .addCase(getQuestionScales.fulfilled, (state, { payload }) => {
                const {
                    data: { records = [] },
                } = payload;
                state.loading = false;
                state.scale = records;
            })
            .addCase(getQuestionScales.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(getAgreementFrequencyQues.pending, state => {
                state.loading = true;
            })
            .addCase(getAgreementFrequencyQues.fulfilled, (state, { payload }) => {
                state.loading = false;
                const array = Object.values(payload.data.data.records);
                const data = array.length ? array.map(item => ({ ...item, is_edited: false })) : [];
                if (payload.type === 'agreement') {
                    state.agreementScaleQues = data;
                    state.subGroup.agreement = payload?.data?.data?.sub_group;
                }
                if (payload.type === 'frequency') {
                    state.frequentScaleQues = data;
                    state.subGroup.frequency = payload.data?.data?.sub_group;
                }
            })
            .addCase(getAgreementFrequencyQues.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateAgreementFrequencyQues.pending, state => {
                state.loading = true;
            })
            .addCase(updateAgreementFrequencyQues.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateAgreementFrequencyQues.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateAgreementFrequencyInstructions.pending, state => {
                state.loading = true;
            })
            .addCase(updateAgreementFrequencyInstructions.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateAgreementFrequencyInstructions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(getTextEditTabs.pending, state => {
                state.loading = true;
            })
            .addCase(getTextEditTabs.fulfilled, (state, { payload }) => {
                state.tabs = payload.data;
                if (Object.keys(payload.data).length === 0) {
                    state.loading = false;
                }
            })
            .addCase(getTextEditTabs.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(getTextEditTabsData.pending, state => {
                state.loading = true;
            })
            .addCase(getTextEditTabsData.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.tabsData = payload.data;
            })
            .addCase(getTextEditTabsData.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateSystemMessages.pending, state => {
                state.loading = true;
            })
            .addCase(updateSystemMessages.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateSystemMessages.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        reset: state => {
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = false;
        },
        resetScale: state => {
            state.scale = [];
            state.agreementScaleQues = [];
            state.frequentScaleQues = [];
        },
        resetTabs: state => {
            state.tabs = {};
            state.tabsData = {};
        },
    },
});

export const { reset, resetScale, resetTabs } = TextEdit.actions;
export default TextEdit.reducer;
