import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Text from '../Text';
import ContentModal from '../ContentModal';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const PracticeDetailModal = ({ open, onClose, data }) => {
    return (
        <ContentModal
            open={open}
            onClose={onClose}
            fullScreen
            closeIconColor={colorsCode.Neutral80}
            closeIconHeight="16px"
            closeIconWidth="16px"
            highlightCloseIcon
        >
            <ContentContainer gap={pxToVh(32)}>
                <FlexColumnWithAlign gap={pxToVh(5)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={24}
                        lineHeight={28}
                        fontWeight={500}
                        label={data.heading}
                    />
                    <Text fontColor={colorsCode.Neutral80} fontSize={16} lineHeight={24} label={data.description} />
                </FlexColumnWithAlign>
                <FlexColumnWithAlign gap={pxToVh(12)} className="full-width">
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={18}
                        lineHeight={28}
                        fontWeight={500}
                        label="Practices"
                    />
                    <FlexColumnWithAlign gap={pxToVh(24)} className="full-width">
                        {data?.practices?.map(practice => {
                            return (
                                <PracticeCard key={practice.id}>
                                    <Text
                                        fontColor={colorsCode.TextSubtle}
                                        fontSize={16}
                                        lineHeight={24}
                                        fontWeight={500}
                                        label={practice.heading}
                                    />
                                    <Text
                                        fontColor={colorsCode.TextSubtle}
                                        fontSize={16}
                                        lineHeight={24}
                                        label={practice.description}
                                    />
                                </PracticeCard>
                            );
                        })}
                    </FlexColumnWithAlign>
                </FlexColumnWithAlign>
            </ContentContainer>
        </ContentModal>
    );
};

export const ContentContainer = styled(FlexColumnWithAlign)`
    padding: ${pxToVh(84)}vh ${pxToVw(62)}vw ${pxToVh(40)}vh ${pxToVw(32)}vw;
`;

export const PracticeCard = styled(FlexColumnWithAlign)`
    border-bottom: 1px solid ${colorsCode.Neutral10};
    padding: ${pxToVh(6)}vh ${pxToVw(6)}vw;
    width: 100%;

    &:first-child {
        border-top: 1px solid ${colorsCode.Neutral10};
    }
`;

PracticeDetailModal.defaultProps = {
    open: false,
    onClose: () => {},
    data: {},
};

PracticeDetailModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    data: PropTypes.shape({
        heading: PropTypes.string,
        description: PropTypes.string,
        practices: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default PracticeDetailModal;
