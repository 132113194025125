import { makeStyles } from '@mui/styles';
import { pxToVh, pxToVw, colorsCode } from '../../common/colors';

const MonitorStyles = makeStyles(() => {
    return {
        headerContainer: {
            paddingBottom: `${pxToVh(16)}vh`,
        },
        backLinkContainer: {
            cursor: 'pointer',
        },
        backLinkText: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            textDecoration: 'underline !important',
            color: colorsCode.SecondaryDeepBlue500,
        },
        monitorSurveySection: {
            padding: `${pxToVh(48)}vh 0`,
        },
        surveyActionsContainer: {
            width: '100%',
        },
        surveyText: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            color: colorsCode.TextSubtle,
        },
        textButton: {
            cursor: 'pointer',

            '&.disabled': {
                cursor: 'not-allowed',
                '& svg path': {
                    fill: colorsCode.Neutral30,
                },

                '& p': {
                    color: colorsCode.Neutral30,
                    cursor: 'inherit',
                },
            },
        },
        exportIcon: {
            '& path': {
                fill: colorsCode.SecondaryDeepBlue500,
            },
        },
        buttonLabel: {
            fontSize: `${pxToVh(12)}vh !important`,
            lineHeight: `${pxToVh(16)}vh !important`,
            letterSpacing: `${pxToVw(0.6)}vw`,
            color: colorsCode.SecondaryDeepBlue500,
            cursor: 'pointer',
        },
        clearTitleDisabled: {
            color: `${colorsCode.Neutral30} !important`,
            pointerEvents: 'none',
        },
        surveyDetailContainer: {
            backgroundColor: colorsCode.cyan100,
            borderRadius: '8px',
            padding: `${pxToVh(20)}vh ${pxToVw(24)}vw`,
            width: '100%',
            margin: `${pxToVh(24)}vh 0 ${pxToVh(48)}vh`,

            '& .chipClass': {
                padding: `${pxToVh(2)}vh ${pxToVw(8)}vw`,
                borderRadius: `${pxToVh(10)}vh`,
            },
        },
        surveyDetailText: {
            fontSize: `${pxToVh(18)}vh !important`,
            lineHeight: `${pxToVh(28)}vh !important`,
            fontWeight: '500 !important',
            color: colorsCode.Neutral80,
        },
        bottomDivider: {
            boxShadow: '0 5px 4px -4px rgba(5, 28, 44, 0.20)',
            width: 'calc(100vw - 14vw)',
            height: '14px',
            margin: '-14px -3vw 0',
        },
        tabContainer: {
            padding: `0 ${pxToVw(4)}vw`,
        },
        tabClass: {
            fontWeight: '300 !important',
            color: `${colorsCode.Neutral80} !important`,
            cursor: 'pointer',
            fontSize: `${pxToVh(16)}vh !important`,
            lineHeight: `${pxToVh(24)}vh !important`,
            padding: `0 0 ${pxToVh(8)}vh`,
        },
        activeClass: {
            borderBottom: `${pxToVh(4)}vh solid ${colorsCode.PrimaryDeepBlue}`,
            color: `${colorsCode.PrimaryDeepBlue} !important`,
            fontWeight: '400 !important',
            cursor: 'pointer',
            padding: `0 0 ${pxToVh(4)}vh !important`,
        },
        disabledTabClass: {
            color: `${colorsCode.Neutral30} !important`,
            cursor: 'default',
            pointerEvents: 'none',
        },
        liveSurveysEmptyState: {
            color: `${colorsCode.Neutral80} !important`,
            fontSize: `${pxToVh(16)}vh !important`,
            lineHeight: `${pxToVh(24)}vh !important`,
            padding: `${pxToVh(24)}vh 0`,
        },
        tabContentContainer: {
            padding: `${pxToVh(48)}vh 0 0`,
            width: '100%',
        },
        tabContentHeading: {
            fontSize: `${pxToVh(24)}vh !important`,
            lineHeight: `${pxToVh(28)}vh !important`,
            fontWeight: '500 !important',
            color: `${colorsCode.TextHeading}`,
        },
        tabContentSubtitle: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            fontWeight: '300 !important',
            color: `${colorsCode.Neutral80}`,
            fontStyle: 'italic',
        },
        responseRateCard: {
            borderRadius: '8px',
            backgroundColor: `${colorsCode.white}`,
            boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
            width: '100%',
            minHeight: `${pxToVh(232)}vh`,
            padding: `0 ${pxToVw(24)}vw`,
            margin: `${pxToVh(24)}vh 0 0 0`,
        },
        responseDetailContainer: {},
        updateTextContainer: {
            alignSelf: 'flex-end',
            cursor: 'pointer',
        },
        updateText: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(24)}vh !important`,
            letterSpacing: `${pxToVw(0.7)}vw`,
            color: colorsCode.SecondaryDeepBlue500,
        },
        editRespInvitesContainer: {
            marginLeft: `calc(12px + ${pxToVw(10)}vw)`,
        },
        responseInfoTitle: {
            fontSize: `${pxToVh(12)}vh !important`,
            lineHeight: `${pxToVh(16)}vh !important`,
            fontWeight: '500 !important',
            color: colorsCode.TextSubtle,
        },
        pieGraphContainer: {
            position: 'relative',
        },
        pieGraphBackground: {
            color: `${colorsCode.SecondaryAmber100} !important`,
        },
        pieGraphForeground: {
            color: `${colorsCode.primaryAmber} !important`,
            position: 'absolute',
            zIndex: 1,
        },
        pieLabelContainer: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            alignItems: 'center !important',
            justifyContent: 'center',
        },
        pieLabelHeading: {
            fontSize: `${pxToVh(48)}vh !important`,
            lineHeight: `${pxToVh(56)}vh !important`,
            fontWeight: '700 !important',
            color: colorsCode.PrimaryDeepBlue,
            fontFamily: 'Bower !important',
        },
        pieLabelInfo: {
            fontSize: `${pxToVh(20)}vh !important`,
            lineHeight: `${pxToVh(32)}vh !important`,
            color: colorsCode.PrimaryDeepBlue,
        },
        flexClass: {
            flex: 1,
        },
        fullWidthClass: {
            width: '100%',
        },
        demographicsContainer: {
            padding: `${pxToVh(60)}vh 0 0`,
            width: '100%',
        },
        demographicsHeaderContainer: {
            width: '100%',
        },
        filterButton: {
            padding: `${pxToVh(4)}vh ${pxToVw(8)}vw`,
            cursor: 'pointer',
        },
        filterButtonText: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(24)}vh !important`,
            color: colorsCode.Neutral80,
        },
        // demographics graph
        demographicsCardsContainer: {
            flexWrap: 'wrap',
            gap: `${pxToVh(20)}vh ${pxToVw(20)}vw`,
            width: '100%',
            margin: `${pxToVh(24)}vh 0 0 0`,
        },
        demographicsCard: {
            borderRadius: '8px',
            backgroundColor: colorsCode.white,
            boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
            width: `calc(50% - ${pxToVw(5)}vw)`,
            overflow: 'hidden',
        },
        demographicsCardHeading: {
            padding: `${pxToVh(12)}vh ${pxToVw(20)}vw`,
            backgroundColor: colorsCode.cyan100,
            width: '100%',
        },
        demographicsCardHeadingText: {
            fontSize: `${pxToVh(12)}vh !important`,
            lineHeight: `${pxToVh(16)}vh !important`,
            fontWeight: '500 !important',
            color: colorsCode.TextSubtle,
        },
        // demographics chart js graph
        demographGraphContainer: {
            width: '100%',
            padding: `${pxToVh(20)}vh ${pxToVw(20)}vw`,
            minHeight: `${pxToVh(335)}vh`,
        },
        graphXLabelContainer: {
            width: '100%',
            margin: `${pxToVh(5)}vh 0 0 0`,
            justifyContent: 'center !important',
        },
        graphXLabel: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            color: colorsCode.Neutral80,
        },
        legendContainer: {
            width: '100%',
            margin: `0 0 ${pxToVh(20)}vh`,
            justifyContent: 'center !important',
        },
        // Update threshold modal
        closeIconContainer: {
            margin: `0 0 ${pxToVh(16)}vh`,
            cursor: 'pointer',
            height: '18px',
        },
        thresholdModalTitle: {
            fontSize: `${pxToVh(32)}vh !important`,
            lineHeight: `${pxToVh(36)}vh !important`,
            fontWeight: '500 !important',
            color: colorsCode.TextHeading,
            padding: '0 !important',
        },
        thresholdDropdownTitle: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            letterSpacing: `${pxToVw(0.14)}vw`,
            fontWeight: '500 !important',
            color: colorsCode.neutralGray700,
        },
        thresholdOverallTextContainer: {
            fontSize: `${pxToVh(18)}vh !important`,
            lineHeight: `${pxToVh(28)}vh !important`,
            color: colorsCode.Neutral80,
        },
        bold: {
            fontWeight: '500 !important',
            color: colorsCode.TextHeading,
        },
        thresholdOptionWrapper: {
            maxHeight: '35vh',
            overflowY: 'auto',
            minHeight: '25vh',
        },
        thresholdOptionText: {
            fontSize: `${pxToVh(16)}vh !important`,
            lineHeight: `${pxToVh(24)}vh !important`,
            fontWeight: '500 !important',
            color: colorsCode.Neutral80,
            marginRight: `${pxToVw(16)}vw !important`,
            wordWrap: 'break-word',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 'calc(100% - 12vw)',
        },
        // filter modal
        filterHeaderContainer: {
            padding: `${pxToVw(24)}vw`,
            borderBottom: `1px solid ${colorsCode.Neutral10}`,
        },
        filterModalTitle: {
            fontSize: `${pxToVh(18)}vh !important`,
            lineHeight: `${pxToVh(28)}vh !important`,
            fontWeight: '300 !important',
            color: colorsCode.TextHeading,
        },
        filterOptionsContainer: {
            maxHeight: '350px',
            overflowY: 'auto',
        },
        filterOptionTitle: {
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            fontWeight: '500 !important',
            color: colorsCode.Neutral80,
        },
        filterInput: {
            height: '4vh !important',
            borderColor: `1px solid ${colorsCode.Neutral30} !important`,
            maxWidth: '20vw !important',
            width: '100%',
            borderRadius: '22px !important',
        },
        dropdownMenuItemContainer: {
            width: '100%',
            padding: `${pxToVh(4)}vh ${pxToVw(8)}vw`,
        },
        dropdownMenuSurveyStatus: {
            padding: `${pxToVh(2)}vh ${pxToVw(8)}vw`,
            borderRadius: `${pxToVh(10)}vh`,

            '& .live-icon': {
                margin: `${pxToVh(2)}vh ${pxToVw(2)}vw`,
                width: `${pxToVw(8)}vw`,
                height: `${pxToVh(8)}vh`,
            },
            '& .closed-icon': {
                width: `${pxToVw(12)}vw`,
                height: `${pxToVh(12)}vh`,
            },
        },
    };
});

export default MonitorStyles;
