import { parseJwt } from './functions';

class AuthStore {
    constructor() {
        if (!AuthStore.instance) {
            this._data = localStorage;
            AuthStore.instance = this;
        }
        return AuthStore.instance;
    }

    set accessToken(val) {
        this._data.setItem('access_token', val);
    }

    set refreshToken(val) {
        this._data.setItem('refresh_token', val);
    }

    get refreshToken() {
        return this._data.getItem('refresh_token');
    }

    set midToken(refresh_token) {
        this._data.setItem('midToken', refresh_token);
    }

    get getMidToken() {
        return this._data.getItem('midToken') || null;
    }

    set auth_time(auth_time) {
        this._data.setItem('auth_time', auth_time);
    }

    set redirectionURL(redirect) {
        this._data.setItem('redirect', redirect);
    }

    get parsedData() {
        return parseJwt(this._data.getItem('access_token'));
    }

    get accessToken() {
        return this._data.getItem('access_token') || null;
    }

    get FullName() {
        const { name = '' } = this.parsedData || {};
        return name ? name : '';
    }

    get name() {
        if (this.parsedData) {
            const { name: username = '' } = this.parsedData || {};
            const usernameData = username;
            return usernameData ? `${usernameData[0]?.toUpperCase()}${usernameData?.slice(1)}` : '';
        }
        return '';
    }

    get isAdmin() {
        const { role = '' } = this.parsedData || {};
        return role === 'Admin';
    }

    get isCST() {
        const { role = '' } = this.parsedData || {};
        return role === 'CST';
    }

    get isCoach() {
        const { role = '' } = this.parsedData || {};
        return role === 'Coach';
    }

    get isClient() {
        const { role = '' } = this.parsedData || {};
        return role === 'Client';
    }

    get initials() {
        const { name: clientname = '' } = this.parsedData || {};
        if (clientname) {
            return name ? this.getInitials(name) : this.getInitials(clientname);
        }
        return '';
    }

    getInitials = name => {
        const splitName = name?.split(' ');
        const initials =
            splitName?.length >= 2
                ? `${splitName[0][0]?.toUpperCase()}${splitName[1][0]?.toUpperCase()}`
                : splitName[0][0]?.toUpperCase() || '';
        return initials;
    };

    get isFirstLogin() {
        const { last_login_at = '' } = this.parsedData || {};
        return !last_login_at;
    }

    get isAdminOrCoach() {
        const { role = '' } = this.parsedData || {};
        return ['Admin', 'Super Admin', 'Coach'].includes(role);
    }

    get isClientOrCst() {
        const { role = '' } = this.parsedData || {};
        return ['CST', 'Client'].includes(role);
    }

    get redirectionURL() {
        return this._data.getItem('redirect');
    }
}

const instance = new AuthStore();
Object.freeze(instance);

export default instance;
