import styled from 'styled-components';

export const ModalContainer = styled.div`
    width: ${props => props.width || '100%'};
    // height: ${props => props.height || '0%'};
    background-color: ${props => props.backgroundColor || 'white'};
    padding: 30px;
    margin: 20px;
    box-shadow: 0px 0px 0.7526665925979614px rgba(5, 28, 44, 0.15);
    border-radius: 8px;
`;

export const ListContainer = styled.div`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '50%'};
    // margin: 350px
`;
