import styled, { css } from 'styled-components';
import { FlexBetween, FlexCenterWithAlign, FlexColumnWithAlign, FlexRow } from '../../../common/styled/styled';
import { Image, ParentDiv } from '../OverviewPage/styles';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { SECTION_LEFT_PADDING } from '../../../components/KnowledgeCenter/GanttChart/styles';

export const PhaseContentContainer = styled(FlexColumnWithAlign)`
    padding: 0 0 0 ${SECTION_LEFT_PADDING};
    width: 100%;
`;

export const AdministrationPlanContainer = styled.div`
    margin: ${pxToVh(72)}vh 0 0 0;
    width: 100%;
`;

export const AdministrationContent = styled(ParentDiv)`
    border-bottom: 1px solid ${colorsCode.Neutral10};
    padding: 0 ${pxToVw(67)}vw ${pxToVh(20)}vh 0;
`;

export const AdministrationSubheading = styled(FlexRow)`
    padding: ${pxToVh(6)}vh ${pxToVw(8)}vw;
    border-top: 2px solid ${colorsCode.Neutral30};
    border-bottom: 1px solid ${colorsCode.Neutral30};
`;

export const TaskChecklistContainer = styled.div`
    margin: ${pxToVh(60)}vh 0 0 0;
    width: 100%;
`;

export const ChecklistItem = styled(FlexRow)`
    padding: ${pxToVh(4)}vh ${pxToVw(8)}vw;

    ${({ isTimeIntensive }) =>
        isTimeIntensive &&
        css`
            background-color: ${colorsCode.Neutral05};
            border-radius: 6px;
        `}

    img {
        width: ${pxToVw(24)}vw;
        height: ${pxToVh(24)}vh;
    }
`;

export const TimeSensitiveColorBox = styled.div`
    width: ${pxToVw(21)}vw;
    height: ${pxToVh(21)}vh;
    background-color: ${colorsCode.Neutral05};
`;

export const ComponentsDesignParentDiv = styled(FlexColumnWithAlign)`
    width: 100%;
    border-bottom: 1px solid ${colorsCode.Neutral10};
    padding: 0 ${pxToVw(50)}vw ${pxToVh(20)}vh 0;

    &:nth-child(even) {
        .content-div {
            flex-direction: row-reverse;

            > :first-child {
            }
        }
    }

    &:nth-child(odd) {
        .content-div {
            > :first-child {
            }
        }
    }

    &:last-child {
        border-bottom: none;
    }
`;

export const ParentContentDiv = styled(FlexBetween)`
    width: 100%;
    ${({ tableData }) =>
        !tableData &&
        css`
            > :first-child {
                flex: 0.55;
            }

            > :last-child {
                flex: 0.45;
            }
        `}

    ${({ tableData }) =>
        tableData &&
        css`
            gap: ${pxToVw(158)}vw;

            > :first-child {
                flex: 0.67;
            }

            > :last-child {
                flex: 0.33;
            }
        `}
`;

export const WarningInfoContainer = styled(FlexRow)`
    padding: ${({ padding }) => padding || `${pxToVh(13)}vh ${pxToVw(32)}vw ${pxToVh(19)}vh`};
    background-color: ${colorsCode.lightCyan};
    border-radius: 12px;
    width: 100%;

    ${({ hasMargin }) =>
        hasMargin &&
        css`
            margin-top: ${pxToVh(43)}vh;
        `}
`;

export const StepperContainer = styled.div`
    padding: 0 ${pxToVw(31)}vw 0 0;
`;

export const StepContentContainer = styled(FlexBetween)`
    width: 100%;
    > :first-child {
        flex: 0.65;
    }
    > :last-child {
        flex: 0.35;
    }
`;

export const StepIconContainer = styled(FlexCenterWithAlign)`
    width: ${pxToVh(35)}vh;
    height: ${pxToVh(35)}vh;
    padding: ${pxToVh(7)}vh ${pxToVw(6)}vw ${pxToVh(6)}vh ${pxToVw(7)}vw;
    background-color: ${colorsCode.noteBackgroundColor};
    border-radius: 50%;

    img {
        width: 100%;
        object-fit: contain;
        width: ${pxToVh(20)}vh;
        height: ${pxToVh(20)}vh;
    }
`;

export const IPInfoNote = styled(FlexRow)`
    padding-top: ${pxToVh(4)}vh;
    border-top: 1px dashed ${colorsCode.black200};
`;

export const SeeNextTextContainer = styled(FlexColumnWithAlign)`
    padding-bottom: ${pxToVh(10)}vh;
    border-bottom: 1px solid ${colorsCode.Neutral10};
    width: 100%;
`;

export const ImagePlaceholder = styled(FlexCenterWithAlign)`
    border-radius: 14px;
    width: ${pxToVw(360)}vw;
    max-width: 100%;
    height: ${pxToVh(216)}vh;
    background-color: ${colorsCode.Neutral05};
`;

export const DemographicsTableContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
`;

export const TableRow = styled.div`
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
    border-bottom: 1px solid ${colorsCode.Neutral10};
    column-gap: ${pxToVw(16)}vw;
`;

export const ColumnHeaderRow = styled(TableRow)`
    padding: ${pxToVh(4)}vh 0;
    align-items: center;
`;

export const ColumnCell = styled(FlexColumnWithAlign)``;

export const DemographicsTableRow = styled(TableRow)`
    padding: ${pxToVh(33)}vw 0;

    &:not(:last-child) {
        margin-bottom: ${pxToVh(14)}vh;
    }
`;

export const ImageStyled = styled(Image)`
    border-radius: 14px;
    filter: drop-shadow(0px 0px 1px rgba(5, 28, 44, 0.15)) drop-shadow(0px 2px 4px rgba(5, 28, 44, 0.2));
    cursor: pointer;
`;
