import { makeStyles } from '@mui/styles';

const UserthemeStyles = makeStyles(() => {
    return {
        activeClass: {
            borderBottom: '0.5vh solid #051C2C',
            color: '#051C2C !important',
            fontWeight: '400 !important',
            cursor: 'pointer',
            fontSize: '2.23vh !important',
        },
        tabClass: {
            fontSize: '2.23vh !important',
            fontWeight: '300 !important',
            color: '#333333 !important',
            cursor: 'pointer',
        },
        textField: {
            width: '29.93vw',
            height: '5.67vh !important',
            borderRadius: '2px',
        },
        nonProfitSurvey: {
            borderRadius: '10px',
            padding: '2px 8px',
            backgroundColor: '#0679C3',
            color: '#fff',
            fontWeight: 500,
        },
        ohiSurvey: {
            borderRadius: '10px',
            padding: '2px 8px',
            backgroundColor: '#E6E6E6',
            color: '#333333',
            fontWeight: 500,
        },
        sectionHeader: {
            background: '#E6F2F9',
            height: '21vh',
            padding: '2.9vh 2.9vh',
        },
        btn: {
            color: 'black !important',
            border: '1px solid black !important',
            fontSize: '1.98vh !important',
            fontWeight: '400 !important',
        },
        img: {
            height: '2.12vh',
            width: '1.04vw',
        },
        clientOverview: {
            paddingLeft: '12vh',
            paddingTop: '3vh',
            // paddingBottom: "0.4vh",
            paddingRight: '8vh',
        },
        clientText: {
            fontSize: '4.53vh !important',
            lineHeight: '2 !important',
            fontWeight: '500 !important',
        },
        linkStyle: {
            fontSize: '1.98vh !important',
            textDecoration: 'none !important',
        },
        cursor: {
            cursor: 'pointer !important',
            // textDecoration: 'underline !important',
        },
        rowClass: {
            gap: '2vw !important',
            marginTop: '4vh !important',
            fontWeight: '300 !important',
        },
        imgBtn: {
            width: '1.67vw',
            height: '3.4vh',
            cursor: 'pointer',
        },
        imgPlay: {
            width: '1.67vw',
            height: '3.4vh',
            cursor: 'pointer',
            marginTop: '1vh',
        },
        flexStartGap: {
            gap: '1.80vw',
            alignItems: 'center !important',
        },
        filterParent: {
            marginBottom: '4.05vh',
        },
        filterTxt: {
            color: '#051C2C',
            fontSize: '2.19vh !important',
            cursor: 'pointer',
        },
        greyedTxt: {
            color: '#B3B3B3',
            fontSize: '1.7vh !important',
            textTransform: 'uppercase',
            cursor: 'not-allowed',
            letterSpacing: '0.6px',
        },
        selectedTxt: {
            color: '#2B5580',
            fontSize: '1.7vh !important',
            textTransform: 'uppercase',
            cursor: 'pointer',
            letterSpacing: '0.6px',
        },
        selectedIcon: {
            stroke: '#2B5580',
        },
        chatBotStyle: {
            position: 'absolute',
            left: '100%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            zIndex: '30',
        },
    };
});

export default UserthemeStyles;
