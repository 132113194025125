import { createSlice } from '@reduxjs/toolkit';

export const INTITIAL_STATE = {
    isOpen: false,
    statusCode: '',
};

const ModalReducer = createSlice({
    name: 'ModalReducer',
    initialState: INTITIAL_STATE,
    reducers: {
        openModal: state => {
            state.isOpen = true;
        },
        closeModal: state => {
            state.isOpen = false;
        },
        setStatusCode: (state, { payload }) => {
            state.statusCode = payload;
        },
    },
});

export const { openModal, closeModal, setStatusCode } = ModalReducer.actions;
export default ModalReducer.reducer;
