import React, { useRef, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import Text from '../../../components/KnowledgeCenter/Text';
import ObjectiveComponent from '../../../components/KnowledgeCenter/ObjectiveComponent';
import { Section, FlexColumnWithAlign } from '../../../common/styled/styled';
import SurveyLiveOverview from './SurveyLiveOverview';
import SurveyActivityContent from './SurveyActivityContent';
import { LIVE_SURVEY_TEXT, SECTION_KEYS } from '../../../utils/kc-constants';
import {
    ANALYSIS_ACTIVITY_DATA,
    COMMUNICATION_ACTIVITY_DATA,
    LIVE_SURVEY_OBJECTIVES,
    LIVE_SURVEY_OVERVIEW_DATA,
    LIVE_SURVEY_TASK_CHECKLIST,
    RESPONSE_RATE_ACTIVITY_DATA,
    SURVEY_LAUNCH_ACTIVITY_DATA,
} from '../../../utils/kc-data';
import { AppContext } from '../Home';
import { useIntersection } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const LiveSurveyPage = () => {
    const refs = useRef([]);
    const location = useLocation();
    const { activeSection, setActiveSection } = useContext(AppContext);
    const isVisible = useIntersection(refs);
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        if (isVisible) {
            setActiveSection(isVisible);
        }
    }, [isVisible, setActiveSection]);

    useEffect(() => {
        if (!activeSection && !params.get('section')) {
            setActiveSection(SECTION_KEYS.LIVE_SURVEY_OVERVIEW);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection, setActiveSection]);

    return (
        <Section gap={pxToVh(60)} padding={`${pxToVh(70)}vh ${pxToVw(20)}vw ${pxToVh(58)}vh ${pxToVw(59)}vw`}>
            <FlexColumnWithAlign gap={pxToVh(15)}>
                <FlexColumnWithAlign gap={pxToVh(16)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={44}
                        lineHeight={52}
                        label={LIVE_SURVEY_TEXT.SETUP_SURVEY_HEADING}
                        fontWeight={500}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={24}
                        lineHeight={36}
                        label={LIVE_SURVEY_TEXT.SETUP_SURVEY_DESCRIPTION}
                    />
                </FlexColumnWithAlign>
                <ObjectiveComponent data={LIVE_SURVEY_OBJECTIVES} />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign
                ref={element => (refs.current[0] = element)}
                id={SECTION_KEYS.LIVE_SURVEY_OVERVIEW}
                margin={`-${pxToVh(22)}vh 0 0 0`}
            >
                <SurveyLiveOverview
                    overviewData={LIVE_SURVEY_OVERVIEW_DATA}
                    taskChecklistData={LIVE_SURVEY_TASK_CHECKLIST}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => (refs.current[1] = element)} id={SECTION_KEYS.SURVEY_LAUNCH_ACTIVITY}>
                <SurveyActivityContent
                    heading={LIVE_SURVEY_TEXT.SURVEY_LAUNCH_SECT_HEADING}
                    activityData={SURVEY_LAUNCH_ACTIVITY_DATA}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => (refs.current[2] = element)} id={SECTION_KEYS.COMMUNICATION_ACTIVITY}>
                <SurveyActivityContent
                    heading={LIVE_SURVEY_TEXT.COMMUNICATION_ACTIVITY_SECT_HEADING}
                    activityData={COMMUNICATION_ACTIVITY_DATA}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => (refs.current[3] = element)} id={SECTION_KEYS.RESPONSE_ACTIVITY}>
                <SurveyActivityContent
                    heading={LIVE_SURVEY_TEXT.RESPONSE_RATE_ACTIVITY_SECT_HEADING}
                    activityData={RESPONSE_RATE_ACTIVITY_DATA}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign
                ref={element => (refs.current[4] = element)}
                id={SECTION_KEYS.ANALYSIS_PREPARATION_SURVEY_CLOSE}
            >
                <SurveyActivityContent
                    heading={LIVE_SURVEY_TEXT.ANALYSIS_ACTIVITY_SECT_HEADING}
                    activityData={ANALYSIS_ACTIVITY_DATA}
                />
            </FlexColumnWithAlign>
        </Section>
    );
};

LiveSurveyPage.defaultProps = {};

LiveSurveyPage.propTypes = {};

export default LiveSurveyPage;
