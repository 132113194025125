import styled from 'styled-components';
import { Popover } from '@mui/material';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const OutcomeContentContainer = styled(FlexColumnWithAlign)`
    .content-div {
        width: 100%;
        > div:first-child {
            flex: 0.7;
        }

        > div:last-child {
            flex: 0.3;
        }
    }
`;

export const StyledDialog = styled(Popover)`
    && .MuiPaper-root {
        padding: ${pxToVh(24)}vh ${pxToVw(24)}vw;
        max-width: ${pxToVw(322)}vw;
        width: 100%;
        border: 1px solid ${colorsCode.backgroundDisabled};
        border-radius: 0;
        box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 8px 16px -1px rgba(5, 28, 44, 0.2);
    }
`;
