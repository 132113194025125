import styled from 'styled-components';
import { FlexColumnBetween } from '../../../common/styled/styled';
import Chip from '../Chip';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled(FlexColumnBetween)`
    padding: ${({ padding }) => padding || `${pxToVh(8)}vh ${pxToVw(8)}vw ${pxToVh(24)}vh`};
    background-color: ${({ backgroundColor }) => backgroundColor || colorsCode.neutral02};
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    min-height: ${({ height }) => height || `${pxToVh(257)}vh`};
    align-self: stretch;
    min-width: ${({ width }) => width || `${pxToVw(267)}vw`};
    max-width: ${({ width }) => width || `${pxToVw(267)}vw`};
    cursor: pointer;
    border-radius: 8px;

    .content-card-text {
        flex: 1;
    }
`;

export const MediaContainer = styled.div`
    position: relative;
    min-height: ${({ mediaHeight }) => mediaHeight || `${pxToVh(141)}vh`};
    height: ${({ mediaHeight }) => mediaHeight || `${pxToVh(141)}vh`};
    width: 100%;
`;

export const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const VideoThumbnail = styled.video`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

export const PlayIcon = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    cursor: pointer;
`;

export const ChipStyled = styled(Chip)`
    position: absolute !important;
    top: 8px;
    left: 8px;
    z-index: 10;
    cursor: pointer !important;
`;
