import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../http/api';
import { Endpoints } from '../../../http/Endpoints';

export const GetClosingProtocolRawData = createAsyncThunk(
    Endpoints.getClosingProtocol,
    async (params, { rejectWithValue }) => {
        const { surveyId } = params;
        try {
            const response = await api.get(Endpoints.getClosingProtocol.replace(':survey_id', surveyId));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetClosingProtocol = createAsyncThunk(
    Endpoints.closingProtocolEndpoint,
    async (params, { rejectWithValue }) => {
        const { surveyId } = params;
        try {
            const response = await api.get(Endpoints.closingProtocolEndpoint.replace(':survey_id', surveyId));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const PutClosingProtocol = createAsyncThunk(
    `${Endpoints.closingProtocolEndpoint}put`,
    async (params, { rejectWithValue }) => {
        const { surveyId, body } = params;
        try {
            const response = await api.put(Endpoints.closingProtocolEndpoint.replace(':survey_id', surveyId), body);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);
export const PostSalesForceClosingProtocol = createAsyncThunk(
    `${Endpoints.salesforceEndpoint}post`,
    async (params, { rejectWithValue }) => {
        try {
            const response = await api.post(Endpoints.salesforceEndpoint.replace('/:opportunity_name', ''), params);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const GetSalesForceClosingProtocol = createAsyncThunk(
    Endpoints.salesforceEndpoint,
    async (params, { rejectWithValue }) => {
        const { client_id, salesforce_id } = params;
        const endpoint = Endpoints.salesforceEndpoint.replace(
            '/:opportunity_name',
            client_id ? `?client_id=${client_id}` : salesforce_id ? `?salesforce_id=${salesforce_id}` : ''
        );
        try {
            const response = await api.get(endpoint);
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);

export const SearchSalesForceClosingProtocol = createAsyncThunk(
    `${Endpoints.salesforceEndpoint}/get`,
    async (params, { rejectWithValue }) => {
        const { opportunity_name } = params;
        try {
            const response = await api.get(Endpoints.salesforceEndpoint.replace(':opportunity_name', opportunity_name));
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error?.response?.data?.message);
        }
    }
);
