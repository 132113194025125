import { createSlice } from '@reduxjs/toolkit';
import { searchClient, searchUser } from '../../actions/Users/actions';

export const INTITIAL_STATE = {
    loading: false,
    isDataFetched: false,
    data: {},
    userData: {},
    error: false,
};

const SearchUserClientSlice = createSlice({
    name: 'SearchUserClient',
    initialState: INTITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(searchClient.pending, state => {
                state.loading = true;
            })
            .addCase(searchClient.fulfilled, (state, { payload }) => {
                const dataObject = payload?.data?.records?.length
                    ? payload?.data?.records.map(({ client_name, uuid, meta_info }) => {
                          return { client_name, uuid, meta_info };
                      })
                    : [];
                state.loading = false;
                state.isDataFetched = true;
                state.data = dataObject;
            })
            .addCase(searchClient.rejected, state => {
                state.loading = false;
                state.isDataFetched = true;
                state.error = true;
            })
            .addCase(searchUser.pending, state => {
                state.loading = true;
            })
            .addCase(searchUser.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.isDataFetched = true;
                state.userData = payload.data?.records;
            })
            .addCase(searchUser.rejected, state => {
                state.loading = false;
                state.isDataFetched = true;
                state.error = true;
            });
    },
    reducers: {
        resetSearched: state => {
            state.isDataFetched = false;
            state.data = {};
            state.error = false;
            state.userData = {};
        },
    },
});

export const { resetSearched } = SearchUserClientSlice.actions;
export default SearchUserClientSlice.reducer;
