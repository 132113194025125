import styled, { css } from 'styled-components';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { LIST_TYPE } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled(FlexColumnWithAlign)`
    padding: ${({ padding }) => padding || `${pxToVh(15)}vh ${pxToVw(21)}vw ${pxToVh(21)}vh`};
    background-color: ${({ backgroundColor }) => backgroundColor || colorsCode.feedbackLightNeutral};
    border-radius: 12px;
    width: 100%;
`;

export const ListContainer = styled.ul`
    padding-left: ${pxToVw(25)}vw;
    list-style: disc;
    margin: ${({ margin }) => margin || 0};

    ${({ listType }) =>
        listType === LIST_TYPE.NUMERAL &&
        css`
            list-style: decimal;
        `}

    ${({ listType }) =>
        listType === LIST_TYPE.NONE &&
        css`
            list-style: none;
            padding: 0;
        `}
`;

export const OrderedListContainer = styled.ol`
    margin: ${({ margin }) => margin || 0};
    padding-left: ${pxToVw(25)}vw;
`;
