import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip as MuiTooltip } from '@mui/material';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';

const Tooltip = ({
    text,
    fontColor,
    fontSize,
    textAlign,
    lineHeight,
    fontWeight,
    children,
    className,
    placement,
    hasArrow,
    padding,
    backgroundColor,
    borderRadius,
    width,
}) => {
    const style = {
        color: `${fontColor || colorsCode.white}`,
        fontSize: `${pxToVh(fontSize || 12)}vh`,
        textAlign: textAlign || 'center',
        lineHeight: `${pxToVh(lineHeight || 16)}vh`,
        fontWeight: `${fontWeight || 400}`,
        padding: `${padding || `${pxToVh(4)}vh ${pxToVw(12)}vw`}`,
        backgroundColor: `${backgroundColor || colorsCode.Neutral80}`,
        borderRadius: `${borderRadius || 0}`,

        '.MuiTooltip-arrow::before': {
            backgroundColor: `${backgroundColor || colorsCode.Neutral80}`,
        },
    };
    return (
        <TooltipStyled
            placement={placement || 'top'}
            arrow={hasArrow}
            className={`${className}`}
            title={text}
            componentsProps={{
                tooltip: {
                    sx: { ...style },
                },
                popper: {
                    sx: {
                        width: `${width || pxToVw(177)}vw`,
                    },
                },
            }}
        >
            {children}
        </TooltipStyled>
    );
};

const TooltipStyled = styled(MuiTooltip)`
    color: ${({ $fontColor }) => $fontColor || colorsCode.white} !important;

    &&&& .MuiTooltip-tooltip {
        color: ${({ $fontColor }) => $fontColor || colorsCode.white};
        font-size: ${({ fontSize }) => pxToVh(fontSize || 12)} !important;
        line-height: ${({ lineHeight }) => pxToVh(lineHeight || 16)} !important;
    }
`;

Tooltip.defaultProps = {
    fontColor: '',
    fontSize: null,
    lineHeight: null,
    fontWeight: null,
    text: null,
    className: null,
    children: null,
    placement: '',
    hasArrow: true,
    padding: null,
    backgroundColor: null,
    borderRadius: null,
    width: null,
    textAlign: null,
};

Tooltip.propTypes = {
    text: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.number,
    textAlign: PropTypes.string,
    lineHeight: PropTypes.number,
    fontWeight: PropTypes.number,
    className: PropTypes.string,
    children: PropTypes.node,
    placement: PropTypes.string,
    hasArrow: PropTypes.bool,
    padding: PropTypes.string,
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    width: PropTypes.string,
};

export default Tooltip;
