import styled, { css } from 'styled-components';
import { LinearProgress as MuiLinearProgress } from '@mui/material';
import { FlexRow, FlexCenterWithAlign, FlexBetween } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled.div`
    padding: ${pxToVh(0)}vh ${pxToVw(0)}vw;
    background-color: ${colorsCode.white};
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 30;
`;

export const TabContainer = styled(FlexBetween)`
    align-items: center;
`;

export const Tab = styled(FlexCenterWithAlign)`
    padding: ${pxToVh(12.5)}vh ${pxToVw(22.5)}vw;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${({ isActive }) =>
        isActive &&
        css`
            background-color: ${colorsCode.PrimaryDeepBlue};
        `}

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
        `}
`;

export const SecondaryContainer = styled(FlexRow)`
    padding: ${props => (props.isHome ? `${pxToVh(0)}vh ${pxToVw(0)}vw` : `${pxToVh(12)}vh ${pxToVw(20)}vw`)};
    background-color: ${colorsCode.PrimaryDeepBlue};
    gap: ${pxToVw(25)}vw;
`;

export const LinearProgress = styled(MuiLinearProgress)`
    width: 100%;
    height: 4px;

    background-color: rgb(255 187 72 / 20%) !important;

    .MuiLinearProgress-bar {
        background-color: ${colorsCode.primaryAmber};
    }
`;

export const MainBar = styled.div`
    width: 100%;
    height: 4px;
    background-color: ${colorsCode.PrimaryDeepBlue};
`;

export const LogoutContainer = styled.div`
    position: relative;

    & .logout-parent-kc {
        background-color: ${colorsCode.PrimaryDeepBlue};
        position: absolute;
        top: 100%;
        right: 2px;
    }

    & .profile-class-kc {
        background: ${colorsCode.PrimaryDeepBlue};
        height: 4.5vh;
        width: 4.5vh;
        margin: 0 1vw 0 0;
    }
`;
