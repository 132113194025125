import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Typography } from '@mui/material';
import { colorsCode, pxToVh } from '../../common/colors';

const Text = ({
    fontColor,
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    fontFamily,
    label,
    textAlign,
    component,
    children,
    onClick,
    className,
    href,
    textDecoration,
    fontStyle,
    maxLines,
    cursor,
    sx,
}) => {
    return (
        <TypographyStyled
            fontWeight={fontWeight}
            lineHeight={lineHeight}
            fontSize={fontSize}
            $fontColor={fontColor}
            letterSpacing={letterSpacing}
            fontFamily={fontFamily}
            component={component}
            textAlign={textAlign}
            onClick={onClick}
            className={className}
            href={href}
            textDecoration={textDecoration}
            dangerouslySetInnerHTML={label ? { __html: label } : null}
            fontStyle={fontStyle}
            maxLines={maxLines}
            cursor={cursor}
            sx={sx}
        >
            {children}
        </TypographyStyled>
    );
};

const TypographyStyled = styled(Typography)`
    color: ${({ $fontColor }) => $fontColor || colorsCode.Neutral80};
    font-size: ${({ fontSize }) => pxToVh(fontSize || 16)}vh !important;
    line-height: ${({ lineHeight }) => pxToVh(lineHeight || 24)}vh !important;
    letter-spacing: ${({ letterSpacing }) => letterSpacing};
    font-family: ${({ fontFamily }) => fontFamily};
    text-decoration: ${({ textDecoration }) => textDecoration};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : null)};

    a {
        text-decoration: none;
        color: ${colorsCode.SecondaryDeepBlue500};
    }
    ${({ maxLines }) =>
        maxLines &&
        css`
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: ${maxLines};
            line-clamp: ${maxLines};
            -webkit-box-orient: vertical;
        `}

    ${({ cursor }) =>
        cursor &&
        css`
            cursor: ${cursor};
        `}
`;

Text.defaultProps = {
    fontColor: '',
    fontSize: null,
    lineHeight: null,
    fontWeight: null,
    letterSpacing: null,
    fontFamily: null,
    component: 'span',
    children: null,
    textAlign: null,
    label: null,
    onClick: null,
    className: null,
    href: null,
    textDecoration: null,
    fontStyle: null,
    maxLines: null,
    cursor: null,
};

Text.propTypes = {
    label: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    fontWeight: PropTypes.number,
    letterSpacing: PropTypes.string,
    component: PropTypes.string,
    fontFamily: PropTypes.string,
    children: PropTypes.node,
    textAlign: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    href: PropTypes.string,
    textDecoration: PropTypes.string,
    fontStyle: PropTypes.string,
    cursor: PropTypes.string,
    maxLines: PropTypes.number,
};

export default Text;
