import { createSlice } from '@reduxjs/toolkit';
import {
    getAdditionalModulesData,
    updateInstructionText,
    updateOpenEndQues,
    updateValuesSecondaryInstructions,
} from '../../actions/TextEdit/actions';

export const INITIAL_STATE = {
    loading: false,
    data: [],
    isUpdated: false,
    error: false,
    errorMessage: '',
};

const AdditionalModule = createSlice({
    initialState: INITIAL_STATE,
    name: 'AdditionalModule',
    extraReducers: builder => {
        builder
            .addCase(getAdditionalModulesData.pending, state => {
                state.loading = true;
            })
            .addCase(getAdditionalModulesData.fulfilled, (state, { payload }) => {
                const { data = {} } = payload || {};
                const { records = {} } = data || {};
                const keys = Object.keys(records) || [];
                // added is_edited key, and is_disabled in options and questions
                keys?.forEach(kitem => {
                    const ques = Object?.values(records[kitem]?.questions);
                    records[kitem].is_edited = false;
                    records[kitem].is_edited_sub = false;
                    records[kitem].questions = ques?.map((qitem, i) => ({
                        ...qitem,
                        is_edited: false,
                        options: qitem?.options?.map(optItem => {
                            qitem.is_disabled = false;
                            if (ques[i]?.options[0]?.scale_id === ques[i + 1]?.options[0]?.scale_id) {
                                ques[i + 1].is_disabled = true;
                            }
                            return { ...optItem };
                        }),
                    }));
                });
                state.loading = false;
                state.data = records;
            })
            .addCase(getAdditionalModulesData.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateOpenEndQues.pending, state => {
                state.loading = true;
            })
            .addCase(updateOpenEndQues.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateOpenEndQues.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateInstructionText.pending, state => {
                state.loading = true;
            })
            .addCase(updateInstructionText.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateInstructionText.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateValuesSecondaryInstructions.pending, state => {
                state.loading = true;
            })
            .addCase(updateValuesSecondaryInstructions.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateValuesSecondaryInstructions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        resetUpdateAdditional: state => {
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = '';
        },
        resetDatadditional: state => {
            state.data = [];
        },
    },
});

export const { resetUpdateAdditional, resetDatadditional } = AdditionalModule.actions;
export default AdditionalModule.reducer;
