import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import Text from '../../../components/KnowledgeCenter/Text';
import ObjectiveComponent from '../../../components/KnowledgeCenter/ObjectiveComponent';
import { FlexColumnWithAlign, Section } from '../../../common/styled/styled';
import DeploymentTimeline from './DeploymentTimeline';
import KeyActivities from './KeyActivities';
import { PRESURVEY_TEXT, SECTION_KEYS } from '../../../utils/kc-constants';
import {
    DEPLOYMENT_SURVEY_PHASES,
    PRESURVEY_OBJECTIVES,
    KEY_ACTIVITES_DATA,
    DEPLOYMENT_TIMELINE_ACTIVITIES,
    DEPLOYMENT_TIMELINE_DURATION_DATA,
} from '../../../utils/kc-data';
import { AppContext } from '../Home';
import { useIntersection } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const PreSurveyPage = () => {
    const refs = useRef([]);
    const location = useLocation();
    const { setActiveSection, activeSection } = useContext(AppContext);
    const isVisible = useIntersection(refs);
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        if (isVisible) {
            setActiveSection(isVisible);
        }
    }, [isVisible, setActiveSection]);

    useEffect(() => {
        if (!activeSection && !params.get('section')) {
            setActiveSection(SECTION_KEYS.KEY_ACTIVITES);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection, setActiveSection]);

    return (
        <Section gap={pxToVh(48)} padding={`${pxToVh(60)}vh ${pxToVw(42)}vw ${pxToVh(102)}vh ${pxToVw(81)}vw`}>
            <FlexColumnWithAlign gap={pxToVh(32)}>
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={44}
                    lineHeight={52}
                    label={PRESURVEY_TEXT.PRESURVEY_HEADING}
                    fontWeight={500}
                />
                <ObjectiveComponent data={PRESURVEY_OBJECTIVES} />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => refs.current.push(element)} id={SECTION_KEYS.KEY_ACTIVITES}>
                <KeyActivities activitiesData={KEY_ACTIVITES_DATA} />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => refs.current.push(element)} id={SECTION_KEYS.DEPLOYMENT_TIMELINE}>
                <DeploymentTimeline
                    deploymentSurveyPhases={DEPLOYMENT_SURVEY_PHASES}
                    timelineActivities={DEPLOYMENT_TIMELINE_ACTIVITIES}
                    timelineDurationData={DEPLOYMENT_TIMELINE_DURATION_DATA}
                />
            </FlexColumnWithAlign>
        </Section>
    );
};

PreSurveyPage.defaultProps = {};

PreSurveyPage.propTypes = {};

export default PreSurveyPage;
