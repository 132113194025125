import React, { useEffect, useState } from 'react';
import { Box, Pagination, PaginationItem } from '@mui/material';
import { FlexEnd, FlexRow } from '../../common/styled/styled';
import { ReactComponent as Backward2Icon } from '../../assets/img/backward2.svg';
import { ReactComponent as BackwardIcon } from '../../assets/img/backward.svg';
import { ReactComponent as ForwardIcon } from '../../assets/img/forward.svg';
import { ReactComponent as Forward2Icon } from '../../assets/img/forward2.svg';
import SelectMenu from '../../common/FormInputs/SelectMenu';
import { PERPAGE } from '../../global-constants';
import PropTypes from 'prop-types';
import { CommonLabel } from '../../common/FormInputs/Common';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import styled from 'styled-components';

const PaginationComponent = ({ handleChangeFn, handleItemPerPageFn, count = 10, page, perPage }) => {
    const [selectedPerPage, setSelectedPerPage] = useState(PERPAGE[0]);

    useEffect(() => {
        if (perPage) {
            setSelectedPerPage(perPage);
        }
    }, [perPage]);

    const changePerPage = event => {
        const selectedVal = event.target.value;
        setSelectedPerPage(selectedVal);
        handleItemPerPageFn(selectedVal);
    };

    return (
        <Box sx={{ margin: '2vh 0 4.31vh 0px' }} data-testid="pagination">
            <FlexEnd>
                <FlexRow>
                    <Pagination
                        count={count}
                        showFirstButton
                        showLastButton
                        onChange={handleChangeFn}
                        page={page}
                        size="small"
                        renderItem={item => (
                            <PaginationItem
                                slots={{
                                    first: Backward2Icon,
                                    previous: BackwardIcon,
                                    next: ForwardIcon,
                                    last: Forward2Icon,
                                }}
                                {...item}
                                sx={{
                                    fontSize: `${pxToVh(14)}vh`,
                                    fontWeight: 400,
                                    lineHeight: `${pxToVh(20)}vh`,
                                    color: colorsCode.Neutral80,
                                    padding: `${pxToVh(3)}vh ${pxToVw(10)}vw`,
                                    '&.Mui-selected': {
                                        color: colorsCode.TextHeading,
                                        backgroundColor: colorsCode.cyan100,
                                        '&:hover': {
                                            background: colorsCode.cyan100,
                                        },
                                    },
                                    '&:hover': {
                                        background: colorsCode.white,
                                    },
                                }}
                            />
                        )}
                    />
                    <Separator />
                    <CommonLabel
                        textWeight={400}
                        textColor={colorsCode.Neutral80}
                        textSize={12}
                        lineHeight={16}
                        label="Results per page"
                    />
                    <Box>
                        <SelectMenu
                            controlWidth={true}
                            menuItems={PERPAGE}
                            onChangeFn={changePerPage}
                            value={selectedPerPage}
                            data-testid="select"
                        />
                    </Box>
                </FlexRow>
            </FlexEnd>
        </Box>
    );
};

PaginationComponent.defaultProps = {
    page: undefined,
};

PaginationComponent.propTypes = {
    handleChangeFn: PropTypes.func.isRequired,
    handleItemPerPageFn: PropTypes.func.isRequired,
    count: PropTypes.number.isRequired,
    page: PropTypes.number,
};
const Separator = styled.div`
    width: ${pxToVw(1)}vw;
    height: ${pxToVh(20)}vh;
    background: ${colorsCode.Neutral30};
`;
export default PaginationComponent;
