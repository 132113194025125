/* istanbul ignore file */
import { makeStyles } from '@mui/styles';
import { colorsCode, pxToVh, pxToVw } from '../colors';

export const commonThemes = makeStyles(() => {
    return {
        buttonGroup: {
            // width: '6.5vw',
            // height: '4.2vh',
            padding: `${pxToVh(6)}vh ${pxToVw(16)}vw`,
            // fontSize: '1.4vh !important',
            // fontWeight: '400 !important'
            textTransform: 'initial !important',
        },
        selectLabel: {
            margin: '10px 0 !important',
            fontSize: '2.30vh !important',
            color: '#333333 !important',
        },
        buttonActive: {
            color: 'black !important',
            border: `1px solid ${colorsCode.Neutral70} !important`,
        },
        buttonBorderInact: {
            borderColor: `${colorsCode.Neutral30} !important`,
            color: '#B3B3B3 !important',
            '&:last-child': {
                borderLeft: '0px !important',
                marginLeft: '0px !important',
            },
        },
        selectClass: {
            height: '3.6vh !important',
            border: '1px solid #B3B3B3 !important',
            minWidth: '20vw !important',
            width: '100% !important',
            borderRadius: '2px !important',
            padding: '1vh 1.11vw !important',
        },
        searchClass: {
            height: '4vh !important',
            borderColor: '1px solid #B3B3B3 !important',
            maxWidth: '20vw !important',
            width: '13.88vw !important',
            borderRadius: '22px !important',
        },
        searchClassFullWidth: {
            height: '4vh !important',
            width: '77vw !important',
            borderRadius: '22px !important',
        },
        optionsSearch: {
            width: '58vw !important',
            height: '4vh !important',
            borderRadius: '22px !important',
        },
        pageSelectClass: {
            width: '6vw !important',
            height: '3.74vh !important',
            padding: '0.5vh 1vw  0 1vw !important',
        },
        dateRangePicker: {
            '& .react-daterange-picker__wrapper': {
                width: '17vw !important',
                height: '4.60vh !important',
                padding: '0.7vh 0 0.7vh 0.7vh !important',
                border: '1px solid #B3B3B3 !important',
                borderRadius: '2px !important',
                fontSize: '1.87vh !important',
            },
            '& .mds-range-picker-input-focused': {
                borderBottom: `4px solid ${colorsCode.PrimaryDeepBlue} !important`,
            },
            '& .react-daterange-picker__range-divider': {
                marginTop: '0.7vh',
                marginRight: '0.7vh',
            },
            '& .react-daterange-picker__inputGroup': {
                padding: '0 2px',
            },
            '& .react-datepicker__input-container': {
                '& > div': {
                    borderColor: colorsCode.Neutral30,

                    '&:hover': {
                        border: `1px solid ${colorsCode.Neutral80}`,
                    },
                },
                '& svg': {
                    filter: 'grayscale(3.20)',
                },
            },
            '& .react-datepicker-ignore-onclickoutside': {
                borderColor: `${colorsCode.Neutral80} !important`,
            },
        },
        rowClass: {
            padding: 0,
            overflow: 'hidden',
        },
        required: {
            color: 'red',
        },
        dialogContext: {
            '&': {
                fontSize: '2.26vh !important',
                lineHeight: '3.4vh !important',
            },
        },
        filterModalTitle: {
            fontWeight: '300 !important',
            fontSize: '2.62vh !important',
            color: '#000',
        },
        filterModalClearTitle: {
            fontWeight: '400 !important',
            fontSize: '1.75vh !important',
            color: '#2B5580',
            textTransform: 'uppercase',
            cursor: 'pointer',
            letterSpacing: '0.6px',
        },
        clearTitleDisabled: {
            color: `${colorsCode.Neutral30} !important`,
            pointerEvents: 'none',
        },
        ContentWrapper: {
            '& .MuiDialogContent-root': {
                padding: '0 !important',
            },
            '& .MuiAccordionSummary-root': {
                minHeight: '4.37vh',
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
                // height: '7.3vh',
                borderBottom: '1px solid #d8d8d8',
            },
            '& .MuiAccordionDetails-root': {
                padding: '0.7vh 1.66vw 0 1.66vw',
            },
            '& .MuiAccordionSummary-content': {
                paddingLeft: '0.69vw',
            },
            // '& .MuiAccordionSummary-expandIconWrapper': {
            //     paddingRight: '0.69vw',
            // },
            '& .MuiCollapse-wrapper': {
                marginBottom: '1.45vh',
            },
        },
        filterOptionsContainer: {
            maxHeight: `${pxToVh(200)}vh`,
            overflowY: 'auto',
        },
        accordianTitle: {
            borderTop: '1px solid #ccc',
            '& .MuiPaper-root': {
                boxShadow: 'none',
                minWidth: '21.38vw',
            },
        },
        countDiv: {
            background: '#E6F2F9',
            width: '3.50vh',
            height: '2.91vh',
            borderRadius: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        countLabel: {
            color: '#051C2C',
            fontSize: '1.75vh !important',
        },
        noRecordBox: {
            background: '#FFEFD1 !important',
        },
        noRecord: {
            textAlign: 'center',
            fontSize: '2.62vh !important',
            fontWeight: '500 !important',
        },
        formControlLabel: {
            color: `${colorsCode.Neutral80}`,
            fontSize: `${pxToVh(14)}vh !important`,
            lineHeight: `${pxToVh(20)}vh !important`,
            padding: `${pxToVh(10)}vh ${pxToVh(16)}vw !important`,

            '&.isDisabled': {
                color: `${colorsCode.Neutral30}`,
            },
        },
    };
});
