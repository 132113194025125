import styled from 'styled-components';
import { FlexCenterWithAlign } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled(FlexCenterWithAlign)`
    padding: ${({ padding }) => padding || `${pxToVh(16)}vh ${pxToVw(16)}vw`};
    background-color: ${({ backgroundColor }) => backgroundColor || colorsCode.white};
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.02), 0px 2px 4px -1px rgba(5, 28, 44, 0.04);
    height: ${({ height }) => height || `${pxToVh(134)}vh`};
    width: ${({ width }) => width || `${pxToVw(216)}vw`};
    border: 1px solid ${colorsCode.Neutral10};
    border-radius: 8px;
`;

export const Icon = styled.img`
    max-height: ${pxToVh(60)}vh;
    max-width: ${pxToVw(60)}vw;
`;
