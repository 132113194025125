import { Dialog, DialogActions, DialogContent, IconButton, Radio } from '@mui/material';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CommonLabel, Img } from '../../../../common/FormInputs/Common';
import CloseIcon from '../../../../assets/img/close.svg';
import { CTA, FlexColumnGap, FlexStartGap } from '../../../../common/styled/styled';
import React from 'react';
import radiobutton from '../../../../assets/img/radiobutton.svg';
import LightTooltip from '../../../../common/LightTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { DownloadMonitorFiles } from '../../../../store/actions/Surveys/action';
import { useDispatch } from 'react-redux';

export const DataExportModal = ({ dataExportModal, closeDataExport }) => {
    const dispatch = useDispatch();
    const [selectedOption, setSelectedOption] = React.useState('');

    const optionHandler = e => {
        setSelectedOption(e.target.id);
    };

    const closeHandler = () => {
        closeDataExport();
        setSelectedOption('');
    };

    const exportHandler = () => {
        if (selectedOption === 'CODED' || selectedOption === 'LABELED') {
            dispatch(DownloadMonitorFiles({ surveyId: dataExportModal?.uuid, slug: selectedOption }));
            closeHandler();
        }
    };
    return (
        <Dialog
            open={dataExportModal.open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                    width: `${pxToVw(580)}vw`,
                    borderRadius: '8px',
                },
            }}
        >
            <DialogContent
                sx={{
                    p: `${pxToVh(30)}vh ${pxToVh(30)}vw 0vh ${pxToVh(30)}vw`,
                }}
            >
                <IconButton
                    aria-label="close"
                    onClick={closeHandler}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <Img image={CloseIcon} h={pxToVh(24)} w={pxToVw(24)} alt="close" cursor="pointer" />
                </IconButton>
                <FlexColumnGap gap={pxToVh(40)}>
                    <CommonLabel
                        textWeight={500}
                        textSize={24}
                        textColor={colorsCode.TextHeading}
                        lineHeight={36}
                        label="Data Export"
                    />
                    <FlexColumnGap gap={pxToVh(40)}>
                        <CommonLabel
                            textWeight={400}
                            textSize={16}
                            textColor={colorsCode.TextHeading}
                            lineHeight={24}
                            label="The export will be in the Excel (xls) format"
                        />
                        <FlexColumnGap gap={pxToVh(8)}>
                            <CommonLabel
                                textWeight={400}
                                textSize={16}
                                textColor={colorsCode.Neutral30}
                                lineHeight={24}
                                label="Survey ID"
                            />
                            <FlexColumnGap gap={pxToVh(24)}>
                                <CommonLabel
                                    textWeight={400}
                                    textSize={16}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={24}
                                    customStyles={{
                                        textTransform: 'uppercase',
                                    }}
                                    label={dataExportModal.surveyId}
                                />
                                <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                    <Radio
                                        sx={{ height: 0, width: 0 }}
                                        onClick={e => optionHandler(e)}
                                        id="CODED"
                                        checked={selectedOption === 'CODED'}
                                        icon={
                                            <img
                                                src={radiobutton}
                                                style={{ height: `${pxToVh(20)}vh`, width: `${pxToVw(20)}vw` }}
                                            />
                                        }
                                    />
                                    <CommonLabel
                                        textWeight={400}
                                        textSize={18}
                                        textColor={colorsCode.Neutral80}
                                        lineHeight={28}
                                        label="Data Files (Codes)"
                                    />
                                    <LightTooltip
                                        title={'Excel data file with question IDs and answer codes'}
                                        placement="top"
                                        arrow
                                    >
                                        <InfoIcon
                                            sx={{
                                                color: colorsCode.Neutral30,
                                                width: '16px',
                                                height: '16px',
                                            }}
                                        />
                                    </LightTooltip>
                                </FlexStartGap>
                                <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                    <Radio
                                        sx={{ height: 0, width: 0 }}
                                        onClick={e => optionHandler(e)}
                                        id="LABELED"
                                        checked={selectedOption === 'LABELED'}
                                        icon={
                                            <img
                                                src={radiobutton}
                                                style={{ height: `${pxToVh(20)}vh`, width: `${pxToVw(20)}vw` }}
                                            />
                                        }
                                    />
                                    <CommonLabel
                                        textWeight={400}
                                        textSize={18}
                                        textColor={colorsCode.Neutral80}
                                        lineHeight={28}
                                        label="Data Files (Labels)"
                                    />
                                    <LightTooltip
                                        title={'Excel data file with question text and answer labels'}
                                        placement="top"
                                        arrow
                                    >
                                        <InfoIcon
                                            sx={{
                                                color: colorsCode.Neutral30,
                                                width: '16px',
                                                height: '16px',
                                            }}
                                        />
                                    </LightTooltip>
                                </FlexStartGap>
                            </FlexColumnGap>
                        </FlexColumnGap>
                    </FlexColumnGap>
                </FlexColumnGap>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', mb: `${pxToVh(35)}vh`, mt: `${pxToVh(40)}vh` }}>
                <FlexStartGap gap={pxToVw(24)}>
                    <CTA
                        size={16}
                        fw={400}
                        lh={24}
                        color={colorsCode.PrimaryDeepBlue}
                        bg={colorsCode.white}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={closeHandler}
                    >
                        Cancel
                    </CTA>
                    <CTA
                        size={16}
                        fw={400}
                        lh={24}
                        color={colorsCode.white}
                        bg={colorsCode.PrimaryDeepBlue}
                        onClick={exportHandler}
                        disabled={selectedOption === ''}
                    >
                        Export
                    </CTA>
                </FlexStartGap>
            </DialogActions>
        </Dialog>
    );
};
