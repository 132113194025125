import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React from 'react';
import styled from 'styled-components';
import { pxToVh, pxToVw } from './colors';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} PopperProps={{ style: { zIndex: 9999 } }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        '&:before': {
            color: '#fff',
            boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
        },
        color: '#fff',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#fff',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
        fontSize: `${pxToVh(12)}vh`,
        fontWeight: 300,
        padding: '9px',
        maxWidth: `${pxToVw(250)}vw`,
    },
}));

export default LightTooltip;
