import React, { Fragment, useEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import SearchIcon from '../../assets/img/search.svg';
import { commonThemes } from '../themes';
import { colorsCode, pxToVh, pxToVw } from '../colors';

const SearchBar = ({ placeholder, id, onChangeFn, val, customClass, callApi = false, tab }) => {
    const styles = commonThemes();
    const [searchVal, setSearch] = useState(val);

    useEffect(() => {
        /* This is done to reset the search to empty string as soon as user changes tab
        i.e. to get the same behaviour as what we get on component unmount.
        If setting searchVal to val in useEffect, the code behaves unexpected because of debouncing
        on searchVal change
        */
        setSearch(val);
    }, [tab]);

    const search = e => {
        const {
            target: { value },
        } = e;
        setSearch(value);
        onChangeFn({
            target: {
                value,
                id,
            },
        });
    };

    return (
        <Fragment>
            <TextField
                autoComplete="off"
                id={id}
                placeholder={placeholder}
                onChange={callApi ? search : onChangeFn}
                value={callApi ? searchVal || '' : val || ''}
                InputProps={{
                    'data-testid': 'search',
                    endAdornment: (
                        <InputAdornment position="end">
                            <img
                                src={SearchIcon}
                                alt="Search"
                                style={{
                                    height: `${pxToVh(16)}vh`,
                                    width: `${pxToVw(16)}vw`,
                                }}
                            />
                        </InputAdornment>
                    ),
                    classes: {
                        root: customClass || styles.searchClass,
                    },
                }}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            border: `1px solid ${colorsCode.Neutral80}`,
                        },
                        '&::hover': {
                            border: `1px solid ${colorsCode.Neutral80}`,
                        },
                        fieldset: {
                            border: '1px solid #B3B3B3',
                        },
                    },
                }}
            />
        </Fragment>
    );
};

export default SearchBar;
