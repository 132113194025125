import { Checkbox, FormControlLabel, FormHelperText, MenuItem, Select, ListSubheader } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import ArrowDownIcon from '../../assets/img/arrow_down.svg';
import { colorsCode, pxToVh, pxToVw } from '../colors';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { FormControl } from '@mui/base';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckboxSvg from '../../assets/img/Checkbox.svg';
import { FlexStartGap } from '../styled/styled';

const DropdownMenu = ({
    menuItems,
    value,
    onChangeFn,
    disabled = false,
    width,
    name,
    multiple = false,
    onClickMultiple = () => {},
    error = false,
    message = '',
    height,
    maxHeight,
    grouped = false,
    categories = [],
    defaultValue,
    placeholder = '',
    menuItemRender,
    customStyles,
    isMultiple = false,
    fullWidth = false,
}) => {
    const style = {
        width: fullWidth ? '100%' : `${width}vw`,
        height: `${height || 5.65}vh`,
        borderRadius: '2px',
        fontWeight: 400,
        lineHeight: `${pxToVh(24)}vh`,
        fontSize: `${pxToVh(16)}vh`,
        color: value ? colorsCode.Neutral80 : colorsCode.Neutral30,
        '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: colorsCode.Neutral30,
        },
        ...customStyles,
    };

    const getGroupedData = () => {
        const getCategoryData = category => {
            const items = category?.sub_industries?.map(subcategory => {
                return (
                    <MenuItemStyled key={subcategory.uuid} value={subcategory.uuid}>
                        {subcategory.name}
                    </MenuItemStyled>
                );
            });

            return [<ListSubheaderStyled key={category.uuid}>{category.name}</ListSubheaderStyled>, items];
        };
        return categories.map(category => getCategoryData(category));
    };

    const renderValue = menuItemRender ? value => menuItems.find(({ uuid }) => uuid === value)?.name : undefined;

    return (
        <FormControl>
            <SelectStyled
                multiple={isMultiple}
                id={name}
                value={value}
                displayEmpty={true}
                onChange={onChangeFn}
                disabled={disabled}
                name={name}
                inputProps={{
                    'data-testid': 'dropdown',
                }}
                IconComponent={props => <img src={ArrowDownIcon} {...props} alt="dropdown" />}
                sx={style}
                error={error}
                isGrouped={grouped}
                defaultValue={defaultValue}
                renderValue={
                    isMultiple || (!value && placeholder)
                        ? () => <span className="dropdown-placeholder">{placeholder}</span>
                        : renderValue
                }
                MenuProps={{
                    sx: {
                        maxHeight,
                    },
                }}
            >
                {grouped
                    ? getGroupedData()
                    : menuItems &&
                      menuItems.map((item, index) => {
                          const { uuid, name, is_selected = false, ...rest } = item;
                          if (multiple) {
                              return (
                                  <MenuItem key={uuid} value={uuid}>
                                      <FormControlLabel
                                          style={{ width: '100%' }}
                                          label={name}
                                          control={
                                              <Checkbox
                                                  value={uuid}
                                                  checked={is_selected}
                                                  onChange={e => onClickMultiple(e, index)}
                                                  sx={{
                                                      height: 0,
                                                      width: 0,
                                                      margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                                  }}
                                                  icon={
                                                      <img
                                                          src={CheckboxSvg}
                                                          className="img"
                                                          style={{
                                                              width: `${pxToVw(18)}vw`,
                                                              height: `${pxToVh(18)}vh`,
                                                          }}
                                                      />
                                                  }
                                                  checkedIcon={
                                                      <CheckBoxIcon sx={{ color: colorsCode.PrimaryDeepBlue }} />
                                                  }
                                              />
                                          }
                                      />
                                  </MenuItem>
                              );
                          }
                          return (
                              <MenuItem
                                  key={uuid}
                                  value={uuid}
                                  originalValue={item}
                                  sx={{ fontSize: `${pxToVh(16)}vh` }}
                              >
                                  {menuItemRender ? menuItemRender({ uuid, name, ...rest }) : name}
                              </MenuItem>
                          );
                      })}
            </SelectStyled>
            <FormHelperText sx={{ color: colorsCode.error }}>
                {message ? (
                    <FlexStartGap gap={pxToVw(0.5)}>
                        <WarningAmberOutlinedIcon
                            sx={{ color: colorsCode.error, height: `${pxToVh(16)}vh`, width: `${pxToVw(16)}vw` }}
                        />
                        {message}
                    </FlexStartGap>
                ) : (
                    ''
                )}
            </FormHelperText>
        </FormControl>
    );
};

export const SelectStyled = styled(Select)`
    .dropdown-placeholder {
        font-size: ${pxToVh(16)}vh;
        line-height: ${pxToVh(24)}vh;
        font-weight: 400;
        color: ${colorsCode.Neutral30};
    }

    && .MuiSelect-select {
        padding: 16.5px ${pxToVw(16)}vw;
    }

    && .MuiSelect-icon {
        right: ${pxToVw(16)}vw;
    }
`;

const MenuItemStyled = styled(MenuItem)`
    && {
        font-weight: 300;
    }
`;

const ListSubheaderStyled = styled(ListSubheader)`
    && {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 500;
        color: ${colorsCode.Neutral80};
        padding: ${pxToVh(4)}vh 16px;
    }
`;

export default DropdownMenu;
