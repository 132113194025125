import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentModal from '../ContentModal';

const VideoModal = ({ open, onClose, url, isImage }) => {
    return (
        <ContentModal open={open} onClose={onClose}>
            {!isImage ? <Video src={url} controls autoPlay playsInline /> : <Image src={url} alt="modal-image" />}
        </ContentModal>
    );
};

export const Video = styled.video`
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    border-radius: 4px;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    border-radius: 4px;
`;

VideoModal.defaultProps = {
    open: false,
    onClose: () => {},
    url: null,
    isImage: false,
};

VideoModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    url: PropTypes.string,
    isImage: PropTypes.bool,
};

export default VideoModal;
