import React, { useRef, useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import Text from '../../../components/KnowledgeCenter/Text';
import ObjectiveComponent from '../../../components/KnowledgeCenter/ObjectiveComponent';
import { Section, FlexColumnWithAlign } from '../../../common/styled/styled';
import SurveySetupOverview from './SurveySetupOverview';
import SurveyComponentsDesign from './SurveyComponentsDesign';
import { SETUP_SURVEY_TEXT, SECTION_KEYS } from '../../../utils/kc-constants';
import {
    SETUP_SURVEY_OBJECTIVES,
    SETUP_SURVEY_OVERVIEW_DATA,
    SETUP_SURVEY_ADMINISTRATION_DATA,
    SETUP_SURVEY_TASK_CHECKLIST,
    SURVEY_COMPONENTS_DESIGN_DATA,
    COMMUNICATIONS_DATA,
    IT_TESTING_INFO_DATA,
    IT_SECURITY_DEF_DATA,
    IT_STEP_ACTIONS,
} from '../../../utils/kc-data';
import Communications from './Communications';
import ITTesting from './ITTesting';
import { AppContext } from '../Home';
import { useIntersection } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const SetupSurveyPage = () => {
    const refs = useRef([]);
    const location = useLocation();
    const { setActiveSection, activeSection } = useContext(AppContext);
    const isVisible = useIntersection(refs);
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        if (isVisible) {
            setActiveSection(isVisible);
        }
    }, [isVisible, setActiveSection]);

    useEffect(() => {
        if (!activeSection && !params.get('section')) {
            setActiveSection(SECTION_KEYS.SETUP_SURVEY_LOGISTICS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection, setActiveSection]);

    return (
        <Section gap={pxToVh(60)} padding={`${pxToVh(70)}vh ${pxToVw(58)}vw ${pxToVh(104)}vh ${pxToVw(61)}vw`}>
            <FlexColumnWithAlign gap={pxToVh(17)}>
                <FlexColumnWithAlign gap={pxToVh(12)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={44}
                        lineHeight={52}
                        label={SETUP_SURVEY_TEXT.SETUP_SURVEY_HEADING}
                        fontWeight={500}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={24}
                        lineHeight={36}
                        label={SETUP_SURVEY_TEXT.SETUP_SURVEY_DESCRIPTION}
                    />
                </FlexColumnWithAlign>
                <ObjectiveComponent data={SETUP_SURVEY_OBJECTIVES} />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign
                ref={element => (refs.current[0] = element)}
                id={SECTION_KEYS.SETUP_SURVEY_LOGISTICS}
                margin={`-${pxToVh(31)}vh 0 0 0`}
            >
                <SurveySetupOverview
                    overviewData={SETUP_SURVEY_OVERVIEW_DATA}
                    administrationData={SETUP_SURVEY_ADMINISTRATION_DATA}
                    taskChecklistData={SETUP_SURVEY_TASK_CHECKLIST}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign
                ref={element => (refs.current[1] = element)}
                id={SECTION_KEYS.SETUP_SURVEY_COMPONENTS_DESIGN}
            >
                <SurveyComponentsDesign componentsDesignData={SURVEY_COMPONENTS_DESIGN_DATA} />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign
                ref={element => (refs.current[2] = element)}
                id={SECTION_KEYS.SETUP_SURVEY_COMMUNICATION}
            >
                <Communications communicationData={COMMUNICATIONS_DATA} />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => (refs.current[3] = element)} id={SECTION_KEYS.SETUP_SURVEY_IT_TESTING}>
                <ITTesting
                    infoData={IT_TESTING_INFO_DATA}
                    definitionsData={IT_SECURITY_DEF_DATA}
                    stepActions={IT_STEP_ACTIONS}
                />
            </FlexColumnWithAlign>
        </Section>
    );
};

SetupSurveyPage.defaultProps = {};

SetupSurveyPage.propTypes = {};

export default SetupSurveyPage;
