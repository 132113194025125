import React from 'react';
import { Typography } from '@mui/material';
import { commonThemes } from '../themes';

const CountLabel = ({ count }) => {
    const styles = commonThemes();
    return (
        <div className={styles.countDiv}>
            <Typography className={styles.countLabel}>{count}</Typography>
        </div>
    );
};

export default CountLabel;
