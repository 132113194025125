import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { CONFIG } from '../../../global-constants';
import { Endpoints } from '../../../http/Endpoints';

export const PermissionAction = createAsyncThunk(
    '/api/users/user-permissions',
    async (requestParams, { rejectWithValue }) => {
        try {
            const url = `${CONFIG.BASE_PATH_APP}${Endpoints.permissionUrl}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: requestParams,
                },
            });
            const { data } = response;
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const Roles = createAsyncThunk('/api/roles', async (requestParams, { rejectWithValue }) => {
    try {
        const url = `${CONFIG.BASE_PATH_APP}${Endpoints.roles
            .replace('__page__', requestParams.page)
            .replace('__limit__', requestParams.limit)}`;
        const response = await axios.get(url, {
            headers: {
                Authorization: requestParams.token,
            },
        });
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error);
    }
});
