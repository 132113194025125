import styled from 'styled-components';
import { FlexRow, FlexColumnWithAlign } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const Container = styled(FlexRow)`
    padding: ${pxToVh(64)}vh ${pxToVw(24)}vw;
    background-color: ${colorsCode.footerColor};
    width: 100%;
    justify-content: center;
    align-items: flex-start;

    img {
        width: ${pxToVw(32)}vw;
        height: ${pxToVw(32)}vw;
    }
`;

export const TextContainer = styled(FlexColumnWithAlign)`
    max-width: ${pxToVw(243)}vw;
    margin-top: -0.5vh;
`;
