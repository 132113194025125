import React, { Fragment } from 'react';
import { SingleDatePicker, ThemeProvider } from '@mds/mds-reactjs-library';
import styled from '@emotion/styled';
import { colorsCode, pxToVh, pxToVw } from './colors';
import moment from 'moment';
import { convertDateToTimestamp } from '../utils/functions';
import { CommonLabel } from './FormInputs/Common';
import { FlexCenterWithAlign } from './styled/styled';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const DatePickerComponent = ({ setDate, date, width = 418 }) => {
    const onChange = e => {
        setDate(e);
    };
    const isValid = moment().isAfter(date);
    const customDatePickerTheme = {
        forms: {
            datePicker: {
                font: { size: { default: '0.8rem', month: '1rem' } },
                color: {
                    background: {
                        default: '#ffffff',
                        selected: colorsCode.PrimaryDeepBlue,
                        'in-range': '#E6F2F9',
                        hover: '#E6F2F9',
                        'selected-hover': colorsCode.PrimaryDeepBlue,
                    },
                },
            },
        },
    };

    return (
        <Fragment>
            <ThemeProvider customTheme={customDatePickerTheme}>
                <SingleDateDiv
                    width={pxToVw(width)}
                    startDate={date}
                    onChange={onChange}
                    placeholderText="DD MMM YYYY"
                    hasError={isValid}
                    value={moment(convertDateToTimestamp(date)).format('DD MMM YYYY')}
                />
                {isValid ? (
                    <FlexCenterWithAlign gap={pxToVw(4)}>
                        <WarningAmberOutlinedIcon
                            sx={{ color: colorsCode.error, height: `${pxToVh(16)}vh`, width: `${pxToVw(16)}vw` }}
                        />
                        <CommonLabel
                            textSize={12}
                            textColor={colorsCode.rejected}
                            textWeight={400}
                            lineHeight={16}
                            label="Date should be greater than today's date."
                        />
                    </FlexCenterWithAlign>
                ) : null}
            </ThemeProvider>
        </Fragment>
    );
};

const SingleDateDiv = styled(SingleDatePicker)`
    width: ${({ width }) => width}vw;
    height: ${pxToVh(40)}vh !important;
    input {
        font-size: ${pxToVh(16)}vh !important;
    }
    .mds-medium {
        border-radius: 2px;
        border-color: ${({ hasError }) => (hasError ? colorsCode.rejected : colorsCode.Neutral30)} !important;
        height: ${pxToVh(40)}vh !important;
    }
`;

export default DatePickerComponent;
