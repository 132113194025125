import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import {
    GanttTimeTableContainer,
    GanttTimePeriodHeader,
    GanttTimePeriodHeaderCell,
    GanttTimePeriodCell,
    GanttTimePeriodRow,
    StyledAccordionSummary,
    StyledAccordionDetails,
    StyledAccordion,
    GanttTimePeriodDataContainer,
} from './styles';
import { colorsCode, pxToVh } from '../../../common/colors';

const activityDuration = {
    position: 'absolute',
    height: `calc(${pxToVh(14)}vh - 1px)`,
    zIndex: 1,
    background: colorsCode.TextSubtle,
    borderRadius: '27px',
    top: '50%',
    transition: 'width 300ms',
    transform: 'translateY(-50%)',
};

const TimeTable = ({ activities, activitiesDuration, collapsedItems }) => {
    const numMonths = 1;
    const numWeeks = 6;
    const weekDays = 7;
    const [intersection, setIntersection] = useState({});

    useEffect(() => {
        if (activitiesDuration?.length) {
            const sections_data = {};
            const _intersection = {};
            activitiesDuration.forEach((item, index) => {
                if (!sections_data[item.key]) {
                    sections_data[item.key] = [item.endDate, index];
                } else if (sections_data[item.key][0] === item.startDate - 1) {
                    if (!_intersection[item.key]) {
                        _intersection[item.key] = [
                            {
                                left: sections_data[item.key][1],
                                right: index,
                            },
                        ];
                    } else {
                        _intersection[item.key].push({
                            left: sections_data[item.key][1],
                            right: index,
                        });
                    }
                    sections_data[item.key] = [item.endDate, index];
                } else {
                    sections_data[item.key] = [item.endDate, index];
                }
            });

            setIntersection(_intersection);
        }
    }, [activitiesDuration]);

    const populateWeekAndDayHeader = () => {
        const weekRow = [];
        for (let i = 0; i < numWeeks; i++) {
            // create week cells
            weekRow.push(
                <GanttTimePeriodHeaderCell key={i}>
                    <Text fontColor={colorsCode.TextHeading} fontSize={16} lineHeight={24} label={`Week ${i + 1}`} />
                </GanttTimePeriodHeaderCell>
            );
        }

        return (
            <Fragment>
                <GanttTimePeriodHeader>{weekRow}</GanttTimePeriodHeader>
            </Fragment>
        );
    };

    // create data cells
    const populateDataCells = (item, activity) => {
        const activityRow = [];
        for (let j = 1; j <= weekDays * numWeeks; j++) {
            activityRow.push(
                <GanttTimePeriodCell
                    key={`${item.key}-${j}`}
                    data-task={item?.key}
                    isLastDayCell={j % 7 === 0}
                    borderColor={activity?.border_color}
                >
                    {activitiesDuration.map((el, index) => {
                        const width = el?.endDate - el?.startDate === 0 ? 0.5 : el?.endDate - el?.startDate + 1;
                        const isIntersectedData = intersection?.[el.key]?.find(val => val?.right === index);
                        const isIntersectedDataSibling = intersection?.[el.key]?.find(val => val?.left === index);
                        if (el?.key === item?.key && el?.startDate === j) {
                            return (
                                <div
                                    key={`${index}-${el?.key}`}
                                    tabIndex="0"
                                    style={{
                                        ...activityDuration,
                                        background: el?.color,
                                        width: isIntersectedDataSibling
                                            ? `calc(${width} * 100%)`
                                            : `calc(${width} * 100% - 1px)`,
                                        right: el?.endDate - el?.startDate === 0 ? 0 : null,
                                        borderTopLeftRadius: isIntersectedData ? 0 : '27px',
                                        borderBottomLeftRadius: isIntersectedData ? 0 : '27px',
                                        borderTopRightRadius: isIntersectedDataSibling ? 0 : '27px',
                                        borderBottomRightRadius: isIntersectedDataSibling ? 0 : '27px',
                                    }}
                                ></div>
                            );
                        }
                    })}
                </GanttTimePeriodCell>
            );
        }

        return activityRow;
    };

    // create data rows
    const populateDataRow = (options, activity) => {
        const activityRows = [];
        {
            options.forEach(item => {
                const activityRow = populateDataCells(item, activity);
                activityRows.push(<GanttTimePeriodRow key={`${item?.key}`}>{activityRow}</GanttTimePeriodRow>);
            });
        }
        return activityRows;
    };

    // create activity grid
    const populateData = () => {
        return activities?.map((activity, index) => {
            return (
                <Fragment key={index}>
                    <StyledAccordion
                        expanded={!collapsedItems.includes(index)}
                        borderColor={activity?.border_color}
                        style={{
                            background: activity?.bg_color,
                            borderTop: activity?.bg_color ? `1px solid ${colorsCode.Neutral10}` : null,
                        }}
                    >
                        <StyledAccordionSummary>
                            <GanttTimePeriodRow key={`${activity?.key}`} isAccordianCell>
                                {populateDataCells(activity, activity)}
                            </GanttTimePeriodRow>
                        </StyledAccordionSummary>
                        <StyledAccordionDetails>{populateDataRow(activity.options, activity)}</StyledAccordionDetails>
                    </StyledAccordion>
                    {index + 1 < activities.length ? (
                        <GanttTimePeriodRow key={`${activity?.key}`} isGapRow>
                            {populateDataCells(activity, activity)}
                        </GanttTimePeriodRow>
                    ) : null}
                </Fragment>
            );
        });
    };

    return (
        <GanttTimeTableContainer style={{ gridTemplateColumns: `repeat(${numMonths}, 1fr)` }}>
            {populateWeekAndDayHeader()}
            <GanttTimePeriodDataContainer
                id="gantt-time-period-cell-container"
                style={{
                    gridTemplateColumns: `repeat(${numMonths}, 1fr)`,
                }}
            >
                {populateData()}
            </GanttTimePeriodDataContainer>
        </GanttTimeTableContainer>
    );
};

TimeTable.defaultProps = {
    activities: [],
    activitiesDuration: [],
    collapsedItems: [],
};

TimeTable.propTypes = {
    activities: PropTypes.arrayOf(
        PropTypes.shape({
            options: PropTypes.arrayOf(PropTypes.shape({})),
        })
    ),
    activitiesDuration: PropTypes.arrayOf(PropTypes.shape({})),
    collapsedItems: PropTypes.bool,
};

export default TimeTable;
