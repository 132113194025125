/* istanbul ignore file */
export const colorsCode = {
    Neutral70: '#4D4D4D',
    Neutral80: '#333333',
    PrimaryDeepBlue: '#051C2C',
    SecondaryAmber100: '#FFEFD1',
    secondaryAmber500: '#FFA800',
    FeedbackLightSuccess: '#DEFAE6',
    white: '#FFFFFF',
    SecondaryAmber300: '#FFCD75',
    Neutral10: '#E6E6E6',
    Neutral30: '#B3B3B3',
    NeutralWhite: '#FFFFFF',
    SecondaryDeepBlue500: '#2B5580',
    TextHeading: '#000000',
    TextSubtle: '#757575',
    primaryCyan: '#0679C3',
    cyan100: '#E6F2F9',
    chipColor: '#E6F2F9',
    neutral02: '#F8F8F8',
    SecondaryDeepBlue: '#82A6C9',
    whiteShade: '#ffffffb3',
    cyan: '#0679C3',
    Orange: '#FDE0D6',
    SecondaryDeepBlue2: '#1B456E',
    SecondaryAmber50: '#FFF9EF',
    Neutral05: '#F2F2F2',
    warning: '#F3C233',
    error: '#d32f2f',
    rejected: '#D51F31',
    pending: '#C58200',
    approved: '#007F26',
    backgroundDisabled: '#CCCCCC',
    primaryAmber: '#FFBB48',
    neutral05: '#F2F2F2',
    danger: '#FDE7E7',
    pink200: '#FFBBCD',
    pink400: '#FF7A9F',
    axesBorder: '#EAEAEA',
    neutral60: '#656565',
    textInverse: '#DBDBDB',
    neutralGray700: '#3D3D3D',
    SecondaryDeepBlue200: '#82A6C9',
    secondaryAmber600: '#EB9B00',
    boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
    lightCyan: '#e6f2f9',
    pendingbg: '#fff3ce',
    rejectedbg: '#fde7e7',
    approvedbg: '#defae6',
    deepBlue400: '#386694',
    black200: '#082644',
    blue700: '#103559',
    footerColor: '#F4F4F4',
    feedbackLightNeutral: '#ECF1F2',
    noteBackgroundColor: '#D9D9D9',
    deepBlue100: '#C1D3E4',
    blueShade1: '#B1DDF4',
    blueShade2: '#6ECBF7',
    blueShade3: '#34B4F4',
    blueShade4: '#0291DC',
    blueShade5: '#0679C3',
    neutral20: '#CCC',
    yellow: '#FFE4B3',
    purple: '#7C3FC4',
    secondaryAmber900: '#B37600',
    logoBgColorActive: '#E63E62',
};

export const pxToVh = val => {
    return (val * 0.14).toFixed(2);
};
export const pxToVw = val => {
    return (val * 0.07).toFixed(2);
};
