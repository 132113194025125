import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Dialog, IconButton, Slide } from '@mui/material';
import { ReactComponent as RemoveIcon } from '../../assets/img/remove-light.svg';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ContentModal = ({
    open,
    onClose,
    children,
    fullScreen,
    closeIconColor,
    closeIconWidth,
    closeIconHeight,
    highlightCloseIcon,
}) => {
    return (
        <DialogStyled
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            TransitionComponent={fullScreen ? Transition : undefined}
        >
            <IconButtonStyled fullScreen={fullScreen} onClick={onClose}>
                <CloseIcon
                    color={closeIconColor}
                    width={closeIconWidth}
                    height={closeIconHeight}
                    fullScreen={fullScreen}
                    highlightCloseIcon={highlightCloseIcon}
                />
            </IconButtonStyled>
            <ContentContainer fullScreen={fullScreen}>{children}</ContentContainer>
        </DialogStyled>
    );
};

const DialogStyled = styled(Dialog)`
    ${({ fullScreen }) =>
        !fullScreen &&
        css`
            .MuiPaper-root {
                max-width: calc(100% - ${pxToVw(322)}vw);
                max-height: ${pxToVh(593)}vh;
                min-height: ${pxToVh(400)}vh;
                width: 100%;
                background-color: transparent;
                box-shadow: none;
                overflow: unset;
            }
        `}

    ${({ fullScreen }) =>
        fullScreen &&
        css`
            left: unset !important;
            .MuiPaper-root {
                max-width: ${pxToVw(690)}vw;
            }
        `}
`;

const IconButtonStyled = styled(IconButton)`
    ${({ fullScreen }) =>
        fullScreen &&
        css`
            position: absolute !important;
            top: 28px;
            right: 20px;
        `}

    display: flex;
    align-self: flex-end;
    cursor: pointer;
`;

const CloseIcon = styled(RemoveIcon)`
    width: ${({ width }) => width || '48px'};
    height: ${({ height }) => height || '48px'};

    path {
        fill: ${({ color }) => color || colorsCode.white};

        ${({ highlightCloseIcon }) =>
            highlightCloseIcon &&
            css`
                stroke: ${({ color }) => color || colorsCode.white};
                stroke-width: 2px;
            `}
    }
`;

const ContentContainer = styled.div`
    ${({ fullScreen }) =>
        !fullScreen &&
        css`
            width: 100%;
            height: calc(100% - 48px);
            background-color: ${colorsCode.white};
            transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            border-radius: 4px;
            box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
                0px 9px 46px 8px rgba(0, 0, 0, 0.12);
            display: flex;
            flex-direction: column;
            overflow: hidden;
        `}
`;

ContentModal.defaultProps = {
    open: false,
    onClose: () => {},
    children: null,
    fullScreen: false,
    closeIconColor: null,
    closeIconWidth: null,
    closeIconHeight: null,
    highlightCloseIcon: false,
};

ContentModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    fullScreen: PropTypes.bool,
    closeIconColor: PropTypes.string,
    closeIconWidth: PropTypes.string,
    closeIconHeight: PropTypes.string,
    highlightCloseIcon: PropTypes.bool,
};

export default ContentModal;
