import { createSlice } from '@reduxjs/toolkit';
import {
    getOhiTemplateQuestions,
    getOutcomesAndPractices,
    updateOhiTemplateQuestions,
} from '../../actions/TextEdit/actions';
import { cloneDeep, orderBy } from 'lodash';

export const INITIAL_STATE = {
    loading: false,
    data: [],
    outcomesData: {},
    tabs: [],
    isUpdated: false,
    error: false,
    errorMessage: '',
    selectedQuestionCount: '',
};

const OHITemplateQuestions = createSlice({
    initialState: INITIAL_STATE,
    name: 'OHITemplateQuestions',
    extraReducers: builder => {
        builder
            .addCase(getOhiTemplateQuestions.pending, state => {
                state.loading = true;
            })
            .addCase(
                getOhiTemplateQuestions.fulfilled,
                (
                    state,
                    {
                        payload: {
                            data: { records = [] },
                        },
                    }
                ) => {
                    const tabValues = Object.keys(records);
                    const clone = cloneDeep(records);
                    tabValues?.forEach(item => {
                        if (clone[item]?.Outcome) {
                            clone[item].Outcome.is_edited = false;
                            let outcomeQuestions = clone[item].Outcome.questions;
                            outcomeQuestions?.forEach((val, i) => {
                                outcomeQuestions[i].is_edited = false;
                            });
                            outcomeQuestions = orderBy(outcomeQuestions, ['question_order'], ['asc']);
                            clone[item].Outcome.questions = outcomeQuestions;
                        }

                        if (clone[item]?.Practices) {
                            clone[item].Practices.is_edited = false;
                            let practiceQuestions = clone[item].Practices.questions;
                            practiceQuestions?.forEach((val, i) => {
                                practiceQuestions[i].is_edited = false;
                            });
                            practiceQuestions = orderBy(practiceQuestions, ['question_order'], ['asc']);
                            clone[item].Practices.questions = practiceQuestions;
                        }
                    });
                    state.loading = false;
                    state.data = clone;
                    state.tabs = tabValues;
                }
            )
            .addCase(getOhiTemplateQuestions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateOhiTemplateQuestions.pending, state => {
                state.loading = true;
            })
            .addCase(updateOhiTemplateQuestions.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateOhiTemplateQuestions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(getOutcomesAndPractices.pending, state => {
                state.loading = true;
            })
            .addCase(
                getOutcomesAndPractices.fulfilled,
                (
                    state,
                    {
                        payload: {
                            data: { records = [] },
                        },
                    }
                ) => {
                    const tabValues = Object.keys(records);
                    let selectedQuestionsLength = 0;
                    tabValues?.forEach(item => {
                        records[item].Outcome.is_edited = false;
                        records[item].Outcome.name = item;
                        records[item].Outcome.use_in_survey = false;
                        records[item].Practices.is_edited = false;
                        records[item].Practices.name = item;
                        let outcomeQuestions = records[item].Outcome.questions;
                        outcomeQuestions?.forEach((val, i) => {
                            const { use_in_survey } = val;
                            outcomeQuestions[i].is_edited = false;
                            if (use_in_survey) {
                                selectedQuestionsLength += 1;
                                records[item].Outcome.use_in_survey = true;
                            }
                        });
                        outcomeQuestions = orderBy(outcomeQuestions, ['question_order'], ['asc']);
                        records[item].Outcome.questions = outcomeQuestions;

                        //Manage practices
                        const surveySubTypes = {};
                        let practiceQuestions = records[item].Practices.questions;
                        practiceQuestions = orderBy(practiceQuestions, ['question_order'], ['asc']);
                        practiceQuestions?.forEach((val, i) => {
                            const { use_in_survey, sub_practice_name } = val;
                            practiceQuestions[i].is_edited = false;
                            if (!surveySubTypes[sub_practice_name]) {
                                surveySubTypes[sub_practice_name] = {
                                    questions: [val],
                                    name: sub_practice_name,
                                    use_in_survey: use_in_survey,
                                };
                            } else {
                                surveySubTypes[sub_practice_name].questions.push(val);
                                if (use_in_survey) {
                                    surveySubTypes[sub_practice_name].use_in_survey = true;
                                }
                            }
                            if (use_in_survey) {
                                selectedQuestionsLength += 1;
                                records[item].Practices.use_in_survey = true;
                            }
                            records[item].Practices.subTypes = surveySubTypes;
                        });

                        records[item].Practices.questions = practiceQuestions;
                    });
                    state.loading = false;
                    state.outcomesData = records;
                    state.tabs = tabValues;
                    state.selectedQuestionCount = selectedQuestionsLength;
                }
            )
            .addCase(getOutcomesAndPractices.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        resetOHI: state => {
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = false;
        },
        updateOutcomeQuestion: (state, { payload }) => {
            const { selectedTab, qIndex, checked } = payload;
            const { selectedQuestionCount } = state;
            const outcomeObj = state.outcomesData[selectedTab].Outcome;
            const outcomeQuestions = outcomeObj.questions;
            outcomeQuestions[qIndex].use_in_survey = checked;

            const selectedQuesLen = outcomeQuestions.filter(({ use_in_survey }) => use_in_survey).length;
            outcomeObj.questions = outcomeQuestions;
            outcomeObj.use_in_survey = !!selectedQuesLen;
            state.outcomesData[selectedTab].Outcome = outcomeObj;
            state.selectedQuestionCount = checked ? selectedQuestionCount + 1 : selectedQuestionCount - 1;
            state.loading = false;
        },
        updatePracticeQuestion: (state, { payload }) => {
            const { outcome, subType, checked, qIndex } = payload;
            const { selectedQuestionCount } = state;
            const practiceObj = state.outcomesData[outcome].Practices;
            const subtypeQuestions = practiceObj.subTypes[subType].questions;
            subtypeQuestions[qIndex].use_in_survey = checked;

            const selectedQuesLen = subtypeQuestions.filter(({ use_in_survey }) => use_in_survey).length;

            practiceObj.subTypes[subType].questions = subtypeQuestions;
            practiceObj.subTypes[subType].use_in_survey = !!selectedQuesLen;
            state.outcomesData[outcome].Practices = practiceObj;
            state.selectedQuestionCount = checked ? selectedQuestionCount + 1 : selectedQuestionCount - 1;
            state.loading = false;
        },
        updateOutcome: (state, { payload }) => {
            const { selectedTab, checked } = payload;
            const { selectedQuestionCount } = state;
            const outcomeObj = state.outcomesData[selectedTab].Outcome;
            const outcomeQuestions = outcomeObj.questions;
            const updatedQuestionsLength = outcomeQuestions.length;
            const selectedQuestionsLength = outcomeQuestions.filter(({ use_in_survey }) => use_in_survey).length;
            const deSelectedQuestionsLength = updatedQuestionsLength - selectedQuestionsLength;

            outcomeQuestions.forEach(ques => {
                ques.use_in_survey = checked;
            });
            outcomeObj.questions = outcomeQuestions;
            outcomeObj.use_in_survey = checked;
            state.outcomesData[selectedTab].Outcome = outcomeObj;
            state.selectedQuestionCount = checked
                ? selectedQuestionCount + deSelectedQuestionsLength
                : selectedQuestionCount - selectedQuestionsLength;
            state.loading = false;
        },

        updatePractice: (state, { payload }) => {
            const { outcome, subType, checked } = payload;
            const { selectedQuestionCount } = state;
            const practiceObj = state.outcomesData[outcome].Practices;
            const subTypeQuestions = practiceObj.subTypes[subType].questions;
            const updatedQuestionsLength = subTypeQuestions.length;
            const selectedQuestionsLength = subTypeQuestions.filter(({ use_in_survey }) => use_in_survey).length;
            const deSelectedQuestionsLength = updatedQuestionsLength - selectedQuestionsLength;

            subTypeQuestions.forEach(ques => {
                ques.use_in_survey = checked;
            });
            practiceObj.subTypes[subType].questions = subTypeQuestions;
            practiceObj.subTypes[subType].use_in_survey = checked;
            state.outcomesData[outcome].Practices = practiceObj;
            state.selectedQuestionCount = checked
                ? selectedQuestionCount + deSelectedQuestionsLength
                : selectedQuestionCount - selectedQuestionsLength;
            state.loading = false;
        },

        updateAllOutcomes: (state, { payload }) => {
            const { data, count } = payload;
            state.outcomesData = data;
            state.selectedQuestionCount = count;
            state.loading = false;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        },
        resetOHITempData: state => {
            state.loading = false;
            state.data = [];
            state.outcomesData = {};
            state.tabs = [];
            state.isUpdated = false;
            state.error = false;
            state.errorMessage = '';
            state.selectedQuestionCount = '';
        },
    },
});

export const {
    resetOHI,
    updateOutcomeQuestion,
    updateOutcome,
    updateAllOutcomes,
    updatePracticeQuestion,
    updatePractice,
    setLoading,
    resetOHITempData,
} = OHITemplateQuestions.actions;
export default OHITemplateQuestions.reducer;
