import { createAsyncThunk } from '@reduxjs/toolkit';
import { Endpoints } from '../../../http/Endpoints';
import { api } from '../../../http/api';
import { SaveSurveyStatus } from '../Surveys/action';
import { MESSAGE } from '../../../global-constants';

export const AllCustomQuestion = createAsyncThunk('allcustom', async (params, { rejectWithValue }) => {
    try {
        const { surveyId } = params;
        const response = await api.get(
            Endpoints.getQuestions.replaceAll(':survey_id', surveyId).concat('?question_type=ALL&is_custom=true')
        );
        // dispatch(GetSurveyPreviewLinks({ surveyId }));
        const {
            data: { data },
        } = response;
        return data;
    } catch (err) {
        return rejectWithValue(err?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const GetSurveyPreviewLinks = createAsyncThunk('getlink', async (params, { rejectWithValue }) => {
    try {
        const { surveyId } = params;
        const response = await api.get(Endpoints.surveyPreviewLink.replace(':survey_id', surveyId));
        const { data } = response;
        return data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});

export const SaveStatusQuestions = createAsyncThunk(
    'saveStatusQuestions',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            const { surveyId, requestParams, status } = params;
            const response = await api.post(Endpoints.bulkApproveReject.replaceAll(':survey_id', surveyId), {
                questions: requestParams,
            });
            if (status) {
                dispatch(SaveSurveyStatus({ surveyId, status }));
                dispatch(AllCustomQuestion(params));
            } else {
                dispatch(AllCustomQuestion(params));
            }
            const {
                data: { data },
            } = response;
            return data;
        } catch (err) {
            return rejectWithValue(err?.response?.data?.message || MESSAGE.Error_Add_Client);
        }
    }
);

export const SubmitForApproval = createAsyncThunk('submitapproval', async (params, { rejectWithValue, dispatch }) => {
    try {
        const { status, surveyId } = params;
        await dispatch(SaveSurveyStatus({ surveyId, status }));
        await dispatch(AllCustomQuestion({ surveyId }));
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || MESSAGE.Error_Add_Client);
    }
});
