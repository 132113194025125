import styled, { css } from 'styled-components';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { FlexColumnWithAlign, FlexRow } from '../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

export const HEADER_CELL_HEIGHT = `${pxToVh(43)}vh`;
export const DATA_CELL_HEIGHT = `${pxToVh(60)}vh`;
export const GAP_CELL_HEIGHT = `${pxToVh(20)}vh`;
export const SECTION_CELL_HEIGHT = `${pxToVh(33)}vh`;
export const SECTION_LEFT_PADDING = `${pxToVw(0)}vw`;

export const Container = styled(FlexColumnWithAlign)`
    .timeline-chip {
        justify-content: flex-start;
        margin-left: ${SECTION_LEFT_PADDING};
        align-self: stretch;
    }
`;

export const LegendContainer = styled(FlexRow)`
    justify-content: flex-end;
    width: 100%;
`;

export const LegendColorBox = styled.div`
    width: ${pxToVw(18)}vw;
    height: ${pxToVw(18)}vw;
    background-color: ${({ color }) => color || colorsCode.TextSubtle};
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: ${pxToVw(250)}vw 1fr;
    border-top: 1px solid ${colorsCode.Neutral10};
    border-right: 1px solid ${colorsCode.Neutral10};
    background-color: ${colorsCode.white};
`;

// task container
export const GridActivityContainer = styled.div`
    border-right: 1px solid ${colorsCode.Neutral10};
    box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.05);
`;

export const GridActivityRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isHeading }) =>
        isHeading &&
        css`
            height: ${HEADER_CELL_HEIGHT};
            border-bottom: 1px solid ${colorsCode.Neutral10};
            box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.05);
        `}

    ${({ isHeading }) =>
        !isHeading &&
        css`
            height: ${DATA_CELL_HEIGHT};
            padding: ${pxToVh(6)}vh 0;
            border-left: 1px solid ${colorsCode.backgroundDisabled};
            position: relative;

            > span {
                width: 100%;
            }

            &::before {
                position: relative;
                width: ${pxToVw(12)}vw;
                border-bottom: 1px solid ${colorsCode.backgroundDisabled};
                content: '';
                display: inline-block;
                margin-right: ${pxToVw(4)}vw;
            }

            &:last-child {
                border-left: none;
            }

            &:last-child::after {
                position: absolute;
                left: 0;
                top: 0;
                height: 50%;
                border-left: 1px solid ${colorsCode.backgroundDisabled};
                content: '';
                display: inline-block;
            }
        `}
`;

export const GapRow = styled.div`
    width: 100%;
    height: ${GAP_CELL_HEIGHT};
`;
export const StyledAccordion = styled(Accordion)`
    &&& {
        margin: 0;
        box-shadow: none;
        background-color: inherit;
        border-radius: 0;

        ::before {
            display: none;
        }

        &:last-child {
            border-bottom: ${({ borderColor }) => `1px solid ${borderColor || colorsCode.Neutral10}`};
        }
    }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
    min-height: ${SECTION_CELL_HEIGHT} !important;
    padding: 0 !important;

    .MuiAccordionSummary-content {
        margin: 0 !important;
    }

    ${({ isActivityDetail }) =>
        isActivityDetail &&
        css`
            padding: 0 ${pxToVw(12)}vw !important;
        `}
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
    padding: 0 !important;

    ${({ isActivityDetail }) =>
        isActivityDetail &&
        css`
            padding: 0 ${pxToVw(22)}vw 0 ${pxToVw(24)}vw !important;
        `}
`;

// timetable container
export const GanttTimeTableContainer = styled.div`
    display: grid;
    overflow-x: auto;

    .task-duration {
        position: absolute;
        height: calc(pxToVh(20) / 3);
        top: calc(pxToVh(20) / 3);
        z-index: 1;
        background: linear-gradient(
            90deg,
            ${colorsCode.SecondaryDeepBlue500} 0%,
            v ${colorsCode.SecondaryDeepBlue} 100%
        );
        border-radius: 2px;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
        cursor: move;

        &:focus {
            outline: 1px solid black;
        }
    }
`;

export const GanttTimePeriodHeader = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(${pxToVw(22)}vw, 1fr);
    height: ${HEADER_CELL_HEIGHT};
`;

export const GanttTimePeriodHeaderCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${colorsCode.lightCyan};
    border-right: 1px solid ${colorsCode.Neutral10};
    border-bottom: 1px solid ${colorsCode.Neutral10};

    &:last-child {
        border-right: none;
    }
`;

export const GanttTimePeriodCell = styled.div`
    position: relative;

    ${({ isLastDayCell, borderColor }) =>
        isLastDayCell &&
        css`
            border-right: 1px solid ${borderColor || colorsCode.Neutral10};
        `}

    &:last-child {
        border-right: none;
    }
`;

export const GanttTimePeriodRow = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(${pxToVw(22)}vw, 1fr);
    height: ${DATA_CELL_HEIGHT};

    ${({ isAccordianCell }) =>
        isAccordianCell &&
        css`
            height: ${SECTION_CELL_HEIGHT};
        `}
    ${({ isGapRow }) =>
        isGapRow &&
        css`
            height: ${GAP_CELL_HEIGHT};
        `}
`;

export const GanttTimePeriodDataContainer = styled.div`
    grid-column: 1/-1;
    display: grid;
    background-color: ${colorsCode.white};
`;

export const TaskDuration = styled.div``;
