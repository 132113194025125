import React from 'react';
import { Slide, Snackbar } from '@mui/material';
import { FlexBetween, FlexStartGap, FlexColumnGap } from '../styled/styled';
import { CorssSvg, RejectSvg, SuccessSvg } from '../../assets/img/svgs';
import { CommonLabel } from './Common';
import { colorsCode, pxToVh, pxToVw } from '../colors';

// eslint-disable-next-line react/display-name
export const ShowMessage = React.memo(function ({ open, close, type, message, title = '' }) {
    function TransitionLeft(props) {
        return <Slide {...props} direction="right" />;
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={open}
            autoHideDuration={6000}
            onClose={close}
            TransitionComponent={TransitionLeft}
            message={
                <FlexBetween
                    style={{
                        alignSelf: 'stretch',
                        gap: `${pxToVw(12)}vw`,
                        padding: '0 0.5vw',
                    }}
                >
                    <FlexStartGap gap={pxToVw(12)} style={{ alignItems: 'center' }}>
                        {type === 'success' ? <SuccessSvg /> : <RejectSvg />}
                        <FlexColumnGap gap={pxToVh(4)}>
                            {type === 'success' ? (
                                <CommonLabel
                                    label={message}
                                    textSize={14}
                                    textWeight={500}
                                    lineHeight={20}
                                    textColor={colorsCode.Neutral80}
                                />
                            ) : (
                                <React.Fragment>
                                    <CommonLabel
                                        label={title ? title : type === 'success' ? 'Success' : 'Error'}
                                        textSize={14}
                                        textWeight={500}
                                        lineHeight={20}
                                        textColor={colorsCode.Neutral80}
                                    />
                                    <CommonLabel
                                        label={message}
                                        textSize={14}
                                        textWeight={300}
                                        lineHeight={20}
                                        textColor={colorsCode.Neutral80}
                                    />
                                </React.Fragment>
                            )}
                        </FlexColumnGap>
                    </FlexStartGap>
                    <span onClick={close} style={{ cursor: 'pointer', marginTop: '5px' }}>
                        <CorssSvg />
                    </span>
                </FlexBetween>
            }
            ContentProps={{
                sx: {
                    background: colorsCode.white,
                    color: colorsCode.PrimaryDeepBlue,
                    padding: `${pxToVh(10)}vh`,
                },
            }}
        ></Snackbar>
    );
});
