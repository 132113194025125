import React, { Fragment } from 'react';
import { Paper, TableRow, TableHead, TableBody, Table, Grid, Checkbox } from '@mui/material';
import SortIcon from '../../../../assets/img/sort-icon.svg';
import EditIcon from '../../../../assets/img/edit_blue.svg';
import DeleteIcon from '../../../../assets/img/delete_blue.svg';
import CheckboxSvg from '../../../../assets/img/Checkbox.svg';

import { FlexColumn, FlexRow, FlexStartGap, TableCellStyled } from '../../../../common/styled/styled';
import TableThemeStyles from './themes';
import '../../scss/index.scss';
import { useSelector } from 'react-redux';
import Loader from '../../../Loader';
import PaginationComponent from '../../PaginationComponent';

import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { PATHS } from '../../../../global-constants';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { Badge } from '../../../Surveys/partials/setup-survey/Badge';

const ClientList = ({
    clientsData,
    clientFilter,
    setClientFilters,
    totalClientsCount,
    // getSurveyName,
    getLinkType,
    selectedIds,
    setSelectedIds,
    setOpen,
    setSelectedClientId,
    setOpenConfirmModal,
    setClientsData,
    templatesData,
    dispatchClientList,
}) => {
    const styles = TableThemeStyles();
    const { data } = useSelector(state => state.permissionReducer);
    const { can_view_clients, can_delete_clients, can_update_clients } = data;
    const { loading } = useSelector(state => state.clientReducer);
    const styleObj = {
        width: `${pxToVw(23)}vw`,
        height: `${pxToVh(23)}vh`,
        color: colorsCode.TextHeading,
    };
    const checkBoxStyle = {
        width: `${pxToVw(18)}vw`,
        height: `${pxToVh(18)}vh`,
    };
    const actionableIconStyle = {
        width: `${pxToVw(16)}vw`,
        height: `${pxToVh(16)}vh`,
    };

    const changePageNumber = (e, value) => {
        const updatedClientFilters = { ...clientFilter, page: value };
        setClientFilters(updatedClientFilters);
        dispatchClientList(updatedClientFilters);
    };

    const changeLimitPerPage = val => {
        const updatedClientFilters = { ...clientFilter, limit: val, page: 1 };
        setClientFilters(updatedClientFilters);
        dispatchClientList(updatedClientFilters);
    };

    const showDeleteModal = clientId => {
        setOpenConfirmModal({ btn: 'singleDelete' });
        setOpen(true);
        setSelectedClientId(clientId);
    };

    const history = useNavigate();

    const handleClick = id => {
        history(PATHS.EDIT_CLIENT.replace(':client_id', id));
    };

    const handleCheckChild = (event, id, i) => {
        const {
            target: { checked },
        } = event;
        const copy = cloneDeep(clientsData);
        copy[i].is_selected = checked;
        setClientsData(copy);

        const containsId = selectedIds?.some(item => item === id);
        if (!containsId) {
            setSelectedIds(ids => [...ids, id]);
        } else {
            let myIds = selectedIds.filter(item => item !== id);
            setSelectedIds([...myIds]);
        }
    };

    const handleSort = () => {
        const copy = cloneDeep(clientFilter);
        const { sort } = copy;
        copy.sort = sort === 0 ? 1 : 0;
        setClientFilters(copy);
        dispatchClientList(copy);
    };

    const handleParent = e => {
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(clientsData);
        const data = copy.map(item => {
            return { ...item, is_selected: checked };
        });
        setSelectedIds(data.filter(({ is_selected }) => is_selected).map(({ uuid }) => uuid));
        setClientsData(data);
    };

    const indeterminateFlag = () => {
        if (!clientsData?.filter(({ is_selected }) => is_selected === true).length) {
            return false;
        } else if (clientsData?.filter(({ is_selected }) => is_selected).length !== clientsData?.length) {
            return true;
        }
        return false;
    };

    return (
        <Fragment>
            {can_view_clients === 'can_view_clients' && (
                <Grid item xs={12} md={12}>
                    <Table
                        size="small"
                        component={Paper}
                        className="tableClass table"
                        sx={{ boxShadow: '0px 0px 1px 0px #051c2c26, 0px 2px 4px -1px #051c2c26' }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCellStyled>
                                    <Checkbox
                                        sx={{
                                            height: 0,
                                            width: 0,
                                        }}
                                        icon={<img src={CheckboxSvg} className="img" style={checkBoxStyle} />}
                                        inputProps={{ 'data-testid': 'parentcheck' }}
                                        onClick={handleParent}
                                        checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                        checked={Boolean(
                                            clientsData?.length &&
                                                clientsData?.every(({ is_selected }) => is_selected === true)
                                        )}
                                        indeterminate={indeterminateFlag()}
                                        indeterminateIcon={<IndeterminateCheckBoxIcon sx={styleObj} />}
                                    />
                                </TableCellStyled>
                                <TableCellStyled className={`${styles.clientName}`}>
                                    <FlowRowGap data-testid="abc">
                                        Client name
                                        <FlexColumn>
                                            <img
                                                src={SortIcon}
                                                alt="image"
                                                className="img sort"
                                                onClick={handleSort}
                                                style={{ cursor: 'pointer', ...actionableIconStyle }}
                                            />
                                        </FlexColumn>
                                    </FlowRowGap>
                                </TableCellStyled>
                                <TableCellStyled className={`${styles.linkType}`}>Link type</TableCellStyled>
                                <TableCellStyled className={`${styles.surveyType}`}>Survey type</TableCellStyled>
                                <TableCellStyled className={`${styles.user}`}>User</TableCellStyled>
                                <TableCellStyled className={`${styles.surveyCreated}`}>Surveys created</TableCellStyled>
                                {can_update_clients === 'can_update_clients' ? (
                                    <TableCellStyled></TableCellStyled>
                                ) : null}

                                {can_delete_clients === 'can_delete_clients' ? (
                                    <TableCellStyled></TableCellStyled>
                                ) : null}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {clientsData && clientsData.length ? (
                                clientsData.map(
                                    (
                                        {
                                            uuid,
                                            is_selected,
                                            client_name,
                                            meta_info,
                                            client_template,
                                            user_count,
                                            survey_count,
                                        },
                                        index
                                    ) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCellStyled>
                                                    <Checkbox
                                                        id={`child-${uuid}`}
                                                        name={`child-${uuid}`}
                                                        checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                                        checked={Boolean(is_selected)}
                                                        inputProps={{ 'data-testid': 'checkbox1' }}
                                                        onClick={e => handleCheckChild(e, uuid, index)}
                                                        sx={{
                                                            height: 0,
                                                            width: 0,
                                                        }}
                                                        icon={
                                                            <img
                                                                src={CheckboxSvg}
                                                                className="img"
                                                                style={checkBoxStyle}
                                                            />
                                                        }
                                                    />
                                                </TableCellStyled>
                                                <TableCellStyled>
                                                    {client_name?.replaceAll('&amp;', '&')}
                                                </TableCellStyled>
                                                <TableCellStyled>{meta_info && getLinkType(meta_info)}</TableCellStyled>
                                                <TableCellStyled>
                                                    <FlexStartGap gap={pxToVw(4)} style={{ flexWrap: 'wrap' }}>
                                                        {client_template.map(({ template_id }, i) => (
                                                            <Badge
                                                                key={i}
                                                                data={templatesData}
                                                                survey_template={template_id}
                                                            />
                                                        ))}
                                                    </FlexStartGap>
                                                </TableCellStyled>
                                                <TableCellStyled>{user_count} users</TableCellStyled>
                                                <TableCellStyled>{survey_count}</TableCellStyled>
                                                {can_update_clients === 'can_update_clients' ? (
                                                    <TableCellStyled>
                                                        <img
                                                            src={EditIcon}
                                                            alt="alt img"
                                                            style={{ cursor: 'pointer', ...actionableIconStyle }}
                                                            onClick={() => handleClick(uuid)}
                                                        />
                                                    </TableCellStyled>
                                                ) : null}
                                                {can_delete_clients === 'can_delete_clients' ? (
                                                    <TableCellStyled>
                                                        <img
                                                            src={DeleteIcon}
                                                            alt="delete img"
                                                            style={{
                                                                cursor: 'pointer',
                                                                ...actionableIconStyle,
                                                            }}
                                                            onClick={() => {
                                                                can_delete_clients === 'can_delete_clients' &&
                                                                    showDeleteModal(uuid);
                                                            }}
                                                        />
                                                    </TableCellStyled>
                                                ) : null}
                                            </TableRow>
                                        );
                                    }
                                )
                            ) : (
                                <TableRow>
                                    <TableCellStyled sx={{ textAlign: 'center' }} colSpan={8}>
                                        No client found
                                    </TableCellStyled>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {loading && <Loader background="transparent" showBackground position="fixed" />}
                    {/* if not data then no pagination */}
                    {clientsData?.length && totalClientsCount > clientFilter.limit ? (
                        <PaginationComponent
                            page={clientFilter.page}
                            handleChangeFn={changePageNumber}
                            handleItemPerPageFn={changeLimitPerPage}
                            count={Math.ceil(totalClientsCount / clientFilter.limit)}
                            perPage={clientFilter?.limit}
                        />
                    ) : null}
                </Grid>
            )}
        </Fragment>
    );
};

const FlowRowGap = styled(FlexRow)`
    justify-content: space-between;
    margin-right: 10px;
`;

ClientList.propTypes = {
    clientsData: PropTypes.array.isRequired,
    clientFilter: PropTypes.object.isRequired,
    setClientFilters: PropTypes.func.isRequired,
    totalClientsCount: PropTypes.number.isRequired,
    getSurveyName: PropTypes.func.isRequired,
    getLinkType: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
    setSelectedIds: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    setSelectedClientId: PropTypes.func.isRequired,
    setOpenConfirmModal: PropTypes.func.isRequired,
    setClientsData: PropTypes.func.isRequired,
};

export default ClientList;
