import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
    loading: false,
    isDataFetched: false,
    additionalQtns: {},
    questionScales: [],
    errorMessage: '',
    successMessage: '',
    addedQuesId: '',
    quesOrder: 1,
};

const QuestionsReducer = createSlice({
    name: 'QuestionsReducer',
    initialState: INITIAL_STATE,
    reducers: {
        resetAdditionalQuestions: state => {
            state.errorMessage = '';
            state.successMessage = '';
            state.isDataFetched = false;
            state.loading = false;
            state.additionalQtns = {};
            state.questionScales = [];
        },
    },
});

export const { resetAdditionalQuestions } = QuestionsReducer.actions;
export default QuestionsReducer.reducer;
